import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class Libas extends Component {
  render() {
    const { tracking } = this.props;
    return (
      <header className="libas container-fluid header-wrapper">
        <div className="row">
          <div className="col-md-22 col-xs-24 col-md-offset-1 text-left xs-text-center">
            <a
              href={tracking.theme.meta_data.website || '#'}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="logo"
                src={tracking.theme.logo || '/images/logo_placeholder.svg'}
                alt="logo"
              />
            </a>
          </div>
        </div>
      </header>
    );
  }
}

Libas.propTypes = {
  tracking: PropTypes.object,
  user: PropTypes.object
};

const mapStateToProps = state => ({
  tracking: state.tracking,
  user: state.user
});

export default connect(
    mapStateToProps,
    null
  )(Libas);
