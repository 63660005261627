import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class KalakaarIndiaa extends Component {
  render() {
    const { user } = this.props;
    return (
      <header className={`container-fluid header-wrapper ${user.subdomain}`}>
        <p>Kalakaar Indiaa</p>
      </header>
    );
  }
}

KalakaarIndiaa.propTypes = {
  tracking: PropTypes.object,
  user: PropTypes.object
};

const mapStateToProps = state => ({
  tracking: state.tracking,
  user: state.user
});

export default connect(
    mapStateToProps,
    null
  )(KalakaarIndiaa);
