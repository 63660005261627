/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import CardOverlay from '../cardOverlay';
import { langOption, lang } from '../../utils/entity_mapping';

const Herbalife = props => {
  const { tracking, history } = props;
  const [state, setState] = useState({ show: false, selectedLang: '' });
  const wrapperRef = useRef(null);

  const onDropdown = () => {
    setState({ ...state, show: !state.show });
  };
  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setState({ ...state, show: false });
    }
  };
  const languages = tracking.theme.meta_data?.languages;
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  //splitting the path for adding language code in the url e.g.: ['', 'return']
  const pathParts = history.location.pathname.split('/');
  const onLangSwitch = (e, item) => {
    e.preventDefault();
    if (pathParts[1] !== item) {
      //adding language code in the url e.g.: ['en', 'return']
      pathParts[0] = item;
      //this line will remove the language code from the url and will replace it with the new one
      //e.g.: ['en'] will get removed and replaced with [] and again new lang code will get added in the url by above line
      pathParts[1].length === 2 ? pathParts.splice(1, 1) : null;
      // getting search url params
      const params = new URLSearchParams(history.location.search);
      // using window.location.href to change the url history.push is not working for this case
      window.location.href = `/${pathParts.join('/')}${
        params?.size > 0 ? `?${params.toString()}` : ''
      }`;
    }
  };

  const lightenColor = (color, amount) => {
    return (
      '#' +
      color
        .slice(1)
        .match(/../g)
        .map(x =>
          ((x = parseInt(x, 16) + amount), x > 255 ? 255 : x < 0 ? 0 : x)
            .toString(16)
            .padStart(2, '0')
        )
        .join('')
    );
  };
  const headerBackground = tracking?.theme?.header_background_color;

  //setting the background color of the language icon on the basis of the header background color when the dropdown is open
  const lightenedColor = lightenColor(
    tracking?.theme?.header_background_color,
    40
  );

  return (
    <header className="container-fluid header-wrapper hl">
      <div className="row no-gutter md-vertical-align d-flex items-center ">
        <div className="col-lg-19 col-md-17 col-xs-18 col-md-offset-1 col-xs-offset-3 text-left xs-text-center">
          <img
            className="logo"
            src={tracking.theme.logo || '/images/logo_placeholder.svg'}
            alt="logo"
          />
        </div>
        <div className="col-lg-4 col-md-5 col-xs-3 text-right xs-text-center">
          {languages?.enabled && languages.list?.length > 0 && (
            <>
              {' '}
              <div className="language-wrapper" ref={wrapperRef}>
                <p
                  style={{
                    backgroundColor: state.show
                      ? headerBackground === null ||
                        headerBackground === '#ffffff'
                        ? '#f6f6f6'
                        : lightenedColor
                      : ''
                  }}
                  className="d-flex items-center justify-center rounded-pill cursor-pointer language-pill"
                  onClick={onDropdown}
                >
                  <p className={`icon-language fs-25 `} />
                  <p className="whitespace-nowrap xs-display-none">
                    {' '}
                    {lang[pathParts[1]] ?? 'English'}
                  </p>
                </p>
                {state.show ? (
                  <div className="dropdown xs-display-none">
                    <ul>
                      {languages.list.map(item => (
                        <li key={item} onClick={e => onLangSwitch(e, item)}>
                          {langOption[item]}
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null}
                <div>
                  <CardOverlay
                    className={`mobile text-left lang-overlay ${
                      state.show ? 'show' : 'hide'
                    }`}
                    closeModal={() => setState({ ...state, show: false })}
                    closeButton
                  >
                    <p className="pd-10 mrg-top-10">Choose Your Language</p>
                    <hr className="mrg-top-5 mrg-btm-5" />
                    <div className="lang-container">
                      {languages.list?.map(item => (
                        <p
                          className="lang-items"
                          key={item}
                          onClick={e => onLangSwitch(e, item)}
                        >
                          {langOption[item]}
                        </p>
                      ))}
                    </div>
                  </CardOverlay>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </header>
  );
};

Herbalife.contextTypes = {
  router: PropTypes.object.isRequired
};

Herbalife.propTypes = {
  tracking: PropTypes.object,
  user: PropTypes.object,
  history: PropTypes.object
};

const mapStateToProps = state => ({
  tracking: state.tracking,
  user: state.user
});

export default withRouter(connect(mapStateToProps, null)(Herbalife));
