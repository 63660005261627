import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class Damensch extends Component {
  render() {
    const { tracking, user } = this.props;
    return (
      <header className={`container-fluid header-wrapper`}>
        <div className="row no-gutter">
          <div className="col-md-22 col-xs-24 col-md-offset-1 text-left xs-text-center">
            {user.subdomain === 'damensch' && user.device === 'mobile' ? (
              <div className="header-ad">
                <a
                  href={tracking.theme.meta_data.udf?.[1].value || '#'}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>{tracking.theme.meta_data.udf?.[0].value}</span>
                  <img
                    className="logo"
                    src={'/images/help_white_icon.svg'}
                    alt="ad"
                  />
                </a>
              </div>
            ) : null}
            <a
              href={tracking.theme.meta_data.website || '#'}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="logo"
                src={tracking.theme.logo || '/images/logo_placeholder.svg'}
                alt="logo"
              />
            </a>
          </div>
        </div>
      </header>
    );
  }
}

Damensch.propTypes = {
  tracking: PropTypes.object,
  user: PropTypes.object
};

const mapStateToProps = state => ({
  tracking: state.tracking
});

export default connect(mapStateToProps, null)(Damensch);
