import React from 'react';
import PropTypes from 'prop-types';
import PageHeading from './PageHeading';

const Layout = ({ children, buttons, heading, goback, border }) => {
  return (
    <div className="d-flex ht-100 justify-between flex-col">
      <PageHeading heading={heading} goback={goback} />
      <div
        className={`ht-100${
          border ? ' bd-primary bd-1 xs-bd-0' : ''
        } overflow-auto overflow-x-hidden`}
      >
        {children}
      </div>
      <div className="d-flex mrg-btm-20 mrg-top-20 xs-pd-left-15 xs-pd-right-15">
        {buttons.map(btn => (
          <button
            type={btn.type}
            key={btn.label}
            onClick={btn.onClick}
            disabled={btn.disabled}
            className={`ant-button rounded-pill full-width d-flex items-center justify-center${
              btn.disabled ? ' disabled' : ''
            }`}
          >
            {btn.label}
          </button>
        ))}
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
  buttons: PropTypes.array,
  heading: PropTypes.any,
  goback: PropTypes.any,
  border: PropTypes.bool
};

export default Layout;
