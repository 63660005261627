import React from 'react';
import PropTypes from 'prop-types';

const PageHeading = ({ goback, heading }) => {
  return (
    <div className="mrg-btm-20 mrg-top-20 xs-pd-left-10 xs-pd-right-10">
      {goback ? (
        <span
          className={`icon-arrow_back cursor-pointer pull-left mrg-right-10 fs-20`}
          onClick={goback}
          onKeyDown={goback}
          role={'button'}
          tabIndex={0}
        />
      ) : null}
      <span className="fs-20 fw-700 text-capitalize">{heading}</span>
    </div>
  );
};
PageHeading.propTypes = {
  goback: PropTypes.any,
  heading: PropTypes.any
};

export default PageHeading;
