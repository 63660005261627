/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { netmedsMenu } from '../../utils/menu';
import { updateUserHandler } from '../../service';

class NetMeds extends Component {
  toggleNav = () => {
    let { user } = this.props;
    user = Object.assign(user, { toggleNav: !user.toggleNav });
    this.props.updateUserHandler(user);
  };

  render() {
    const { tracking, user } = this.props;
    const enterpriseProfile = tracking.theme.meta_data;
    return (
      <header className="netmeds">
        <div className="container-fluid topbar visible-xs">
          <div className="row col-gutter-130">
            <div className="col-md-24 col-xs-24 text-left xs-text-center">
              <a className="hamburger-menu visible-xs" onClick={this.toggleNav}>
                <span />
              </a>
              <span
                className={`toggle-nav ${
                  user.toggleNav ? 'visible-xs' : 'hide'
                }`}
                onClick={user.toggleNav ? this.toggleNav : null}
              />
              <a
                className="site-link"
                href={enterpriseProfile.website}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="logo"
                  src={'/images/netmeds_m_logo.png'}
                  width="130px"
                  alt="logo"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="container-fluid topbar hidden-xs">
          <div className="row col-gutter-130">
            <div className="col-md-24 col-xs-24 text-left xs-text-center">
              <a
                href={enterpriseProfile.website}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="logo"
                  src={tracking.theme.logo || '/images/logo_placeholder.svg'}
                  alt="logo"
                />
              </a>
            </div>
          </div>
        </div>
        <div
          className={`container-fluid header-wrapper ${
            user.toggleNav ? 'mb' : 'hide-nav'
          }`}
        >
          <div className="row col-gutter-130 xs-mrg-0">
            <div className="col-md-24 col-xs-24 text-left xs-pd-0">
              <ul className="nav">
                <li key={1001} className="visible-xs m-link">
                  <a
                    className="main"
                    href="https://m.netmeds.com/customer/account/login/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Sign In
                  </a>
                </li>
                {netmedsMenu.level1.map((item, i) => (
                  <li key={i}>
                    <a
                      className="main"
                      href={`https://www.netmeds.com/${item.url}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

NetMeds.propTypes = {
  tracking: PropTypes.object,
  user: PropTypes.object,
  updateUserHandler: PropTypes.func
};

const mapStateToProps = state => ({
  tracking: state.tracking,
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  updateUserHandler: user => dispatch(updateUserHandler(user))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(NetMeds);
