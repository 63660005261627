/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class MenaMultiLang extends Component {
  langSwitcher = () => {
    const { lang } = this.props.user;
    let newLang = 'en';
    if(lang === 'en'){
      newLang = 'ar';
    }
    let { pathname, search, hash } = this.props.location;
    let splitUrl = pathname.split('/');
    const idx = splitUrl.indexOf(lang);
    if(idx > -1){
      pathname = pathname.replace(lang, newLang);
      splitUrl[1] = newLang;
    } else {
      splitUrl.splice(1,0, newLang);
    }
    if(splitUrl.length === 2){
      splitUrl[2] = '';
    }
    window.location.href = splitUrl.join('/') + search + hash;
  }
  render() {
    const { tracking, user } = this.props;
    const { lang } = user;
    return (
      <header className={`container-fluid header-wrapper ${user.subdomain == '6thstreet' ? 'sixthstreet' : user.subdomain}  ${user.subdomain == '6thstreet-test' ? 'sixthstreet-test' : ''}`}>
        <div className="row no-gutter">
          <div className="col-md-22 col-xs-24 col-md-offset-1 text-left xs-text-center">
            <a
              href={tracking.theme.meta_data.website || '#'}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="common logo"
                src={tracking.theme.logo || '/images/logo_placeholder.svg'}
                alt="logo"
              />
            </a>
            <span className="lang-switcher" role="presentation" onClick={this.langSwitcher}>{lang === 'en' ? 'عربى' : 'English'}</span>
          </div>
        </div>
      </header>
    );
  }
}

MenaMultiLang.propTypes = {
  tracking: PropTypes.object,
  user: PropTypes.object,
  location: PropTypes.object
};

const mapStateToProps = state => ({
  tracking: state.tracking,
  user: state.user
});

export default withRouter(connect(
    mapStateToProps,
    null
  )(MenaMultiLang));
