import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { strings, copyrightYear } from '../../utils/localizedStrings';
import { ga4ClickEvent } from '../../utils/helper';

const Supertails = props => {
  const gaEvents = (e, eventName, label, action) => {
    ga4ClickEvent({ category: eventName, action, label, props });
    ReactGA.event({
      category: eventName,
      action: action,
      label: label
    });
  };
  const { user, orderInfo, tracking } = props;
  const enterpriseProfile = tracking.theme.meta_data;
  const { lang } = user;

  const notShowCPBranding = tracking.theme.meta_data?.udf?.filter(
    item => item.key === 'notShowCPBranding'
  )?.[0]?.value;

  return orderInfo &&
    orderInfo.step &&
    orderInfo.step.current &&
    orderInfo.step.current < 2 ? (
    <footer
      className={`container-fluid footer-wrapper sticky-footer ${
        user.hideLink
          ? ''
          : user.appLink &&
            user.subdomain === 'hopscotch' &&
            this.context.router.history.location.search &&
            this.context.router.history.location.pathname === '/'
          ? 'xs-pd-btm-100'
          : ''
      }`}
    >
      <div className="row col-gutter-60 ">
        <Fragment>
          <div className="col-md-6 col-lg-8 col-xs-24 xs-mrg-top-15">
            {enterpriseProfile && enterpriseProfile.email && (
              <p className="text-left">
                <span className="info user-select">
                  <span> Email us: </span>
                  <a
                    href={`mailto:${enterpriseProfile &&
                      enterpriseProfile.email}`}
                    onClick={e =>
                      gaEvents(
                        e,
                        'Email ID',
                        'help',
                        enterpriseProfile && enterpriseProfile.email
                      )
                    }
                  >
                    {enterpriseProfile && enterpriseProfile.email}
                  </a>
                </span>
              </p>
            )}
            {enterpriseProfile && enterpriseProfile.contact && (
              <p className="text-left">
                <div className="row ">
                  <div className="col-md-24 col-lg-12 col-xs-24 xs-mrg-top-5">
                    <span className="info">
                      Call us:{' '}
                      <a
                        href={`tel:${enterpriseProfile &&
                          enterpriseProfile.contact}`}
                        onClick={e =>
                          gaEvents(
                            e,
                            'Contact Number',
                            'help',
                            enterpriseProfile && enterpriseProfile.contact
                          )
                        }
                      >
                        {enterpriseProfile && enterpriseProfile.contact}
                      </a>
                    </span>
                  </div>
                  <div className="col-md-24 col-lg-12 col-lg-gutter-60 col-xs-24 col-lg-push-0 col-md-push-4">
                    <span>
                      {!(
                        enterpriseProfile &&
                        enterpriseProfile.time &&
                        enterpriseProfile.time.to
                      ) &&
                      !(
                        enterpriseProfile &&
                        enterpriseProfile.time &&
                        enterpriseProfile.time.from
                      ) ? null : (
                        <span>
                          (
                          {enterpriseProfile &&
                            enterpriseProfile.time &&
                            enterpriseProfile.time.from}{' '}
                          to{' '}
                          {enterpriseProfile &&
                            enterpriseProfile.time &&
                            enterpriseProfile.time.to}{' '}
                          {enterpriseProfile &&
                            enterpriseProfile.day &&
                            enterpriseProfile.day.from}{' '}
                          to{' '}
                          {enterpriseProfile &&
                            enterpriseProfile.day &&
                            enterpriseProfile.day.to}
                          )
                        </span>
                      )}
                    </span>
                  </div>
                </div>
              </p>
            )}
          </div>
          <div className="col-md-4 col-lg-4 xs-mrg-top-15 text-left">
            {' '}
            <ul className="social-links">
              {enterpriseProfile.app &&
              (enterpriseProfile.app.android || enterpriseProfile.app.ios) ? (
                <li className="display-block-desktop">
                  <span className="pd-left-10">
                    {strings[lang].downloadApp}
                  </span>
                </li>
              ) : null}
              {enterpriseProfile.app && enterpriseProfile.app.android ? (
                <li className="display-block-desktop">
                  <a
                    href={enterpriseProfile.app.android}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={e =>
                      gaEvents(
                        e,
                        'App Install',
                        user.device,
                        enterpriseProfile.app.android
                      )
                    }
                  >
                    <span className="icon-android" />
                  </a>
                </li>
              ) : null}
              {enterpriseProfile.app && enterpriseProfile.app.ios ? (
                <li className="display-block-desktop">
                  <a
                    href={enterpriseProfile.app.ios}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={e =>
                      gaEvents(
                        e,
                        'App Install',
                        user.device,
                        enterpriseProfile.app.ios
                      )
                    }
                  >
                    <span className="icon-apple" />
                  </a>
                </li>
              ) : null}
            </ul>
          </div>
          <div className="col-md-14 col-lg-11 col-xs-24 xs-mrg-top-10 clickpost-info">
            <div className="row">
              <div className="col-md-8 col-sm-24 xs-mrg-top-15 sm-mrg-top-15">
                <ul className="social-links">
                  <li
                    className={`${
                      enterpriseProfile &&
                      enterpriseProfile.social &&
                      enterpriseProfile.social.fb
                        ? ''
                        : 'hide'
                    }`}
                  >
                    <a
                      href={
                        enterpriseProfile &&
                        enterpriseProfile.social &&
                        enterpriseProfile.social.fb
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={e =>
                        gaEvents(
                          e,
                          'Social',
                          'facebook',
                          enterpriseProfile &&
                            enterpriseProfile.social &&
                            enterpriseProfile.social.fb
                        )
                      }
                    >
                      <span className="icon-fb" />
                    </a>
                  </li>
                  <li
                    className={`${
                      enterpriseProfile &&
                      enterpriseProfile.social &&
                      enterpriseProfile.social.tw
                        ? ''
                        : 'hide'
                    }`}
                  >
                    <a
                      href={
                        enterpriseProfile &&
                        enterpriseProfile.social &&
                        enterpriseProfile.social.tw
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={e =>
                        gaEvents(
                          e,
                          'Social',
                          'twitter',
                          enterpriseProfile &&
                            enterpriseProfile.social &&
                            enterpriseProfile.social.tw
                        )
                      }
                    >
                      <span className="icon-twitter" />
                    </a>
                  </li>
                  <li
                    className={`${
                      enterpriseProfile &&
                      enterpriseProfile.social &&
                      enterpriseProfile.social.ig
                        ? ''
                        : 'hide'
                    }`}
                  >
                    <a
                      href={
                        enterpriseProfile &&
                        enterpriseProfile.social &&
                        enterpriseProfile.social.ig
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={e =>
                        gaEvents(
                          e,
                          'Social',
                          'instagram',
                          enterpriseProfile &&
                            enterpriseProfile.social &&
                            enterpriseProfile.social.ig
                        )
                      }
                    >
                      <span className="icon-instagram" />
                    </a>
                  </li>
                  <li
                    className={`${
                      enterpriseProfile &&
                      enterpriseProfile.social &&
                      enterpriseProfile.social.yt
                        ? ''
                        : 'hide'
                    }`}
                  >
                    <a
                      href={
                        enterpriseProfile &&
                        enterpriseProfile.social &&
                        enterpriseProfile.social.yt
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={e =>
                        gaEvents(
                          e,
                          'Social',
                          'youtube',
                          enterpriseProfile &&
                            enterpriseProfile.social &&
                            enterpriseProfile.social.yt
                        )
                      }
                    >
                      <span className="icon-youtube1" />
                    </a>
                  </li>
                </ul>
              </div>
              <div
                className={`col-md-16 col-sm-24 xs-col-gutter-16 ${notShowCPBranding &&
                  'xs-text-center text-right pd-right-30'}`}
              >
                <span className="copyright text-gray">
                  © {copyrightYear} {strings[lang].allRightsReserved}
                </span>
                {notShowCPBranding ? null : (
                  <span className="text-gray powered">
                    <span>Powered by </span>
                    <a
                      href="https://www.clickpost.ai/post-purchase-customer-experience-software"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://pyck-res-bucket.s3.ap-southeast-1.amazonaws.com/clickpost_assets/clickpost_logo.png"
                        alt="clickpost"
                      />
                    </a>
                  </span>
                )}
              </div>
            </div>
          </div>
        </Fragment>
      </div>
    </footer>
  ) : null;
};

Supertails.contextTypes = {
  router: PropTypes.object.isRequired
};

Supertails.propTypes = {
  tracking: PropTypes.object,
  user: PropTypes.object,
  orderInfo: PropTypes.object,
  page: PropTypes.object
};

const mapStateToProps = state => ({
  tracking: state.tracking,
  user: state.user,
  orderInfo: state.orderInfo,
  page: state.page
});

export default connect(mapStateToProps, null)(Supertails);
