import React from 'react';
import PropTypes from 'prop-types';

const Input = ({
  id,
  name,
  value,
  placeholder,
  onChange,
  onKeyDown,
  className,
  icon,
  rightAction,
  label,
  readOnly,
  error,
  type,
  wrapperClassName
}) => {
  return (
    <label className={wrapperClassName}>
      {label ? (
        <p className="fs-14 text-secondary-color mrg-btm-5">{label}</p>
      ) : null}

      <div htmlFor={id} className={`ant-input overflow-hidden ${className}`}>
        {icon}

        <input
          placeholder={placeholder}
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
          readOnly={readOnly}
          type={type}
        />
        {rightAction && <div style={{ marginRight: -11 }}>{rightAction}</div>}
      </div>
      {error && <p className="fs-12 text-red mrg-top-5">{error}</p>}
    </label>
  );
};

Input.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.any,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.any,
  onKeyDown: PropTypes.any,
  rightAction: PropTypes.any,
  label: PropTypes.any,
  readOnly: PropTypes.bool,
  error: PropTypes.any,
  type: PropTypes.string,
  wrapperClassName: PropTypes.string
};

export default Input;
