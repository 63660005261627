import React from 'react';
import PropTypes from 'prop-types';

const NotFound = ({ heading, description }) => {
  return (
    <div className="d-flex flex-col max justify-center text-center">
      <img
        src="/images/not-found-search.svg"
        alt="not-found"
        className="mrg-btm-15"
      />
      <p className="text-primary-color fs-20 fw-600">
        {heading || 'Search not found...'}
      </p>
      {description && (
        <p className="text-secondary-color fs-16">{description || ''}</p>
      )}
    </div>
  );
};
NotFound.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.string
};
NotFound.defaultProps = {
  description: ''
};

export default NotFound;
