import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { chevronLeft } from '../utils/svgs';

const Carousel = ({ imgList, className }) => {
  const [state, setState] = useState({
    show: 0
  });
  const onNext = () => {
    state.show += 1;
    setState({ ...state });
  };
  const onPrev = () => {
    state.show -= 1;
    setState({ ...state });
  };
  return (
    <div className={`img-carousel ${className}`}>
      <button
        onClick={onPrev}
        className={`arrow left ${state.show === 0 ? 'hide' : ''}`}
      >
        {chevronLeft()}
      </button>
      {imgList.map((src, idx) => (
        <img
          height={320}
          width={'100%'}
          key={src}
          src={src ?? '/images/img-placeholder.svg'}
          className={`rounded object-fit-cover ${
            state.show === idx ? '' : 'hide'
          }`}
          alt="product"
        />
      ))}
      <button
        onClick={onNext}
        className={`arrow right rotate-180 ${
          state.show === imgList.length - 1 ? 'hide' : ''
        }`}
      >
        {chevronLeft()}
      </button>

      <div className="dot-container">
        {imgList.map((src, idx) => (
          <div
            key={`${src}-img-dot`}
            className={`dot ${idx === state.show ? 'active' : ''}`}
          />
        ))}
      </div>
    </div>
  );
};

Carousel.propTypes = {
  imgList: PropTypes.any,
  className: PropTypes.string
};

export default Carousel;
