import React from 'react';
export const headsupfortails = ['headsupfortails', 'headsupfortails-test'];
export const isOnlySummaryScreenDomains = [
  'pantaloons',
  'thecollective',
  'abfrl-test',
  'abfrl-test2'
  // 'clickpost-returns-4'
];

export const bankDetailNotRequiredForAdminDomains = [
  'birkenstock',
  'clickpost-returns-4',
  'tracking-demo',
  'birkenstock-test'
];

export const addressFields = [
  { name: 'name', label: 'Name', width: '24', required: true },
  { name: 'address', label: 'Address', width: '24', required: true },
  { name: 'landmark', label: 'Landmark', width: '24' },
  {
    name: 'pincode',
    label: 'Pincode',
    width: '12',
    required: true
  },
  { name: 'city', label: 'City', width: '12', required: true },
  { name: 'state', label: 'State', width: '24', required: true },
  { name: 'country', label: 'Country', width: '24', required: true },
  { name: 'phone', label: 'Phone', width: '24', required: true },
  { name: 'email', label: 'Email', width: '24', required: false }
];
export const defaultAddress = {
  name: '',
  email: '',
  address: '',
  landmark: '',
  pincode: '',
  city: '',
  state: '',
  country: '',
  phone: '',
  isSubmitted: false,
  isSelfShipped: false
};

export const steps = {
  ITEM_SELECTION: 'ITEM_SELECTION',
  REASON: 'REASON',
  VARIANT_EXCHANGE: 'VARIANT_EXCHANGE',
  PRODUCT_EXCHANGE: 'PRODUCT_EXCHANGE',
  REFUND: 'REFUND',
  EXCHANGE_PAYMENTS: 'EXCHANGE_PAYMENTS',
  ADDRESS: 'ADDRESS',
  DATE: 'DATE',
  SUMMARY: 'SUMMARY'
};

export const stepActions = {
  FORWARD: 'FORWARD',
  REVERSE: 'REVERSE'
};

export const exchangeTypes = {
  SAME_PRODUCT_DIFFERENT_VARIANT: 'SAME_PRODUCT_DIFFERENT_VARIANT',
  DIFFERENT_PRODUCT: 'ALTOGETHER_DIFFERENT_PRODUCT',
  SAME_PRODUCT_SAME_VARIANT: 'SAME_PRODUCT_SAME_VARIANT'
};

export const pumaSubdomains = ['puma', 'puma-test'];

export const selectVariantIn = {
  reasons: 'reasons',
  variants: 'variants'
};

export const onlyReturnModalText = {
  houseofaldeno: (
    <span>
      The item is currently unavailable. Please reach out to us at
      <a
        href="mailto:info@houseofaldeno.com"
        className="mrg-left-5 mrg-right-5"
      >
        info@houseofaldeno.com
      </a>
      or contact us at 9880037137 for further assistance.
    </span>
  )
};

export const LETSDRESSUP = 'letsdressup';
