/**
  @TODO: soham - Remove the commented code and the CardOverlay component after testing complete
**/
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { getPaymentInfo, postPaymentInfo } from '../../service';
// import CardOverlay from '../cardOverlay';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import StatusBasedUI from './StatusBasedUI';

export const statusMap = {
  PAYMENT_SUCCESSFUL: 'PAYMENT_SUCCESSFUL',
  PAYMENT_FAILED: 'PAYMENT_FAILED',
  SHIPMENT_CONVERTED: 'SHIPMENT_CONVERTED',
  SHIPMENT_CONVERSION_FAIL: 'SHIPMENT_CONVERSION_FAIL',
  REFUND_REQUEST_GENERATED_DUE_TO_FAILED_CONVERSION:
    'REFUND_REQUEST_GENERATED_DUE_TO_FAILED_CONVERSION',
  REFUND_REQUEST_GENERATED_DUE_TO_RTO: 'REFUND_REQUEST_GENERATED_DUE_TO_RTO',
  REFUND_SUCCESSFUL: 'REFUND_SUCCESSFUL',
  PAYMENT_LINK_GENERATED: 'PAYMENT_LINK_GENERATED'
};

export const PaymentFailedModalBody = ({
  onRetry,
  title = 'Payment Failed',
  description
}) => (
  <>
    <div
      className="rounded-xxl xs-pd-left-0 d-flex pd-20 xs-bg-none mrg-btm-25 md-flex-col xs-justify-start items-center justify-center text-center"
      style={{ background: '#FEEAEB' }}
    >
      <img src="/images/failed-cross.svg" alt="failed" />
      <span
        style={{ color: '#5C5C5C' }}
        className="fs-20 fw-700 text-left xs-mrg-left-10 xs-mrg-top-0 mrg-top-10 "
      >
        {title}
      </span>
    </div>
    <div className="mrg-btm-15 text-left text-primary-color">{description}</div>
    <button onClick={onRetry} className="button full-width ml-auto">
      Retry Payment
    </button>
  </>
);

const Payment = ({
  paymentInfo,
  getPayment,
  tracking,
  //   postHandler,
  wrapperClassName,
  location
}) => {
  const { search } = location;
  const searchParams = new URLSearchParams(search);
  //   const [state, setState] = useState({
  //     show: false
  //   });

  //   const closeModal = () => {
  //     state.show = false;
  //     setState({ ...state });
  //   };

  useEffect(() => {
    getPayment();
  }, []);

  //   useEffect(() => {
  //     if (
  //       !paymentInfo.isFetching &&
  //       !paymentInfo.seen &&
  //       [SUCCESS, FAILED, SHIPMENT_CONVERTED, CONVERSION_FAILED].includes(status)
  //     ) {
  //       setState({ show: true });
  //       if ([SHIPMENT_CONVERTED, CONVERSION_FAILED].includes(status)) {
  //         postHandler({
  //           transactionId: paymentInfo.transactionId,
  //           seen: true
  //         });
  //       }
  //     }
  //   }, [paymentInfo.seen, paymentInfo.transactionId, paymentInfo.isFetching]);

  if (
    paymentInfo.status === statusMap.PAYMENT_LINK_GENERATED &&
    searchParams.has('paymentStatus')
  ) {
    paymentInfo.status = {
      2: statusMap.PAYMENT_SUCCESSFUL,
      4: statusMap.PAYMENT_FAILED
    }[Number(searchParams.get('paymentStatus'))];
  }

  if (
    !paymentInfo.paymentLink ||
    !paymentInfo.status ||
    paymentInfo.isFetching ||
    paymentInfo.isError
  ) {
    return null;
  }

  const trackingStatus = tracking.latestStatus.status;

  return (
    <>
      {/* <CardOverlay
        className="desktop"
        isOpen={state.show && !paymentInfo.isFetching}
        closeModal={closeModal}
        closeButton
      >
        {[SHIPMENT_CONVERTED, SUCCESS].includes(status) && (
          <>
            <div
              className="rounded-xxl xs-pd-left-0 d-flex pd-20 xs-bg-none mrg-btm-25 md-flex-col xs-justify-start items-center justify-center text-center"
              style={{ background: '#F0F9F5' }}
            >
              <img src="/images/success-tick.svg" alt="success" />
              <span
                style={{ color: '#5C5C5C' }}
                className="fs-20 fw-700 text-left xs-mrg-left-10 xs-mrg-top-0 mrg-top-10 "
              >
                Payment Successful
              </span>
            </div>
            <div className="d-flex mrg-btm-10 text-primary-color justify-between">
              <span>Transaction ID:</span>
              <span className="fw-600">{paymentInfo.transactionId}</span>
            </div>
            <div className="d-flex text-primary-color justify-between">
              <span>Amount Paid:</span>
              <span className="fw-600">
                Rs.{Number(paymentInfo.amount) - Number(paymentInfo.discount)}
              </span>
            </div>
          </>
        )}
        {status === FAILED && (
          <PaymentFailedModalBody
            onRetry={() => openUrl(paymentInfo.paymentLink, 'blank')}
            description={'Amount will be refunded if it has been debited'}
          />
        )}
        {status === CONVERSION_FAILED && (
          <>
            <div
              className="rounded-xxl xs-pd-left-0 d-flex pd-20 xs-bg-none mrg-btm-25 md-flex-col xs-justify-start items-center justify-center text-center"
              style={{ background: '#FEEAEB' }}
            >
              <img src="/images/failed-cross.svg" alt="failed" />
              <span
                style={{ color: '#5C5C5C' }}
                className="fs-20 fw-700 text-left xs-mrg-left-10 xs-mrg-top-0 mrg-top-10 "
              >
                Failure !
              </span>
            </div>
            <div className="mrg-btm-15 text-left text-primary-color">
              We could not convert your order to Prepaid, we will refund the
              amount if your account has already been debited.
            </div>
          </>
        )}
      </CardOverlay> */}
      <div className={wrapperClassName}>
        <div className="card meta-card row md-flex items-center no-gutter min-ht-auto pd-15">
          <StatusBasedUI
            paymentInfo={paymentInfo}
            isAfterOFD={['DELIVERED', 'RETURNED', 'DTO'].includes(
              trackingStatus.toUpperCase()
            )}
          />
        </div>
      </div>
    </>
  );
};

Payment.propTypes = {
  paymentInfo: PropTypes.any,
  getPayment: PropTypes.any,
  postHandler: PropTypes.any,
  wrapperClassName: PropTypes.string,
  location: PropTypes.any,
  history: PropTypes.any,
  tracking: PropTypes.any
};

PaymentFailedModalBody.propTypes = {
  onRetry: PropTypes.any,
  title: PropTypes.string,
  description: PropTypes.any
};

const mapStateToProps = state => ({
  paymentInfo: state.paymentInfo,
  tracking: state.tracking
});
const mapDispatchToProps = dispatch => ({
  getPayment: () => dispatch(getPaymentInfo()),
  postHandler: query => dispatch(postPaymentInfo(query))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Payment)
);
