import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { ga4ClickEvent } from '../../utils/helper';

class NetMeds extends Component {
  constructor() {
    super();
    this.gaEvents = this.gaEvents.bind(this);
  }

  gaEvents(e, eventName, label, action) {
    ga4ClickEvent({ category: eventName, action, label, props: this.props });
    ReactGA.event({
      category: eventName,
      action: action,
      label: label
    });
  }

  render() {
    const { user } = this.props;
    return (
      <div className="netmeds">
        <footer
          className={`container-fluid copyright ${
            user.hideLink
              ? ''
              : user.appLink &&
                user.subdomain === 'hopscotch' &&
                this.context.router.history.location.pathname === '/'
              ? 'xs-pd-btm-100'
              : ''
          }`}
        >
          <div className="row col-gutter-130">
            <div className="col-md-12 col-xs-24 pd-10">
              <p>
                Copyright © 2022 Netmeds Marketplace Limited.
                <br /> All rights Reserved
              </p>
            </div>
            <div className="col-md-12 col-xs-24 pd-10">
              <span className="right-block text-right xs-text-left">
                {' '}
                <a href="https://www.netmeds.com/sitemap">Sitemap</a> |{' '}
                <a href="https://www.netmeds.com/privacy-policy">
                  Privacy Policy
                </a>{' '}
                |{' '}
                <a href="https://www.netmeds.com/terms-and-conditions">
                  Terms of use
                </a>{' '}
              </span>
            </div>
          </div>
        </footer>
        {/*<div className="container-fluid copyright">
          <div className="row col-gutter-130">
            <div className="col-md-12 col-xs-24 pd-10">
              <p>Copyright © 2022 Netmeds Marketplace Limited.<br /> All rights Reserved</p>
            </div>
            <div className="col-md-12 col-xs-24 pd-10">
              <span className="right-block text-right xs-text-left"> <a href="https://www.netmeds.com/sitemap">Sitemap</a> | <a href="https://www.netmeds.com/privacy-policy">Privacy Policy</a> | <a href="https://www.netmeds.com/terms-and-conditions">Terms of use</a> </span>
            </div>
          </div>
        </div>
      */}
      </div>
    );
  }
}

NetMeds.contextTypes = {
  router: PropTypes.object.isRequired
};

NetMeds.propTypes = {
  tracking: PropTypes.object,
  user: PropTypes.object,
  page: PropTypes.object
};

const mapStateToProps = state => ({
  tracking: state.tracking,
  user: state.user,
  page: state.page
});

export default connect(mapStateToProps, null)(NetMeds);
