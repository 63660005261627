import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class Pantaloons extends Component {
  render() {
    const { tracking, user } = this.props;
    return (
      <header className={`container-fluid header-wrapper ${user.subdomain} pantaloons-xs`}>
        <div className="row">
        <div className="col-md-24 col-xs-24 text-center visible-xs">
            <a
              href={tracking.theme.meta_data.website || '#'}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="common logo"
                src="https://pyck-res-bucket.s3-ap-southeast-1.amazonaws.com/clickpost_assets/logo/pantaloons_logo.svg"
                alt="logo"
              />
            </a>
          </div>
          <div className="col-md-24 col-xs-24 text-left hidden-xs">
            <a
              href={tracking.theme.meta_data.website || '#'}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="common logo"
                src={tracking.theme.logo || '/images/logo_placeholder.svg'}
                alt="logo"
              />
            </a>
          </div>
        </div>
      </header>
    );
  }
}

Pantaloons.propTypes = {
  tracking: PropTypes.object,
  user: PropTypes.object
};

const mapStateToProps = state => ({
  tracking: state.tracking,
  user: state.user
});

export default connect(
    mapStateToProps,
    null
  )(Pantaloons);
