import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class LouisPhilippe extends Component {
  render() {
    const { tracking, user } = this.props;
    return (
      <header
        className={`container-fluid header-wrapper ${user.subdomain} louisPhilippe-xs`}
      >
        <div className="row">
          <div className="col-md-24 col-xs-24 text-center visible-xs">
            <a
              href={tracking.theme.meta_data.website || '#'}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="common logo"
                src={
                  tracking.theme.logo ||
                  'https://pyck-res-bucket.s3-ap-southeast-1.amazonaws.com/clickpost_assets/logo/Louis_Philippe_Logo_Latest.jpg'
                }
                alt="logo"
              />
            </a>
          </div>
          <div className="col-md-24 col-xs-24 text-left hidden-xs">
            <a
              href={tracking.theme.meta_data.website || '#'}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="common logo"
                src={tracking.theme.logo || '/images/logo_placeholder.svg'}
                alt="logo"
              />
            </a>
          </div>
        </div>
      </header>
    );
  }
}

LouisPhilippe.propTypes = {
  tracking: PropTypes.object,
  user: PropTypes.object
};

const mapStateToProps = state => ({
  tracking: state.tracking,
  user: state.user
});

export default connect(mapStateToProps, null)(LouisPhilippe);
