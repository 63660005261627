import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import {
  trackingInfo,
  customerFeed as csFeed,
  googleSearch as gSearch,
  orderInfo as oInfo,
  reasons as reasonsObj,
  returnStatus as returnStatusObj,
  npr as nprObj
} from './utils/entity_mapping';

const trackingInit = Object.assign(trackingInfo, {
  isFetching: true,
  error: false
});
const googleSearchInit = Object.assign(gSearch);
const tracking = (state = trackingInit, action) => {
  switch (action.type) {
    case 'TRACKING_REQUEST':
      return {
        ...state,
        ...action.tracking
      };
    case 'TRACKING_SUCCESS':
    case 'TRACKING_EDD_SUCCESS':
      return {
        ...state,
        ...action.tracking
      };
    case 'TRACKING_UPDATE':
      return {
        ...state,
        ...action.tracking
      };
    case 'REVERSE_TRACKING_UPDATE':
      return {
        ...state,
        ...action.data
      };
    case 'TRACKING_FAILURE':
      return {
        ...state,
        ...action.tracking
      };
    case 'TRACKING_THEME_UPDATE':
      return {
        ...state,
        ...action.tracking
      };
    case 'TRACKING_RESET':
      return {
        ...state,
        ...action.tracking
      };
    default:
      return state;
  }
};

const rating = (state = {}, action) => {
  switch (action.type) {
    case 'RATING_REQUEST':
      return {
        ...state,
        ...action.rating
      };
    case 'RATING_SUCCESS':
      return {
        ...state,
        ...action.rating
      };
    case 'RATING_FAILURE':
      return {
        ...state,
        ...action.rating
      };
    default:
      return state;
  }
};

const reverseRating = (state = {}, action) => {
  switch (action.type) {
    case 'RATING_REQUEST_REVERSE':
      return {
        ...state,
        ...action.reverseRating
      };
    case 'RATING_SUCCESS_REVERSE':
      return {
        ...state,
        ...action.reverseRating
      };
    case 'RATING_FAILURE_REVERSE':
      return {
        ...state,
        ...action.reverseRating
      };
    default:
      return state;
  }
};

const opsFeed = (state = {}, action) => {
  switch (action.type) {
    case 'OPS_FEED_REQUEST':
      return {
        ...state,
        ...action.opsFeed
      };
    case 'OPS_FEED_SUCCESS':
      return {
        ...state,
        ...action.opsFeed
      };
    case 'OPS_FEED_FAILURE':
      return {
        ...state,
        ...action.opsFeed
      };
    default:
      return state;
  }
};

const customerFeed = (state = csFeed, action) => {
  switch (action.type) {
    case 'CUSTOMER_FEED_CONFIG':
      return {
        ...state,
        ...action.data
      };
    case 'CUSTOMER_FEED_REQUEST':
      return {
        ...state,
        ...action.customerFeed
      };
    case 'CUSTOMER_FEED_SUCCESS':
      return {
        ...state,
        ...action.customerFeed
      };
    case 'CUSTOMER_FEED_FAILURE':
      return {
        ...state,
        ...action.customerFeed
      };
    default:
      return state;
  }
};

const npr = (state = nprObj, action) => {
  switch (action.type) {
    case 'NPR_OPS_FEED_REQUEST':
      return {
        ...state,
        ops: {
          ...state.ops,
          isFetching: true,
          error: false
        }
      };
    case 'NPR_OPS_FEED_SUCCESS':
      return {
        ...state,
        ops: {
          ...state.ops,
          ...action.ops,
          isFetching: false,
          error: false
        }
      };
    case 'NPR_OPS_FEED_FAILURE':
      return {
        ...state,
        ops: {
          ...state.ops,
          isFetching: false,
          error: true
        }
      };
    case 'NPR_CUSTOMER_FEED_REQUEST':
      return {
        ...state,
        customer: {
          ...state.customer,
          isFetching: true,
          error: false
        }
      };
    case 'NPR_CUSTOMER_FEED_SUCCESS':
      return {
        ...state,
        customer: {
          ...state.customer,
          ...action.customer,
          isFetching: false,
          error: false
        }
      };
    case 'NPR_CUSTOMER_FEED_FAILURE':
      return {
        ...state,
        customer: {
          ...state.customer,
          isFetching: false,
          error: true
        }
      };
    default:
      return state;
  }
};

const user = (
  state = {
    toggleNav: false,
    modal: false,
    bannerHeight: 0,
    lang: 'en',
    routePrefix: ''
  },
  action
) => {
  switch (action.type) {
    case 'USER_REQUEST':
      return {
        ...state,
        ...action.user
      };
    case 'USER_MODAL':
    case 'USER_UPDATE':
      return {
        ...state,
        ...action.user
      };
    default:
      return state;
  }
};

const page = (
  state = {
    waybill: '',
    cpId: '',
    orderId: '',
    source: '',
    appOnly: false,
    courierRating: null,
    issueId: null
  },
  action
) => {
  switch (action.type) {
    case 'PAGE_REQUEST':
      return {
        ...state,
        ...action.page
      };
    default:
      return state;
  }
};

const googleSearch = (state = googleSearchInit, action) => {
  switch (action.type) {
    case 'GOOGLE_SEARCH_REQUEST':
    case 'GOOGLE_SEARCH_RESET':
      return {
        ...state,
        ...action.googleSearch
      };
    case 'GOOGLE_SEARCH_SUCCESS':
      return {
        ...state,
        ...action.googleSearch
      };
    case 'GOOGLE_SEARCH_FAILURE':
      return {
        ...state,
        ...action.googleSearch
      };
    default:
      return state;
  }
};

const orderInfo = (state = oInfo, action) => {
  switch (action.type) {
    case 'ORDER_INFO_REQUEST':
    case 'ORDER_INFO_SUCCESS':
    case 'ORDER_INFO_FAILURE':
    case 'RETURN_LOGIN_REQUEST':
    case 'RETURN_LOGIN_SUCCESS':
    case 'RETURN_LOGIN_FAILURE':
    case 'RETURN_CLICKED_EVENT':
    case 'CHECKBOX_CLICKED_EVENT':
    case 'ADD_PRODUCT':
    case 'REMOVE_PRODUCT':
    case 'STEP_EVENT':
    case 'ACTION_UPDATE':
    case 'DATE_EVENT':
    case 'ADDRESS_UPDATE':
    case 'PINCODE_SERVICEABILITY_REQUEST':
    case 'PINCODE_SERVICEABILITY_SUCCESS':
    case 'PINCODE_SERVICEABILITY_FAILURE':
    case 'UPDATE_BANK_DETAIL':
    case 'UPDATE_UPI_DETAILS':
    case 'TOGGLE_BANK_FORM_VALIDATION':
    case 'SELECT_REFUND_METHOD':
    case 'UPDATE_SERVICEABLE_AREAS':
    case 'EXCHANGE_NUDGE':
      return {
        ...state,
        ...action.data
      };
    case 'RESET_BANK_DETAIL':
      return {
        ...state,
        ...state.bankDetail,
        bankDetail: {
          name: '',
          accountNumber: '',
          ifsccode: '',
          bankName: '',
          branchName: ''
        }
      };
    case 'RESET_UPI_DETAILS':
      return {
        ...state,
        ...state.upiDetails,
        upiDetails: {
          upiName: '',
          upiId: ''
        }
      };
    case 'SET_STORE_CREDIT_INFO':
    case 'UPDATE_STORE_CREDIT_INFO':
      return {
        ...state,
        ...state.storeCredit,
        storeCredit: action.data
      };
    case 'RESET_RETURN':
      state = oInfo;
      return state;
    case 'UPDATE_EXCHANGE_INFO':
      return {
        ...state,
        exchange: { ...state.exchange, ...action.data }
      };
    default:
      return state;
  }
};

const reasons = (state = reasonsObj, action) => {
  switch (action.type) {
    case 'REASONS_REQUEST':
    case 'REASONS_SUCCESS':
    case 'REASONS_FAILURE':
      return {
        ...state,
        ...action.data
      };
    default:
      return state;
  }
};

const returnStatus = (state = returnStatusObj, action) => {
  switch (action.type) {
    case 'RETURN_STATUS_REQUEST':
    case 'RETURN_STATUS_SUCCESS':
    case 'RETURN_STATUS_FAILURE':
      return {
        ...state,
        ...action.data
      };
    default:
      return state;
  }
};

const auth = (state = { isAuthenticated: false }, action) => {
  switch (action.type) {
    case 'AUTH_REQUEST':
    case 'AUTH_SUCCESS':
    case 'AUTH_FAILURE':
      return {
        ...state,
        ...action.data
      };
    default:
      return state;
  }
};

const file = (state = { list: [] }, action) => {
  switch (action.type) {
    case 'FILE_REQUEST':
    case 'FILE_RENDER':
    case 'FILE_FAILURE':
    case 'ADD_IMAGE':
    case 'REMOVE_IMAGE':
    case 'SET_IMAGE':
    case 'RESET_PRODUCT_IMAGE':
    case 'UPLOAD_IMAGE_CONFIG':
    case 'PRE_SIGNED_URL_IMAGE':
    case 'PRE_SIGNED_URL_UPLOADED':
    case 'UPLOADED_IMAGE_FILE':
      return {
        ...state,
        ...action.data
      };
    case 'RESET_IMAGE':
      return { ...action.data };
    default:
      return state;
  }
};

const variants = (state = { isFetching: false, list: [] }, action) => {
  switch (action.type) {
    case 'VARIANTS_FETCHING':
      return {
        ...state,
        isFetching: true,
        isError: false
      };
    case 'VARIANTS_FETCHING_ERROR':
      return {
        list: [],
        isFetching: false,
        isError: true
      };
    case 'ADD_VARIANTS':
      return {
        ...state,
        ...action.data,
        isFetching: false,
        isError: false
      };
    case 'RESET_VARIANTS':
      return {
        list: [],
        isFetching: false,
        isError: false
      };
    default:
      return state;
  }
};

const exchangeConfig = (
  state = {
    reasonsWithSubReasons: [],
    instruction: ''
  },
  action
) => {
  switch (action.type) {
    case 'UPDATE_CONFIG':
      return {
        ...state,
        ...action.data
      };
    default:
      return state;
  }
};

const paymentInfo = (
  state = {
    isFetching: true,
    isError: false
  },
  action
) => {
  switch (action.type) {
    case 'SUBMITTED_PAYMENT_INFO':
      return {
        ...state,
        isSubmitting: false
      };
    case 'SUBMITTING_PAYMENT_INFO':
      return {
        ...state,
        isSubmitting: true
      };
    case 'PAYMENT_INFO_FAILED':
      return {
        ...state,
        isError: true
      };
    case 'FETCH_PAYMENT_INFO':
      return {
        ...state,
        isFetching: true
      };
    case 'SET_PAYMENT_INFO':
      return {
        ...action.data
      };
    default:
      return state;
  }
};

const ipAddress = (
  state = { isFetching: false, isError: false, isLoaded: false, ip: null },
  action
) => {
  switch (action.type) {
    case 'GET_IP_ADDRESS':
      return {
        ...state,
        isFetching: true,
        isError: false
      };
    case 'SET_IP_ADDRESS':
      return {
        ...state,
        isFetching: false,
        isLoaded: true,
        ip: action.data
      };
    case 'IP_ADDRESS_ERROR':
      return {
        ...state,
        isFetching: false,
        isError: true
      };
    default:
      return state;
  }
};

const returnConfig = (
  state = {
    isFetching: false,
    isError: false,
    refundConfig: {}
  },
  action
) => {
  switch (action.type) {
    case 'RETURN_CONFIG_FETCHING':
      return {
        ...state,
        isFetching: true,
        isError: false
      };
    case 'RETURN_CONFIG_SUCCESS':
      return {
        ...state,
        isFetching: false,
        isError: false,
        refundConfig: action.data ?? {}
      };
    case 'RETURN_CONFIG_ERROR':
      return {
        ...state,
        isFetching: false,
        isError: true
      };
    default:
      return state;
  }
};

const appReducer = combineReducers({
  auth,
  form: formReducer,
  tracking,
  rating,
  opsFeed,
  customerFeed,
  user,
  page,
  googleSearch,
  orderInfo,
  reasons,
  variants,
  returnStatus,
  file,
  npr,
  exchangeConfig,
  paymentInfo,
  reverseRating,
  ipAddress,
  returnConfig
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
