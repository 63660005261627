import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { strings, copyrightYear } from '../../utils/localizedStrings';
import { ga4ClickEvent } from '../../utils/helper';

class Usplworld extends Component {
  gaEvents = (e, eventName, label, action) => {
    ga4ClickEvent({ category: eventName, action, label, props: this.props });
    ReactGA.event({
      category: eventName,
      action: action,
      label: label
    });
  };

  render() {
    const { user, orderInfo, tracking } = this.props;
    const enterpriseProfile = tracking.theme.meta_data;
    const { lang } = user;
    return orderInfo &&
      orderInfo.step &&
      orderInfo.step.current &&
      orderInfo.step.current < 2 ? (
      <footer
        className={`container-fluid usplworld footer-wrapper sticky-footer ${
          user.hideLink
            ? ''
            : user.appLink &&
              user.subdomain === 'hopscotch' &&
              this.context.router.history.location.search &&
              this.context.router.history.location.pathname === '/'
            ? 'xs-pd-btm-100'
            : ''
        }`}
      >
        <div className="row no-gutter">
          <Fragment>
            <div className="col-md-24 col-xs-24 text-left">
              <ul className="social-links">
                <li>
                  <a
                    href={enterpriseProfile.social.fb}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={e =>
                      this.gaEvents(
                        e,
                        'Social',
                        'facebook',
                        enterpriseProfile.social.fb
                      )
                    }
                  >
                    <span className="icon-fb" />
                  </a>
                </li>
                <li>
                  <a
                    href={enterpriseProfile.social.tw}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={e =>
                      this.gaEvents(
                        e,
                        'Social',
                        'twitter',
                        enterpriseProfile.social.tw
                      )
                    }
                  >
                    <span className="icon-twitter" />
                  </a>
                </li>
                <li className={`${enterpriseProfile.social.ig ? '' : 'hide'}`}>
                  <a
                    href={enterpriseProfile.social.ig}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={e =>
                      this.gaEvents(
                        e,
                        'Social',
                        'instagram',
                        enterpriseProfile.social.ig
                      )
                    }
                  >
                    <span className="icon-instagram" />
                  </a>
                </li>
                <li className={`${enterpriseProfile.social.yt ? '' : 'hide'}`}>
                  <a
                    href={enterpriseProfile.social.yt}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={e =>
                      this.gaEvents(
                        e,
                        'Social',
                        'youtube',
                        enterpriseProfile.social.yt
                      )
                    }
                  >
                    <span className="icon-youtube1" />
                  </a>
                </li>
              </ul>
              <span className="copyright text-gray pull-right">
                © {copyrightYear} {strings[lang].allRightsReserved}
              </span>
            </div>
          </Fragment>
        </div>
      </footer>
    ) : null;
  }
}

Usplworld.contextTypes = {
  router: PropTypes.object.isRequired
};

Usplworld.propTypes = {
  tracking: PropTypes.object,
  user: PropTypes.object,
  orderInfo: PropTypes.object,
  page: PropTypes.object
};

const mapStateToProps = state => ({
  tracking: state.tracking,
  user: state.user,
  orderInfo: state.orderInfo,
  page: state.page
});

export default connect(mapStateToProps, null)(Usplworld);
