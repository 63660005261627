const netmedsMenu = {
  level1: [
    {
      name: 'Prescriptions',
      url: 'prescriptions'
    },
    {
      name: 'Spotlight',
      url: 'non-prescriptions/spotlight'
    },
    {
      name: 'Ayush',
      url: 'non-prescriptions/ayush'
    },
    {
      name: 'Fitness',
      url: 'non-prescriptions/fitness'
    },
    {
      name: 'Personal Care',
      url: 'non-prescriptions/personal-care'
    },
    {
      name: 'Family Care',
      url: 'non-prescriptions/family-care'
    },
    {
      name: 'Lifestyle',
      url: 'non-prescriptions/lifestyle'
    },
    {
      name: 'Treatments',
      url: 'non-prescriptions/treatments'
    },
    {
      name: 'Devices',
      url: 'non-prescriptions/device'
    }
  ]
};
export { netmedsMenu };
