import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class TheSouledStore extends Component {
  render() {
    const { tracking } = this.props;
    const enterpriseProfile = tracking.theme.meta_data;
    return (
      <div className="tss">
        <div className="topbar" />
        <header className="container-fluid header-wrapper">
          <div className="row col-gutter-40">
            <div className="col-md-24 text-center col-xs-24">
              <a
                className="pos-rel"
                href={enterpriseProfile.website}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="logo"
                  src={tracking.theme.logo || '/images/logo_placeholder.svg'}
                  alt="logo"
                />
              </a>
            </div>
          </div>
        </header>
      </div>
    );
  }
}

TheSouledStore.propTypes = {
  tracking: PropTypes.object
};

const mapStateToProps = state => ({
  tracking: state.tracking
});

export default connect(
    mapStateToProps,
    null
  )(TheSouledStore);
