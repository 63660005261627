import React from 'react';
import PropTypes from 'prop-types';

const Pagination = ({
  className,
  hasNext,
  hasPrevious,
  onNext,
  onPrevious
}) => {
  return (
    <div className={`d-flex items-center justify-center ${className}`}>
      {hasPrevious ? (
        <button
          className="btn-icon d-flex items-center"
          disabled={!hasPrevious}
          onClick={onPrevious}
        >
          <span className=" text-secondary-color fs-16 fw-600">Previous</span>

          <img
            style={{ transform: 'rotate(180deg)' }}
            src="images/chevron_circle_right.svg"
            alt="chevron-right"
            className="scale-120 mrg-left-10"
          />
        </button>
      ) : null}
      <span className="mrg-left-10 text-secondary-color mrg-right-10" />
      {hasNext ? (
        <button
          className="btn-icon d-flex items-center"
          disabled={!hasNext}
          onClick={onNext}
        >
          <img
            src="images/chevron_circle_right.svg"
            alt="chevron-right"
            className="scale-120 mrg-right-10"
          />
          <span className=" text-secondary-color fs-16 fw-600">Next</span>
        </button>
      ) : null}
    </div>
  );
};
Pagination.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.any,
  onPrevious: PropTypes.any,
  hasNext: PropTypes.bool,
  hasPrevious: PropTypes.bool
};

export default Pagination;
