import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { copyrightYear } from '../../utils/localizedStrings';
import { ga4ClickEvent } from '../../utils/helper';
class CommonFooter extends Component {
  gaEvents = (e, eventName, label, action) => {
    ga4ClickEvent({ category: eventName, action, label, props: this.props });
    ReactGA.event({
      category: eventName,
      action: action,
      label: label
    });
  };
  render() {
    const { user, orderInfo, tracking } = this.props;
    const { lang } = user;
    const enterpriseProfile = tracking.theme.meta_data;
    // const { lang } = user;
    return orderInfo &&
      orderInfo.step &&
      orderInfo.step.current &&
      orderInfo.step.current < 2 ? (
      <footer
        className={`container-fluid footer-wrapper brandsforless sticky-footer ${
          user.hideLink
            ? ''
            : user.appLink &&
              user.subdomain === 'hopscotch' &&
              this.context.router.history.location.search &&
              this.context.router.history.location.pathname === '/'
            ? 'xs-pd-btm-100'
            : ''
        }`}
      >
        <div className="row col-gutter-60">
          <div className="col-xs-14 col-xs-offset-5 col-sm-10 col-sm-offset-7 col-md-8 col-md-offset-8">
            <div className="md-flex items-center justify-between">
              <div className="d-flex justify-center mrg-btm-10">
                <a
                  className="mrg-right-10"
                  href={
                    enterpriseProfile &&
                    enterpriseProfile.social &&
                    enterpriseProfile.social.fb
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={e =>
                    this.gaEvents(
                      e,
                      'Social',
                      'facebook',
                      enterpriseProfile &&
                        enterpriseProfile.social &&
                        enterpriseProfile.social.fb
                    )
                  }
                >
                  <span className="icon-fb" />
                </a>
                <a
                  className="mrg-right-10"
                  href={
                    enterpriseProfile &&
                    enterpriseProfile.social &&
                    enterpriseProfile.social.ig
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={e =>
                    this.gaEvents(
                      e,
                      'Social',
                      'instagram',
                      enterpriseProfile &&
                        enterpriseProfile.social &&
                        enterpriseProfile.social.ig
                    )
                  }
                >
                  <span className="icon-instagram" />
                </a>
                <a
                  className="mrg-right-10"
                  href={'https://wa.me/9718008333'}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={e =>
                    this.gaEvents(
                      e,
                      'Social',
                      'whatsapp',
                      'https://wa.me/9718008333'
                    )
                  }
                >
                  <span className="icon-whatsapp" />
                </a>
              </div>
              <div className="d-flex items-center justify-center mrg-btm-10">
                <span className="fs-12 mrg-right-10 hide visible-lg">
                  {lang === 'en' ? 'Download the app' : 'قم بتنزيل التطبيق'}
                </span>

                <a
                  href={enterpriseProfile.app.ios}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mrg-right-10"
                  onClick={e =>
                    this.gaEvents(
                      e,
                      'App Install',
                      user.device,
                      enterpriseProfile.app.ios
                    )
                  }
                >
                  <img src={'/images/app-store.svg'} height={30} alt="apple" />
                </a>
                <a
                  href={enterpriseProfile.app.android}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={e =>
                    this.gaEvents(
                      e,
                      'App Install',
                      user.device,
                      enterpriseProfile.app.android
                    )
                  }
                >
                  <img
                    src={'/images/google-play.svg'}
                    height={30}
                    alt="android"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="mrg-top-10 fs-12 text-center text-capitalize">
          Copyright {copyrightYear}. Brands for less all rights reserved
        </div>
      </footer>
    ) : null;
  }
}

CommonFooter.contextTypes = {
  router: PropTypes.object.isRequired
};

CommonFooter.propTypes = {
  tracking: PropTypes.object,
  user: PropTypes.object,
  orderInfo: PropTypes.object,
  page: PropTypes.object
};

const mapStateToProps = state => ({
  tracking: state.tracking,
  user: state.user,
  orderInfo: state.orderInfo,
  page: state.page
});

export default connect(mapStateToProps, null)(CommonFooter);
