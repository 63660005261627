/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { stylishopStrings } from '../../utils/localizedStrings';

class Stylishop extends Component {
  langSwitcher = () => {
    const { lang } = this.props.user;
    let newLang = 'en';
    if(lang === 'en'){
      newLang = 'ar';
    }
    let { pathname, search, hash } = this.props.location;
    let splitted = pathname.split('/');
    const idx = splitted.indexOf(lang) > -1;
    if(idx > -1){
      pathname = pathname.replace(lang, newLang);
      splitted[1] = newLang;
    } else {
      splitted.splice(1,0, newLang);
    }
    if(splitted.length === 2){
      splitted[2] = '';
    }
    window.location.href = splitted.join('/') + search + hash;
  }
  render() {
    const { tracking, user } = this.props;
    const { lang } = user;
    return (
      <header className={`container-fluid header-wrapper stylishop`}>
        <div className="row no-gutter">
          <div className="col-md-2 col-xs-24 text-left xs-text-center">
            <a
              href={tracking.theme.meta_data.website || '#'}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="logo"
                src={tracking.theme.logo || '/images/logo_placeholder.svg'}
                alt="logo"
              />
            </a>
          </div>
          <div className="col-md-20 col-xs-24 text-left hidden-xs">
            <ul className="sub-menu">
              <li><a href={`https://stylishop.com/${lang}/women.html`}>{stylishopStrings[lang].women}</a></li>
              <li><a href={`https://stylishop.com/${lang}/men.html`}>{stylishopStrings[lang].men}</a></li>
            </ul>
          </div>
          <div className="col-md-2 text-right hidden-xs">
            <p className="lang-switcher" onClick={this.langSwitcher}>{lang === 'en' ? 'عربى' : 'English'}</p>
          </div>
        </div>
      </header>
    );
  }
}

Stylishop.propTypes = {
  tracking: PropTypes.object,
  user: PropTypes.object,
  location: PropTypes.object
};

const mapStateToProps = state => ({
  tracking: state.tracking,
  user: state.user
});

export default withRouter(connect(
    mapStateToProps,
    null
  )(Stylishop));
