import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const HerbalChakra = props => {
  const { user } = props;
  return (
    <header className={`container-fluid header-wrapper ${user.subdomain}`}>
      <div className="row no-gutter md-flex-end">
        <div className="col-md-24 text-center">
          <a
            href="https://www.herbyangel.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="common logo"
              src="https://pyck-res-bucket.s3.ap-southeast-1.amazonaws.com/enterprise/theme/herbalchakra/Herby_Angel_Logo.png"
              alt="logo"
            />
          </a>
          <a
            href="https://purelyyours.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="mrg-left-20"
          >
            <img
              className="common logo"
              src="https://pyck-res-bucket.s3.ap-southeast-1.amazonaws.com/enterprise/theme/herbalchakra/Purely_Yours_Logo.png"
              alt="logo"
            />
          </a>
        </div>
      </div>
    </header>
  );
};

HerbalChakra.propTypes = {
  tracking: PropTypes.object,
  user: PropTypes.object
};

const mapStateToProps = state => ({
  tracking: state.tracking,
  user: state.user
});

export default connect(mapStateToProps, null)(HerbalChakra);
