import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { currencyMapper } from '../../../utils/entity_mapping';

const GridCard = ({
  id,
  label,
  price,
  orderInfo,
  badge,
  onClick,
  tracking,
  src,
  isTextIcon,
  disabled
}) => {
  const {
    theme: { theme_color }
  } = tracking;
  const { config } = tracking.theme;

  return (
    <div
      className={`grid-card ${disabled ? 'disable-element' : ''}`}
      onClick={() => onClick(id, label, price)}
      role="presentation"
    >
      {badge ? <div className="badge-top-right">{badge}</div> : null}
      {isTextIcon ? (
        <div
          className="img rounded d-flex text-theme items-center justify-center"
          style={{
            fontSize: 75,
            background: theme_color + '19'
          }}
        >
          {label?.slice(0, 1).toUpperCase()}
        </div>
      ) : (
        <img
          src={src || 'images/img-placeholder.svg'}
          alt="category"
          className="img object-fit-cover rounded"
        />
      )}
      <div className="mrg-top-5 truncate-2-lines">
        {label?.length > 60 ? label.slice(0, 60) + '...' : label}
      </div>
      {price && (
        <div className="fw-600 mrg-top-5">
          { currencyMapper(orderInfo.currency ?? config.country_code)}
          {price}
        </div>
      )}
    </div>
  );
};
GridCard.propTypes = {
  id: PropTypes.any,
  label: PropTypes.string,
  badge: PropTypes.any,
  src: PropTypes.string,
  onClick: PropTypes.any,
  price: PropTypes.any,
  orderInfo: PropTypes.any,
  tracking: PropTypes.any,
  isTextIcon: PropTypes.bool,
  disabled: PropTypes.bool
};

const mapStateToProps = state => ({
  orderInfo: state.orderInfo,
  tracking: state.tracking
});

export default connect(mapStateToProps)(GridCard);
