/* eslint-disable import/no-unresolved */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Loadable from 'react-loadable';
import 'url-search-params-polyfill';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import App from './App';
import configureStore from './utils/configureStore';
import './styles/index.scss';
import { GA, GA4 } from './utils/constants';

const store = configureStore(window.__INITIAL_STATE__);
const { user, tracking, page } = store.getState();
ReactGA.initialize(GA.TRACKING_ID);
ReactGA4.initialize(GA4.TRACKING_ID);
Loadable.preloadReady().then(() => {
  ReactDOM.hydrate(
    <Provider store={store}>
      <BrowserRouter>
        <App
          location={window && window.location}
          user={user}
          page={page}
          dispatch={store.dispatch}
          theme={tracking.theme}
          requiredLogin={
            tracking.theme.meta_data && tracking.theme.meta_data.required_login
          }
        />
      </BrowserRouter>
    </Provider>,
    document.getElementById('root')
  );
});
