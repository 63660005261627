import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const Redtag = props => {
  const { user, tracking } = props;
  return (
    <header className={`container-fluid header-wrapper ${user.subdomain}`}>
      <div className="row no-gutter md-flex-end">
        <div className="col-md-11 col-xs-12 col-md-offset-1 text-left xs-text-center">
          <a
            href={
              (tracking.theme.meta_data && tracking.theme.meta_data.website) ||
              '#'
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="common logo"
              src={tracking.theme.logo || '/images/logo_placeholder.svg'}
              alt="logo"
            />
          </a>
        </div>
        <div className="col-md-11 col-xs-12 text-right xs-text-center">
          <a
            href={
              (tracking.theme.meta_data && tracking.theme.meta_data.website) ||
              '#'
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="common arabic-logo"
              src='https://pyck-res-bucket.s3.ap-southeast-1.amazonaws.com/clickpost_assets/RT_Arabic_logo.jpg'
              alt="logo"
            />
          </a>
        </div>
      </div>
    </header>
  );
};

Redtag.propTypes = {
  tracking: PropTypes.object,
  user: PropTypes.object
};

const mapStateToProps = state => ({
  tracking: state.tracking,
  user: state.user
});

export default connect(mapStateToProps, null)(Redtag);
