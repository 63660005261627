import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { strings, copyrightYear } from '../../utils/localizedStrings';
import { ga4ClickEvent } from '../../utils/helper';

class Zoomin extends Component {
  gaEvents = (e, eventName, label, action) => {
    ga4ClickEvent({ category: eventName, action, label, props: this.props });
    ReactGA.event({
      category: eventName,
      action: action,
      label: label
    });
  };

  render() {
    const { user, orderInfo, tracking } = this.props;
    const enterpriseProfile = tracking.theme.meta_data;
    const { lang } = user;
    const notShowCPBranding = tracking.theme.meta_data?.udf?.filter(
      item => item.key === 'notShowCPBranding'
    )?.[0]?.value;

    return orderInfo &&
      orderInfo.step &&
      orderInfo.step.current &&
      orderInfo.step.current < 2 ? (
      <footer className="container-fluid footer-wrapper sticky-footer">
        <div className="row col-gutter-60">
          <Fragment>
            <div className="col-md-24 col-xs-24 text-left xs-text-center md-text-center">
              <div className="col-md-14 col-lg-17 col-sm-24">
                <ul className="social-links">
                  {enterpriseProfile &&
                  enterpriseProfile.social &&
                  enterpriseProfile.social.fb ? (
                    <li>
                      <a
                        href={enterpriseProfile.social.fb}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={e =>
                          this.gaEvents(
                            e,
                            'Social',
                            'facebook',
                            enterpriseProfile.social.fb
                          )
                        }
                      >
                        <span className="icon-fb" />
                      </a>
                    </li>
                  ) : null}
                  {enterpriseProfile &&
                  enterpriseProfile.social &&
                  enterpriseProfile.social.tw ? (
                    <li>
                      <a
                        href={enterpriseProfile.social.tw}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={e =>
                          this.gaEvents(
                            e,
                            'Social',
                            'twitter',
                            enterpriseProfile.social.tw
                          )
                        }
                      >
                        <span className="icon-twitter" />
                      </a>
                    </li>
                  ) : null}
                  {enterpriseProfile &&
                  enterpriseProfile.social &&
                  enterpriseProfile.social.ig ? (
                    <li
                      className={`${enterpriseProfile.social.ig ? '' : 'hide'}`}
                    >
                      <a
                        href={enterpriseProfile.social.ig}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={e =>
                          this.gaEvents(
                            e,
                            'Social',
                            'instagram',
                            enterpriseProfile.social.ig
                          )
                        }
                      >
                        <span className="icon-instagram" />
                      </a>
                    </li>
                  ) : null}
                  {enterpriseProfile &&
                  enterpriseProfile.social &&
                  enterpriseProfile.social.yt ? (
                    <li
                      className={`${enterpriseProfile.social.yt ? '' : 'hide'}`}
                    >
                      <a
                        href={enterpriseProfile.social.yt}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={e =>
                          this.gaEvents(
                            e,
                            'Social',
                            'youtube',
                            enterpriseProfile.social.yt
                          )
                        }
                      >
                        <span className="icon-youtube1" />
                      </a>
                    </li>
                  ) : null}

                  {enterpriseProfile &&
                  enterpriseProfile.app &&
                  (enterpriseProfile.app.android ||
                    enterpriseProfile.app.ios) ? (
                    <li className="display-block-desktop">
                      |{' '}
                      <span className="pd-left-10">
                        {' '}
                        {strings[lang].downloadApp}
                      </span>
                    </li>
                  ) : null}

                  {enterpriseProfile &&
                  enterpriseProfile.app &&
                  enterpriseProfile.app.android ? (
                    <li className="display-block-desktop">
                      <a
                        href={enterpriseProfile.app.android}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={e =>
                          this.gaEvents(
                            e,
                            'App Install',
                            user.device,
                            enterpriseProfile.app.android
                          )
                        }
                      >
                        <span className="icon-android" />
                      </a>
                    </li>
                  ) : null}
                  {enterpriseProfile &&
                  enterpriseProfile.app &&
                  enterpriseProfile.app.ios ? (
                    <li className="display-block-desktop">
                      <a
                        href={enterpriseProfile.app.ios}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={e =>
                          this.gaEvents(
                            e,
                            'App Install',
                            user.device,
                            enterpriseProfile.app.ios
                          )
                        }
                      >
                        <span className="icon-apple" />
                      </a>
                    </li>
                  ) : null}
                </ul>
              </div>
              <div
                className={`col-md-10 col-lg-7 col-sm-24 xs-mrg-top-25 xs-col-gutter-26 clickpost-info ${notShowCPBranding &&
                  'xs-text-center text-right md-text-center'}`}
              >
                <span className="copyright text-gray">
                  © {copyrightYear} {strings[lang].allRightsReserved}
                </span>
                {notShowCPBranding ? null : (
                  <span className="text-gray powered">
                    <span>Powered by </span> {' '}
                    <a
                      href="https://www.clickpost.ai/post-purchase-customer-experience-software"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://pyck-res-bucket.s3.ap-southeast-1.amazonaws.com/clickpost_assets/Clickpost+Logo+HR2.png"
                        alt="clickpost"
                      />
                    </a>
                  </span>
                )}
              </div>
            </div>
          </Fragment>
        </div>
      </footer>
    ) : null;
  }
}

Zoomin.contextTypes = {
  router: PropTypes.object.isRequired
};

Zoomin.propTypes = {
  tracking: PropTypes.object,
  user: PropTypes.object,
  orderInfo: PropTypes.object,
  page: PropTypes.object
};

const mapStateToProps = state => ({
  tracking: state.tracking,
  user: state.user,
  page: state.page
});

export default connect(mapStateToProps, null)(Zoomin);
