import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class PeeSafe extends Component {
  render() {
    const { tracking } = this.props;
    return (
      <header className="container-fluid header-wrapper common">
        <div className="row col-gutter-60">
          <div className="col-md-24 col-xs-24 text-center">
            <a
              href={tracking.theme.meta_data.website || '#'}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="logo xs-pd-top-10 xs-pd-btm-10"
                src={tracking.theme.logo || '/images/logo_placeholder.svg'}
                alt="logo"
              />
            </a>
          </div>
        </div>
      </header>
    );
  }
}

PeeSafe.propTypes = {
  tracking: PropTypes.object,
  user: PropTypes.object
};

const mapStateToProps = state => ({
  tracking: state.tracking,
  user: state.user
});

export default connect(
    mapStateToProps,
    null
  )(PeeSafe);
