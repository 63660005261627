import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class Sleepycat extends Component {
  render() {
    const { tracking } = this.props;
    const enterpriseProfile = tracking.theme.meta_data || {};
    return (
      <Fragment>
        <div className="container-fluid top-bar sleepycat hidden-xs">
          <div className="row no-gutter">
            <div className="col-md-12 text-left">
              <a
                className="emi-label"
                href="https://www.sleepycat.in/no-cost-emi/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Avail 0% No cost EMI on Credit Cards
              </a>
            </div>
            <div className="col-md-12 text-right">
              <ul className="menuLinks">
                <li>
                  <a
                    href="https://www.sleepycat.in/track-order/"
                    aria-current="page"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Track Order
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.sleepycat.in/survey/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Sleep Score
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.sleepycat.in/faq/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    FAQs
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.sleepycat.in/contact/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Contact
                  </a>
                </li>
                <li>
                  <span className="icon icon-phone" />
                  <a
                    href={`tel:${enterpriseProfile.contact}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {enterpriseProfile.contact}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <header className="container-fluid header-wrapper sleepycat">
          <div className="row no-gutter">
            <div className="container">
              <div className="col-md-6 col-xs-24 text-left xs-text-center">
                <a
                  href={tracking.theme.meta_data.website || '#'}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className=" logo"
                    src={
                      'https://pyck-res-bucket.s3.amazonaws.com/enterprise/theme/sleepycat/1663738498.849.jpg' ||
                      '/images/logo_placeholder.svg'
                    }
                    alt="logo"
                  />
                </a>
              </div>
              <div className="col-md-18 hidden-xs">
                <ul className="menuLinks">
                  <li>
                    <a href="https://www.sleepycat.in/mattresses/">Mattress</a>
                  </li>
                  <li>
                    <a href="https://www.sleepycat.in/products/">Accessories</a>
                  </li>
                  <li>
                    <a href="https://www.sleepycat.in/product/sleepycat-smartbase/">
                      Smart Base
                    </a>
                  </li>
                  <li>
                    <a href="https://www.sleepycat.in/about-us/">About</a>
                  </li>
                  <li>
                    <a href="https://www.sleepycat.in/giving-back/">
                      Giving Back
                    </a>
                  </li>
                  <li>
                    <a href="https://www.sleepycat.in/blog/">Blog</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </header>
      </Fragment>
    );
  }
}

Sleepycat.propTypes = {
  tracking: PropTypes.object
};

const mapStateToProps = state => ({
  tracking: state.tracking
});

export default connect(mapStateToProps, null)(Sleepycat);
