import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class Asenne extends Component {
  render() {
    const { tracking, user } = this.props;
    return (
      <header className={`container-fluid header-wrapper ${user.subdomain}`}>
        <div className="row no-gutter">
          <div className="col-md-24 col-xs-24 text-center">
            <a
              href={tracking.theme.meta_data.website || '#'}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="logo">ASENNE</span>
            </a>
          </div>
        </div>
      </header>
    );
  }
}

Asenne.propTypes = {
  tracking: PropTypes.object,
  user: PropTypes.object
};

const mapStateToProps = state => ({
  tracking: state.tracking,
  user: state.user
});

export default connect(
    mapStateToProps,
    null
  )(Asenne);
