import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { strings, copyrightYear } from '../../utils/localizedStrings';
import { ga4ClickEvent } from '../../utils/helper';

class Kushals extends Component {
  gaEvents = (e, eventName, label, action) => {
    ga4ClickEvent({ category: eventName, action, label, props: this.props });
    ReactGA.event({
      category: eventName,
      action: action,
      label: label
    });
  };
  render() {
    const { user, orderInfo, tracking } = this.props;
    const enterpriseProfile = tracking.theme.meta_data;
    const { lang } = user;
    return orderInfo &&
      orderInfo.step &&
      orderInfo.step.current &&
      orderInfo.step.current < 2 ? (
      <footer
        className={`container-fluid footer-wrapper sticky-footer ${
          user.hideLink
            ? ''
            : user.appLink &&
              user.subdomain === 'hopscotch' &&
              this.context.router.history.location.search &&
              this.context.router.history.location.pathname === '/'
            ? 'xs-pd-btm-100'
            : ''
        }`}
      >
        <div className="row col-gutter-60">
          {/* <div className="row col-gutter-60"> */}
          <Fragment>
            {user.enterprise === 'herbalife' ? (
              <div className="col-md-8 col-xs-24 pos-rel">
                <p>
                  {/* <span className="icon icon-email" /> */}
                  <span className="info">
                    You can log and track all your inquiries on
                  </span>
                </p>
                <p>
                  <span className="info user-select">
                    24x7 Online Support:{' '}
                    <a
                      className="support-link"
                      href="https://herbalife-econnect.hrbl.com/intelligentchannel/faces/createinquiry"
                    >
                      {' '}
                      <b>I-channel (Click Here)</b>
                    </a>
                  </span>
                </p>
              </div>
            ) : (
              enterpriseProfile &&
              enterpriseProfile.email && (
                <div className="col-md-8 col-xs-24 pos-rel">
                  <p>
                    <span className="icon icon-email" />
                    <span className="info">You can email us on</span>
                  </p>
                  <p>
                    <span className="info user-select">
                      <a
                        href={`mailto:${enterpriseProfile &&
                          enterpriseProfile.email}`}
                        onClick={e =>
                          this.gaEvents(
                            e,
                            'Email ID',
                            'help',
                            enterpriseProfile && enterpriseProfile.email
                          )
                        }
                      >
                        {enterpriseProfile && enterpriseProfile.email}
                      </a>
                    </span>
                  </p>
                </div>
              )
            )}

            <div className="col-md-7 col-xs-24 xs-mrg-top-20 pos-rel">
              {!(
                enterpriseProfile &&
                enterpriseProfile.time &&
                enterpriseProfile.time.to
              ) &&
              !(
                enterpriseProfile &&
                enterpriseProfile.time &&
                enterpriseProfile.time.from
              ) ? null : (
                <p>
                  <span className="icon icon-phone" />
                  Call us{' '}
                  <span>
                    {!(
                      enterpriseProfile &&
                      enterpriseProfile.time &&
                      enterpriseProfile.time.to
                    ) &&
                    !(
                      enterpriseProfile &&
                      enterpriseProfile.time &&
                      enterpriseProfile.time.from
                    ) ? null : (
                      <span>
                        (
                        {enterpriseProfile &&
                          enterpriseProfile.time &&
                          enterpriseProfile.time.from}{' '}
                        to{' '}
                        {enterpriseProfile &&
                          enterpriseProfile.time &&
                          enterpriseProfile.time.to}
                        {enterpriseProfile &&
                          enterpriseProfile.day &&
                          enterpriseProfile.day.from &&
                          enterpriseProfile &&
                          enterpriseProfile.day &&
                          enterpriseProfile.day.to && (
                            <span>
                              {' '}
                              {enterpriseProfile &&
                                enterpriseProfile.day &&
                                enterpriseProfile.day.from}{' '}
                              to{' '}
                              {enterpriseProfile &&
                                enterpriseProfile.day &&
                                enterpriseProfile.day.to}
                            </span>
                          )}
                        )
                      </span>
                    )}
                  </span>
                </p>
              )}

              <p>
                <span className="user-select">
                  <a
                    href={`https://wa.me/919591321696`}
                    onClick={e =>
                      this.gaEvents(
                        e,
                        'Contact Number',
                        'help',
                        enterpriseProfile && enterpriseProfile.contact
                      )
                    }
                  >
                    {enterpriseProfile && enterpriseProfile.contact}
                  </a>
                </span>
              </p>
            </div>
            <div className="col-md-8 col-xs-24 xs-mrg-top-20">
              <ul className="social-links">
                <li
                  className={`${
                    enterpriseProfile &&
                    enterpriseProfile.social &&
                    enterpriseProfile.social.fb
                      ? ''
                      : 'hide'
                  }`}
                >
                  <a
                    href={
                      enterpriseProfile &&
                      enterpriseProfile.social &&
                      enterpriseProfile.social.fb
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={e =>
                      this.gaEvents(
                        e,
                        'Social',
                        'facebook',
                        enterpriseProfile &&
                          enterpriseProfile.social &&
                          enterpriseProfile.social.fb
                      )
                    }
                  >
                    <span className="icon-fb" />
                  </a>
                </li>
                <li
                  className={`${
                    enterpriseProfile &&
                    enterpriseProfile.social &&
                    enterpriseProfile.social.tw
                      ? ''
                      : 'hide'
                  }`}
                >
                  <a
                    href={
                      enterpriseProfile &&
                      enterpriseProfile.social &&
                      enterpriseProfile.social.tw
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={e =>
                      this.gaEvents(
                        e,
                        'Social',
                        'twitter',
                        enterpriseProfile &&
                          enterpriseProfile.social &&
                          enterpriseProfile.social.tw
                      )
                    }
                  >
                    <span className="icon-twitter" />
                  </a>
                </li>
                <li
                  className={`${
                    enterpriseProfile &&
                    enterpriseProfile.social &&
                    enterpriseProfile.social.ig
                      ? ''
                      : 'hide'
                  }`}
                >
                  <a
                    href={
                      enterpriseProfile &&
                      enterpriseProfile.social &&
                      enterpriseProfile.social.ig
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={e =>
                      this.gaEvents(
                        e,
                        'Social',
                        'instagram',
                        enterpriseProfile &&
                          enterpriseProfile.social &&
                          enterpriseProfile.social.ig
                      )
                    }
                  >
                    <span className="icon-instagram" />
                  </a>
                </li>
                <li
                  className={`${
                    enterpriseProfile &&
                    enterpriseProfile.social &&
                    enterpriseProfile.social.yt
                      ? ''
                      : 'hide'
                  }`}
                >
                  <a
                    href={
                      enterpriseProfile &&
                      enterpriseProfile.social &&
                      enterpriseProfile.social.yt
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={e =>
                      this.gaEvents(
                        e,
                        'Social',
                        'youtube',
                        enterpriseProfile &&
                          enterpriseProfile.social &&
                          enterpriseProfile.social.yt
                      )
                    }
                  >
                    <span className="icon-youtube1" />
                  </a>
                </li>
              </ul>
              <span className="copyright text-gray pull-right">
                © {copyrightYear} {strings[lang].allRightsReserved}
              </span>
            </div>
          </Fragment>
        </div>
      </footer>
    ) : null;
  }
}

Kushals.contextTypes = {
  router: PropTypes.object.isRequired
};

Kushals.propTypes = {
  tracking: PropTypes.object,
  user: PropTypes.object,
  orderInfo: PropTypes.object,
  page: PropTypes.object
};

const mapStateToProps = state => ({
  tracking: state.tracking,
  user: state.user,
  orderInfo: state.orderInfo,
  page: state.page
});

export default connect(mapStateToProps, null)(Kushals);
