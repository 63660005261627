export const title = '';

export const meta = [
  {
    charset: 'UTF-8'
  },
  {
    name: 'viewport',
    content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0'
  },
  {
    httpEquiv: 'X-UA-Compatible',
    content: 'IE=edge'
  },
  {
    name: 'description',
    content: ''
  }
];

export const link = [];

export const script = [];

export const noscript = [];
