import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Listing from './Listing';
import ProductDetails from './ProductDetails';
import { PageSpinner } from '../../../staticComponents/spinner';
import { useExchangeContext } from '../wrapper';
import { stepActions, steps } from '../defaultValues';
import { connect } from 'react-redux';

export const catalogueSteps = {
  CATEGORIES: 'CATEGORIES',
  PRODUCTS: 'PRODUCTS',
  DETAILS: 'DETAILS'
};

const Catalogue = ({ orderInfo }) => {
  const { goBack, step: exchangeStep } = useExchangeContext();
  const [step, setStep] = useState(catalogueSteps.CATEGORIES);
  const [state, setState] = useState({ categoryId: null, productId: null });

  const onCategorySelect = id => {
    state.categoryId = id;
    setStep(catalogueSteps.PRODUCTS);
    setState({ ...state });
  };
  const onProductSelect = (id, label, price) => {
    state.productId = id;
    state.productTitle = label;
    state.productPrice = price;
    setStep(catalogueSteps.DETAILS);
    setState({ ...state });
  };

  const onGoBack = () => {
    switch (step) {
      case catalogueSteps.CATEGORIES:
        return goBack(steps.REASON);
      case catalogueSteps.PRODUCTS:
        return setStep(catalogueSteps.CATEGORIES);
      case catalogueSteps.DETAILS:
        return setStep(catalogueSteps.PRODUCTS);
    }
  };

  useEffect(() => {
    if (exchangeStep.action === stepActions.REVERSE) {
      setStep(orderInfo.exchange.catalogueState.step);
      setState(orderInfo.exchange.catalogueState.state);
    }
  }, []);

  switch (step) {
    case catalogueSteps.CATEGORIES:
      return (
        <Listing
          key={catalogueSteps.CATEGORIES}
          listingType={catalogueSteps.CATEGORIES}
          onClick={onCategorySelect}
          onGoBack={onGoBack}
          onCategorySelect={onCategorySelect}
        />
      );
    case catalogueSteps.PRODUCTS:
      return (
        <Listing
          key={catalogueSteps.PRODUCTS}
          categoryId={state.categoryId}
          listingType={catalogueSteps.PRODUCTS}
          onClick={onProductSelect}
          onGoBack={onGoBack}
          onCategorySelect={onCategorySelect}
        />
      );
    case catalogueSteps.DETAILS:
      return (
        <ProductDetails onGoBack={onGoBack} catalogueState={{ state, step }} />
      );
    default:
      <PageSpinner />;
  }
};

Catalogue.propTypes = {
  orderInfo: PropTypes.any
};

const mapStateToProps = state => ({
  orderInfo: state.orderInfo
});

export default connect(mapStateToProps)(Catalogue);
