import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { strings, copyrightYear } from '../../utils/localizedStrings';
import { ga4ClickEvent } from '../../utils/helper';

const Thenestery = props => {
  const gaEvents = (e, eventName, label, action) => {
    ga4ClickEvent({ category: eventName, action, label, props });
    ReactGA.event({
      category: eventName,
      action: action,
      label: label
    });
  };
  const { user, orderInfo, tracking } = props;
  const enterpriseProfile = tracking.theme.meta_data;
  const { lang } = user;
  return orderInfo &&
    orderInfo.step &&
    orderInfo.step.current &&
    orderInfo.step.current < 2 ? (
    <footer
      className={`container-fluid footer-wrapper sticky-footer ${
        user.hideLink
          ? ''
          : user.appLink &&
            user.subdomain === 'hopscotch' &&
            this.context.router.history.location.search &&
            this.context.router.history.location.pathname === '/'
          ? 'xs-pd-btm-100'
          : ''
      }`}
    >
      <div className="row col-gutter-60 ">
        {/* <div className="row col-gutter-60"> */}
        <Fragment>
          {enterpriseProfile && enterpriseProfile.email && (
            <div className="col-md-8 col-xs-24 pos-rel">
              <p>
                <span className="info user-select pd-btm-10">
                  <span className="pd-right-10 color-blue icon-envelope"></span>
                  <a
                    href={`mailto:${enterpriseProfile &&
                      enterpriseProfile.email}`}
                    onClick={e =>
                      gaEvents(
                        e,
                        'Email ID',
                        'help',
                        enterpriseProfile && enterpriseProfile.email
                      )
                    }
                  >
                    {enterpriseProfile && enterpriseProfile.email}
                  </a>
                </span>
              </p>
              <p>
                <span className="info user-select">
                  <span className="pd-right-10 icon-whatsapp color-green" />
                  <a
                    href={`https://api.whatsapp.com/send/?phone=918951979362&text=Hi+Team+Nestery%2C+I+need+help+tracking+my+order&type=phone_number&app_absent=0`}
                    onClick={e =>
                      gaEvents(
                        e,
                        'Contact Number',
                        'help',
                        enterpriseProfile && enterpriseProfile.contact
                      )
                    }
                  >
                    {enterpriseProfile && enterpriseProfile.contact}
                  </a>
                </span>
              </p>
            </div>
          )}

          <div className="col-md-7 col-xs-24 xs-mrg-top-20 pos-rel">
            {!(
              enterpriseProfile &&
              enterpriseProfile.time &&
              enterpriseProfile.time.to
            ) &&
            !(
              enterpriseProfile &&
              enterpriseProfile.time &&
              enterpriseProfile.time.from
            ) ? null : (
              <p>
                <span className="icon icon-phone" />
                <span className="info">
                  Call us{' '}
                  {enterpriseProfile &&
                    enterpriseProfile.time &&
                    enterpriseProfile.time.from}{' '}
                  to{' '}
                  {enterpriseProfile &&
                    enterpriseProfile.time &&
                    enterpriseProfile.time.to}{' '}
                  ({' '}
                  {enterpriseProfile &&
                    enterpriseProfile.day &&
                    enterpriseProfile.day.from}{' '}
                  to{' '}
                  {enterpriseProfile &&
                    enterpriseProfile.day &&
                    enterpriseProfile.day.to}
                  )
                </span>
              </p>
            )}
          </div>
          <div className="col-md-8 col-xs-24 xs-mrg-top-20">
            <ul className="social-links">
              <li
                className={`${
                  enterpriseProfile &&
                  enterpriseProfile.social &&
                  enterpriseProfile.social.fb
                    ? ''
                    : 'hide'
                }`}
              >
                <a
                  href={
                    enterpriseProfile &&
                    enterpriseProfile.social &&
                    enterpriseProfile.social.fb
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={e =>
                    gaEvents(
                      e,
                      'Social',
                      'facebook',
                      enterpriseProfile &&
                        enterpriseProfile.social &&
                        enterpriseProfile.social.fb
                    )
                  }
                >
                  <span className="icon-fb" />
                </a>
              </li>
              <li
                className={`${
                  enterpriseProfile &&
                  enterpriseProfile.social &&
                  enterpriseProfile.social.tw
                    ? ''
                    : 'hide'
                }`}
              >
                <a
                  href={
                    enterpriseProfile &&
                    enterpriseProfile.social &&
                    enterpriseProfile.social.tw
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={e =>
                    gaEvents(
                      e,
                      'Social',
                      'twitter',
                      enterpriseProfile &&
                        enterpriseProfile.social &&
                        enterpriseProfile.social.tw
                    )
                  }
                >
                  <span className="icon-twitter" />
                </a>
              </li>
              <li
                className={`${
                  enterpriseProfile &&
                  enterpriseProfile.social &&
                  enterpriseProfile.social.ig
                    ? ''
                    : 'hide'
                }`}
              >
                <a
                  href={
                    enterpriseProfile &&
                    enterpriseProfile.social &&
                    enterpriseProfile.social.ig
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={e =>
                    gaEvents(
                      e,
                      'Social',
                      'instagram',
                      enterpriseProfile &&
                        enterpriseProfile.social &&
                        enterpriseProfile.social.ig
                    )
                  }
                >
                  <span className="icon-instagram" />
                </a>
              </li>
              <li
                className={`${
                  enterpriseProfile &&
                  enterpriseProfile.social &&
                  enterpriseProfile.social.yt
                    ? ''
                    : 'hide'
                }`}
              >
                <a
                  href={
                    enterpriseProfile &&
                    enterpriseProfile.social &&
                    enterpriseProfile.social.yt
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={e =>
                    gaEvents(
                      e,
                      'Social',
                      'youtube',
                      enterpriseProfile &&
                        enterpriseProfile.social &&
                        enterpriseProfile.social.yt
                    )
                  }
                >
                  <span className="icon-youtube1" />
                </a>
              </li>
            </ul>
            <span className="copyright text-gray pull-right">
              © {copyrightYear} {strings[lang].allRightsReserved}
            </span>
          </div>
        </Fragment>
      </div>
    </footer>
  ) : null;
};

Thenestery.contextTypes = {
  router: PropTypes.object.isRequired
};

Thenestery.propTypes = {
  tracking: PropTypes.object,
  user: PropTypes.object,
  orderInfo: PropTypes.object,
  page: PropTypes.object
};

const mapStateToProps = state => ({
  tracking: state.tracking,
  user: state.user,
  orderInfo: state.orderInfo,
  page: state.page
});

export default connect(mapStateToProps, null)(Thenestery);
