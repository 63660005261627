/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { ga4ClickEvent } from '../../utils/helper';

class Candere extends Component {
  state = { currentTab: -1 };
  gaEvents = (e, eventName, label, action) => {
    ga4ClickEvent({ category: eventName, action, label, props: this.props });
    ReactGA.event({
      category: eventName,
      action: action,
      label: label
    });
  };

  toogleFooterNav = tab => {
    let { currentTab } = this.state;
    if (currentTab === tab) {
      currentTab = -1;
    } else {
      currentTab = tab;
    }
    this.setState({ currentTab });
  };
  render() {
    const { user, orderInfo, tracking } = this.props;
    const enterpriseProfile = tracking.theme.meta_data;
    return orderInfo &&
      orderInfo.step &&
      orderInfo.step.current &&
      orderInfo.step.current < 2 ? (
      <footer
        className={`container-fluid footer-wrapper sticky-footer candere ${
          user.hideLink
            ? ''
            : user.appLink &&
              user.subdomain === 'hopscotch' &&
              this.context.router.history.location.search &&
              this.context.router.history.location.pathname === '/'
            ? 'xs-pd-btm-100'
            : ''
        }`}
      >
        <div className="row copyright-footer">
          <div className="col-md-4 col-xs-24 col-md-offset-1">
            <div className="ssl"></div>
          </div>
          <div className="col-md-10 col-xs-24 col-md-offset-1">
            <p className="copy_margin">
              © 2022 Candere.com . All Rights Reserved.
              <a className="sitemap" href="https://www.candere.com/sitemap">
                Site Map
              </a>
            </p>
          </div>
          <div className="col-md-8 col-xs-24">
            <div className="row">
              <div className="col-md-8 col-xs-24">
                <p className="text-right copy_margin">WE ACCEPT</p>
              </div>
              <div className="col-md-16 col-xs-24 mrg-top-15 pd-left-0">
                <img
                  className="img_margin"
                  src="https://pyck-res-bucket.s3-ap-southeast-1.amazonaws.com/clickpost_assets/assets/candere/payment_method.png"
                  alt="Candere Payment Methods"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-copyright-container visible-xs">
          <div className="col-md-8 col-xs-24 xs-mrg-top-20">
            <p className="follow-us">FOLLOW US:</p>
            <ul className="social-links">
              <li>
                <a
                  href={
                    enterpriseProfile &&
                    enterpriseProfile.social &&
                    enterpriseProfile.social.fb
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={e =>
                    this.gaEvents(
                      e,
                      'Social',
                      'facebook',
                      enterpriseProfile &&
                        enterpriseProfile.social &&
                        enterpriseProfile.social.fb
                    )
                  }
                >
                  <span className="icon-fb" />
                </a>
              </li>
              <li>
                <a
                  href={
                    enterpriseProfile &&
                    enterpriseProfile.social &&
                    enterpriseProfile.social.tw
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={e =>
                    this.gaEvents(
                      e,
                      'Social',
                      'twitter',
                      enterpriseProfile &&
                        enterpriseProfile.social &&
                        enterpriseProfile.social.tw
                    )
                  }
                >
                  <span className="icon-twitter" />
                </a>
              </li>
              <li
                className={`${
                  enterpriseProfile &&
                  enterpriseProfile.social &&
                  enterpriseProfile.social.ig
                    ? ''
                    : 'hide'
                }`}
              >
                <a
                  href={
                    enterpriseProfile &&
                    enterpriseProfile.social &&
                    enterpriseProfile.social.ig
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={e =>
                    this.gaEvents(
                      e,
                      'Social',
                      'instagram',
                      enterpriseProfile &&
                        enterpriseProfile.social &&
                        enterpriseProfile.social.ig
                    )
                  }
                >
                  <span className="icon-instagram" />
                </a>
              </li>
              <li
                className={`${
                  enterpriseProfile &&
                  enterpriseProfile.social &&
                  enterpriseProfile.social.yt
                    ? ''
                    : 'hide'
                }`}
              >
                <a
                  href={
                    enterpriseProfile &&
                    enterpriseProfile.social &&
                    enterpriseProfile.social.yt
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={e =>
                    this.gaEvents(
                      e,
                      'Social',
                      'youtube',
                      enterpriseProfile &&
                        enterpriseProfile.social &&
                        enterpriseProfile.social.yt
                    )
                  }
                >
                  <span className="icon-youtube1" />
                </a>
              </li>
            </ul>
            <p className="copyright-txt">
              © 2022 candere.com. All Right Reserved.
            </p>
          </div>
        </div>
      </footer>
    ) : null;
  }
}

Candere.contextTypes = {
  router: PropTypes.object.isRequired
};

Candere.propTypes = {
  tracking: PropTypes.object,
  user: PropTypes.object,
  orderInfo: PropTypes.object,
  page: PropTypes.object
};

const mapStateToProps = state => ({
  tracking: state.tracking,
  user: state.user,
  orderInfo: state.orderInfo,
  page: state.page
});

export default connect(mapStateToProps, null)(Candere);
