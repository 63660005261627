import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const Walmart = props => {
  const { orderInfo } = props;

  return orderInfo &&
    orderInfo.step &&
    orderInfo.step.current &&
    orderInfo.step.current < 2 ? (
    <footer className={`container-fluid footer-wrapper sticky-footer`}>
      <div className="row col-gutter-60">
        <Fragment>
          <div className="col-xs-24">
            <span className="copyright text-white">
              © 2023 Walmart. All Rights Reserved.
            </span>
          </div>
        </Fragment>
      </div>
    </footer>
  ) : null;
};

Walmart.contextTypes = {
  router: PropTypes.object.isRequired
};

Walmart.propTypes = {
  tracking: PropTypes.object,
  user: PropTypes.object,
  orderInfo: PropTypes.object,
  page: PropTypes.object
};

const mapStateToProps = state => ({
  tracking: state.tracking,
  user: state.user,
  orderInfo: state.orderInfo,
  page: state.page
});

export default connect(mapStateToProps, null)(Walmart);
