/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateUserHandler } from '../../service';

class HealthKart extends Component {
  render() {
    const { tracking } = this.props;
    const enterpriseProfile = tracking.theme.meta_data;
    return (
      <header className="healthkart">
        <div className="container-fluid topbar visible-xs">
          <div className="row col-gutter-130">
            <div className="col-md-24 col-xs-24 text-left xs-text-center">
              <a
                className="site-link"
                href={enterpriseProfile.website}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="logo"
                  src={'/images/healthkart_m_logo.svg'}
                  width="130px"
                  alt="logo"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="container-fluid topbar hidden-xs">
          <div className="row col-gutter-130">
            <div className="col-md-24 col-xs-24 text-left xs-text-center">
              <a
                href={enterpriseProfile.website}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="logo"
                  src={tracking.theme.logo || '/images/logo_placeholder.svg'}
                  alt="logo"
                />
              </a>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

HealthKart.propTypes = {
  tracking: PropTypes.object,
  updateUserHandler: PropTypes.func
};

const mapStateToProps = state => ({
  tracking: state.tracking
});

const mapDispatchToProps = dispatch => ({
  updateUserHandler: user => dispatch(updateUserHandler(user))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(HealthKart);
