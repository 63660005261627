import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { ga4ClickEvent } from '../../utils/helper';

const EightTwoE = props => {
  const gaEvents = (e, eventName, label, action) => {
    ga4ClickEvent({ category: eventName, action, label, props });
    ReactGA.event({
      category: eventName,
      action: action,
      label: label
    });
  };

  const { orderInfo, tracking } = props;
  const enterpriseProfile = tracking.theme.meta_data;

  return orderInfo &&
    orderInfo.step &&
    orderInfo.step.current &&
    orderInfo.step.current < 2 ? (
    <footer className={`container-fluid footer-wrapper sticky-footer`}>
      <div className="row col-gutter-60">
        {/* <div className="row col-gutter-60"> */}
        <Fragment>
          <div className="col-xs-24 pos-rel hidden-md hidden-lg mrg-btm-20">
            {enterpriseProfile && enterpriseProfile.email && (
              <div className="">
                {/* <span className="icon icon-email" /> */}
                <span className="info ">
                  For further assistance, you can write to us at {''}
                  <a
                    href={`mailto:${enterpriseProfile &&
                      enterpriseProfile.email}`}
                    onClick={e =>
                      gaEvents(
                        e,
                        'Email ID',
                        'help',
                        enterpriseProfile && enterpriseProfile.email
                      )
                    }
                  >
                    {enterpriseProfile && enterpriseProfile.email}
                  </a>
                </span>
              </div>
            )}
          </div>
          <div className="col-md-11 col-xs-24 lg-text-left md-text-center">
            <ul className="social-links">
              <li
                className={`${
                  enterpriseProfile &&
                  enterpriseProfile.social &&
                  enterpriseProfile.social.fb
                    ? ''
                    : 'hide'
                }`}
              >
                <a
                  href={
                    enterpriseProfile &&
                    enterpriseProfile.social &&
                    enterpriseProfile.social.fb
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={e =>
                    gaEvents(
                      e,
                      'Social',
                      'facebook',
                      enterpriseProfile &&
                        enterpriseProfile.social &&
                        enterpriseProfile.social.fb
                    )
                  }
                >
                  FACEBOOK
                </a>
              </li>
              <li
                className={`${
                  enterpriseProfile &&
                  enterpriseProfile.social &&
                  enterpriseProfile.social.tw
                    ? ''
                    : 'hide'
                }`}
              >
                <a
                  href={
                    enterpriseProfile &&
                    enterpriseProfile.social &&
                    enterpriseProfile.social.tw
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={e =>
                    gaEvents(
                      e,
                      'Social',
                      'twitter',
                      enterpriseProfile &&
                        enterpriseProfile.social &&
                        enterpriseProfile.social.tw
                    )
                  }
                >
                  TWITTER
                </a>
              </li>
              <li
                className={`${
                  enterpriseProfile &&
                  enterpriseProfile.social &&
                  enterpriseProfile.social.ig
                    ? ''
                    : 'hide'
                }`}
              >
                <a
                  href={
                    enterpriseProfile &&
                    enterpriseProfile.social &&
                    enterpriseProfile.social.ig
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={e =>
                    gaEvents(
                      e,
                      'Social',
                      'instagram',
                      enterpriseProfile &&
                        enterpriseProfile.social &&
                        enterpriseProfile.social.ig
                    )
                  }
                >
                  INSTAGRAM
                </a>
              </li>
              <li
                className={`${
                  enterpriseProfile &&
                  enterpriseProfile.social &&
                  enterpriseProfile.social.yt
                    ? ''
                    : 'hide'
                }`}
              >
                <a
                  href={
                    enterpriseProfile &&
                    enterpriseProfile.social &&
                    enterpriseProfile.social.yt
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={e =>
                    gaEvents(
                      e,
                      'Social',
                      'youtube',
                      enterpriseProfile &&
                        enterpriseProfile.social &&
                        enterpriseProfile.social.yt
                    )
                  }
                >
                  YOUTUBE
                </a>
              </li>
            </ul>
            <div className="mrg-top-20 hidden-sm hidden-xs">
              <span className="copyright text-gray">
                © 2023, DPKA UNIVERSAL CONSUMER VENTURES PVT. LTD. ALL RIGHTS
                RESERVED
              </span>
            </div>
          </div>
          {/* <div className="col-md-5 col-xs-24 xs-mrg-top-20 pos-rel">
            {!(
              enterpriseProfile &&
              enterpriseProfile.time &&
              enterpriseProfile.time.to
            ) &&
            !(
              enterpriseProfile &&
              enterpriseProfile.time &&
              enterpriseProfile.time.from
            ) ? null : (
              <p>
                <span className="icon icon-phone" />
                <span className="info">
                  Call us{' '}
                  {enterpriseProfile &&
                    enterpriseProfile.time &&
                    enterpriseProfile.time.from}{' '}
                  to{' '}
                  {enterpriseProfile &&
                    enterpriseProfile.time &&
                    enterpriseProfile.time.to}{' '}
                  (
                  {enterpriseProfile &&
                    enterpriseProfile.day &&
                    enterpriseProfile.day.from}{' '}
                  to{' '}
                  {enterpriseProfile &&
                    enterpriseProfile.day &&
                    enterpriseProfile.day.to}
                  )
                </span>
                {/* {user.subdomain === 'herbalife' ? (
                    <span className="info">& 10AM to 2PM (Sat)</span>
                  ) : null}
              </p>
            )}

            <p>
              <span className="info user-select">
                <a
                  href={`tel:${enterpriseProfile && enterpriseProfile.contact}`}
                  onClick={e =>
                    gaEvents(
                      e,
                      'Contact Number',
                      'help',
                      enterpriseProfile && enterpriseProfile.contact
                    )
                  }
                >
                  {enterpriseProfile && enterpriseProfile.contact}
                </a>
              </span>
            </p>
          </div> */}
          <div className="col-md-12 col-xs-24 pos-rel mrg-top-30 hidden-sm hidden-xs">
            <div className="mrg-top-10" />
            {enterpriseProfile && enterpriseProfile.email && (
              <div className="pull-right">
                {/* <span className="icon icon-email" /> */}
                <span className="info">
                  For further assistance, you can write to us at {''}
                  <a
                    href={`mailto:${enterpriseProfile &&
                      enterpriseProfile.email}`}
                    onClick={e =>
                      gaEvents(
                        e,
                        'Email ID',
                        'help',
                        enterpriseProfile && enterpriseProfile.email
                      )
                    }
                  >
                    {enterpriseProfile && enterpriseProfile.email}
                  </a>
                </span>
              </div>
            )}
          </div>
          <div className="col-md-3 col-xs-24 xs-mrg-top-20 hidden-md hidden-lg mrg-top-20">
            <span className="copyright text-gray">
              © 2022, DPKA UNIVERSAL CONSUMER VENTURES PVT. LTD. ALL RIGHTS
              RESERVED
            </span>
          </div>
        </Fragment>
      </div>
    </footer>
  ) : null;
};

EightTwoE.contextTypes = {
  router: PropTypes.object.isRequired
};

EightTwoE.propTypes = {
  tracking: PropTypes.object,
  user: PropTypes.object,
  orderInfo: PropTypes.object,
  page: PropTypes.object
};

const mapStateToProps = state => ({
  tracking: state.tracking,
  user: state.user,
  orderInfo: state.orderInfo,
  page: state.page
});

export default connect(mapStateToProps, null)(EightTwoE);
