import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export default function(ComposedComponent) {
  class Authenticate extends React.Component {
    static propTypes = {
      isAuthenticated: PropTypes.boolean,
      redirect: PropTypes.func.isRequired
    };

    componentDidMount() {
      this._checkAndRedirect();
    }

    componentDidUpdate() {
      this._checkAndRedirect();
    }

    _checkAndRedirect() {
      //   const { isAuthenticated, history } = this.props;
      //   if (!isAuthenticated) {
      //     // redirect();
      //     history.push('/returns');
      //   }
      const token = cookies.get('token');
      if (token) {
        // this.props.history.push('/returns');
      } else {
        this.props.history.push('/login');
      }
    }

    render() {
      const token = cookies.get('token');
      return <div>{token ? <ComposedComponent {...this.props} /> : null}</div>;
    }
  }

  const mapStateToProps = state => {
    return {
      isAuthenticated: state.auth.isAuthenticated
    };
  };

  //   const mapDispatchToProps = dispatch => bindActionCreators({
  //     redirect: () => push('/signin')
  //   }, dispatch)

  Authenticate.propTypes = {
    // isAuthenticated: PropTypes.boolean,
    // redirect: PropTypes.func.isRequired,
    history: PropTypes.object
  };

  return connect(
    mapStateToProps,
    null
  )(Authenticate);
}
