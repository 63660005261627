/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateUserHandler } from '../../service';

class Candere extends Component {
  toggleNav = () => {
    let { user } = this.props;
    user = Object.assign(user, { toggleNav: !user.toggleNav });
    this.props.updateUserHandler(user);
  };
  render() {
    const { tracking } = this.props;
    return (
      <header className="container-fluid header-wrapper candere">
        <div className="row col-gutter-60">
          <div className="col-md-20 col-xs-24 text-left col-md-offset-2">
            <div className="logo-container">
            <a
              href={tracking.theme.meta_data.website || '#'}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="logo"
                src={tracking.theme.logo || '/images/logo_placeholder.svg'}
                alt="logo"
              />
            </a>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

Candere.propTypes = {
  tracking: PropTypes.object,
  user: PropTypes.object,
  updateUserHandler: PropTypes.func
};

const mapStateToProps = state => ({
  tracking: state.tracking,
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  updateUserHandler: user => dispatch(updateUserHandler(user))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(Candere);
