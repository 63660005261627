import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Instructions from '../components/Instructions';
import { connect } from 'react-redux';
import {
  addProduct,
  fetchCatalogueProduct,
  updateExchangeInfo
} from '../../../service';
import { PageSpinner } from '../../../staticComponents/spinner';
import Carousel from '../components/Carousel';
import { useForm } from '../utils/useForm';
import Select from 'react-select';
import { variantListSetter } from '../utils/helper';
import { useExchangeContext } from '../wrapper';
import { stepActions, steps } from '../defaultValues';
import { currencyMapper } from '../../../utils/entity_mapping';
import Layout from '../components/Layout';

const ProductDetails = ({
  tracking,
  user,
  variants,
  onGoBack,
  orderInfo,
  fetchProduct,
  addProduct,
  updateExchangeInfo,
  exchangeConfig,
  catalogueState
}) => {
  const { productDetails } = variants;
  const {
    catalogueConfig: { percentageDiscount },
    forward_order_discount
  } = exchangeConfig;
  const isMultiExchangeEnabled =
    tracking?.theme?.meta_data?.exchange?.allowMultiExchange;
  const { state, goForward, step } = useExchangeContext();
  const { config } = tracking.theme;
  const selectedProduct = orderInfo.request[state.editProductId] ?? {};
  const [variantList, setVariantList] = useState([]);
  let calculatedPriceDiff = selectedProduct.priceDiff ?? 0;

  const { formData, onChange, isDirty } = useForm({
    init: { variant: '' }
  });

  const selectedVariant =
    variantList.find(v => v.value === formData.variant) ?? {};

  if (
    percentageDiscount &&
    selectedProduct.price_breakup?.discount_percent > 0
  ) {
    //code for  discount percentage added
    if (selectedVariant?.item) {
      selectedVariant.item.discount_price =
        selectedVariant.item.price -
        (selectedVariant.item.price *
          selectedProduct.price_breakup?.discount_percent) /
          100;
    }
  }

  const priceDiff =
    selectedVariant.item?.price -
    (selectedProduct.price - (selectedProduct.price_breakup?.discount ?? 0));

  if (forward_order_discount) {
    calculatedPriceDiff = selectedVariant.item?.price - selectedProduct.price;
  } else if (percentageDiscount) {
    calculatedPriceDiff =
      selectedVariant.item?.discount_price - selectedProduct.discount_price;
  } else {
    calculatedPriceDiff =
      selectedVariant.item?.price -
      (selectedProduct.price - (selectedProduct.price_breakup?.discount ?? 0));
  }

  const priceDiffSign = Math.sign(calculatedPriceDiff);

  const diffText = {
    1: `This item is ${orderInfo.currency ??
      currencyMapper(config.country_code)} ${Math.abs(
      calculatedPriceDiff * selectedProduct.quantity
    ).toFixed(2)} more from original item`,
    0: `This item is same price as original item`,
    '-1': `This item is ${orderInfo.currency ??
      currencyMapper(config.country_code)} ${Math.abs(
      calculatedPriceDiff * selectedProduct.quantity
    ).toFixed(2)} less from original item`
  };

  const onSubmit = () => {
    const { request } = orderInfo;
    const { editProductId } = state;
    const { variant } = formData;

    addProduct({
      ...orderInfo.request,
      [editProductId]: {
        ...request[editProductId],
        variant_id: variant,
        selected_variant: selectedVariant.item.title,
        exchangeSku: selectedVariant.item.sku,
        previous_variant: request[editProductId]?.description ?? '',
        exchangePrice: selectedVariant.item.price,
        variantImage: productDetails?.data?.product?.images?.nodes?.map(
          item => item.url
        ),
        exchangeTitle: catalogueState.state?.productTitle ?? '',
        priceDiff,
        ...(percentageDiscount
          ? {
              priceDiffWithDiscount:
                (selectedVariant.item.discount_price ??
                  selectedVariant.item.price) - selectedProduct.discount_price
            }
          : {}),
        priceDiffWithoutDiscount:
          selectedVariant.item.price - selectedProduct.price
      }
    });

    updateExchangeInfo({
      // new keys added to calculate if need to make payment or do refund
      catalogueState
    });

    if (isMultiExchangeEnabled) {
      return goForward(steps.ITEM_SELECTION);
    }
    return goForward(steps.ADDRESS);
  };

  useEffect(() => {
    fetchProduct({ shopify_product_id: catalogueState.state.productId }).then(
      res => {
        const variantListTemp = variantListSetter(
          res.result?.data?.variants?.nodes ?? [],
          orderInfo.currency ?? currencyMapper(config.country_code),
          user.subdomain
        );
        //sorting variants in ascending order because we're showing min price of variant on category selection
        const sortedVariant = variantListTemp?.sort(
          (a, b) => parseFloat(a.item.price) - parseFloat(b.item.price)
        );
        setVariantList(sortedVariant);
        if (step.action === stepActions.FORWARD) {
          const variant = variantListTemp.find(v => !v.isDisabled);
          if (variant) {
            onChange('variant', variant.value);
          }
        }
        if (step.action === stepActions.REVERSE) {
          onChange('variant', selectedProduct.variant_id);
        }

        var detailsContainer = document.getElementById('details-container');
        var variantSelectLabel = document.getElementById(
          'variant-select-label'
        );
        detailsContainer.scrollTo({
          behavior: 'smooth',
          top: variantSelectLabel.offsetTop
        });
      }
    );
  }, []);
  return (
    <Layout
      heading={`Select Exchange Item Color${
        user.subdomain === 'sprig-store' ? '/Type' : ' & Size'
      }`}
      goback={onGoBack}
      buttons={[
        {
          label: 'Next',
          disabled: !isDirty,
          type: 'button',
          onClick: onSubmit
        }
      ]}
    >
      <div className="ht-100 xs-pd-10">
        {variants.isFetching || !productDetails ? (
          <PageSpinner />
        ) : (
          <>
            <div id="details-container" className="ht-100 overflow-auto">
              <Carousel
                imgList={productDetails?.data?.product?.images?.nodes?.map(
                  item => item.url
                )}
              />
              <div className="mrg-top-20 mrg-btm-20">
                <p className="mrg-btm-5">
                  {catalogueState.state?.productTitle ?? ''}
                </p>
                <div>
                  <span className="fw-600">
                    {orderInfo.currency ?? currencyMapper(config.country_code)}{' '}
                    {selectedVariant.item?.price ??
                      catalogueState.state?.productPrice ??
                      '0.00'}
                  </span>
                  <span className="mrg-right-10 mrg-left-10 text-gray">|</span>
                  <span>Qty: {selectedProduct.quantity}</span>
                </div>
              </div>
              <h4 id="variant-select-label" className="mrg-btm-5">
                Select Variant:
              </h4>
              <Select
                name={'variant'}
                value={variantList.find(v => v.value === formData.variant)}
                onChange={val => onChange('variant', val.value)}
                options={variantList}
                components={{
                  SingleValue: props =>
                    // eslint-disable-next-line react/prop-types
                    `${props.data.item.title} - ${orderInfo.currency ??
                      currencyMapper(config.country_code)} ${
                      // eslint-disable-next-line react/prop-types
                      props.data.item.price
                    }`
                }}
                className="custom-select indicator-theme text-primary-color"
                classNamePrefix="custom-select"
                isSearchable={false}
              />
              {formData.variant ? (
                <Instructions
                  className="mrg-btm-10 mrg-top-20"
                  description={diffText[priceDiffSign]}
                />
              ) : null}

              {/* size input start */}
              {/* <div>
              <h4 className="mrg-btm-10">Size : L</h4>
              <div className="d-flex">
                {['XS', 'S', 'M', 'L', 'XL'].map(size => (
                  <label
                    key={size}
                    htmlFor={`size-${size}`}
                    className="size-radio-input space-x-3"
                  >
                    <input
                      disabled={size === 'XS'} // for representation of disabled state
                      required
                      id={`size-${size}`}
                      name="size"
                      type="radio"
                      checked={size === 'L'}
                      value={size}
                      // onChange={onChangeHandler('size', size)}
                      // checked={size === state.size}
                    />
                    <div className="bd-1 cursor-pointer text-primary-color">
                      {size}
                    </div>
                  </label>
                ))}
              </div>
            </div> */}
              {/* size input ends */}
              {/* color input start */}
              {/* <div>
              <h4 className="mrg-btm-10">Color : Maroon</h4>
              <div className="d-flex">
                {['#CD5252', '#CBCE26', '#22A1A9', '#2B138A', '#000000'].map(
                  color => (
                    <label key={color} className="color-radio-input space-x-3">
                      <input
                        required
                        name="color"
                        type="radio"
                        value={color}
                        checked={color === '#000000'}
                        // onChange={onChangeHandler('color', color)}
                        // checked={color === state.color}
                      />
                      <div
                        style={{
                          background: color,
                          outlineColor: `${color}22`
                        }}
                        className="cursor-pointer"
                      />
                    </label>
                  )
                )}
              </div>
            </div> */}
              {/* color input ends */}

              {/* <div>
                <h4>Details</h4>
                <p>
                  The fabric of t-shirt is pure cotton, neck type is round,
                  sleeve type is half and fabric care can be done by gentle
                  machine wash.
                </p>
              </div> */}
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

ProductDetails.propTypes = {
  onGoBack: PropTypes.any,
  onNext: PropTypes.any,
  fetchProduct: PropTypes.any,
  variants: PropTypes.any,
  orderInfo: PropTypes.any,
  tracking: PropTypes.any,
  user: PropTypes.any,
  addProduct: PropTypes.any,
  updateExchangeInfo: PropTypes.any,
  catalogueState: PropTypes.any,
  exchangeConfig: PropTypes.any
};

const mapStateToProps = state => ({
  variants: state.variants,
  orderInfo: state.orderInfo,
  user: state.user,
  tracking: state.tracking,
  exchangeConfig: state.exchangeConfig
});

const mapDispatchToProps = dispatch => ({
  fetchProduct: query => dispatch(fetchCatalogueProduct(query)),
  addProduct: query => dispatch(addProduct(query)),
  updateExchangeInfo: query => dispatch(updateExchangeInfo(query))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);
