import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class Ritukumar extends Component {
  render() {
    // const { tracking } = this.props;
    return (
      <header className="container-fluid ritukumar header-wrapper">
        <div className="row">

        <div className="col-md-4 col-xs-7 text-center xs-display-none col-md-offset-2">
            <a
              href="https://ri.ritukumar.com/in/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="common logo"
                src={
                  'https://pyck-res-bucket.s3-ap-southeast-1.amazonaws.com/clickpost_assets/logo/ritukumar/logo2.png' ||
                  '/images/logo_placeholder.svg'
                }
                alt="logo"
              />
            </a>
          </div>

<div className="col-md-4 col-xs-7 text-center xs-display-none">
    <a
      href="https://labelritukumar.com/in/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        className="common logo"
        src={
          'https://pyck-res-bucket.s3-ap-southeast-1.amazonaws.com/clickpost_assets/logo/ritukumar/logo3.png' ||
          '/images/logo_placeholder.svg'
        }
        alt="logo"
      />
    </a>
  </div>

  <div className="col-md-4 col-xs-24 text-center content-box">
            <a
              href=" https://ritukumar.com/in/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="common logo"
                src={
                  'https://pyck-res-bucket.s3-ap-southeast-1.amazonaws.com/clickpost_assets/logo/ritukumar/logo5.png' ||
                  '/images/logo_placeholder.svg'
                }
                alt="logo"
              />
            </a>
          </div>
          <div className="col-md-4 col-xs-12 text-center xs-text-center xs-display-none">
            <a
              href="http://aarke.in/in"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="common logo"
                src={
                  'https://pyck-res-bucket.s3-ap-southeast-1.amazonaws.com/clickpost_assets/logo/ritukumar/logo1.png'
                }
                alt="logo"
              />
            </a>
          </div>

          <div className="col-md-4 col-xs-7 text-center xs-display-none">
            <a
              href="https://www.ritukumar.com/in/home_living"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="common logo"
                src={
                  'https://pyck-res-bucket.s3-ap-southeast-1.amazonaws.com/clickpost_assets/logo/ritukumar/logo4.png' ||
                  '/images/logo_placeholder.svg'
                }
                alt="logo"
              />
            </a>
          </div>

        </div>

      </header>
    );
  }
}

Ritukumar.propTypes = {
  tracking: PropTypes.object
};

const mapStateToProps = state => ({
  tracking: state.tracking
});

export default connect(
    mapStateToProps,
    null
  )(Ritukumar);
