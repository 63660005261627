/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';

const CardOverlay = props => {
  return (
    <div
      className={`blanket ${props.className} ${props.isOpen ? 'show' : 'hide'}`}
      onClick={props.closeModal}
    >
      <div
        className={`row modal ${props.modalClassName}`}
        onClick={props.childPropagation}
      >
        {props.closeButton && (
          <span
            className="icon-close cursor-pointer"
            onClick={props.closeModal}
          />
        )}
        {props.children}
      </div>
    </div>
  );
};

CardOverlay.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  children: PropTypes.array,
  closeModal: PropTypes.func,
  childPropagation: PropTypes.func,
  closeButton: PropTypes.bool,
  modalClassName: PropTypes.string
};

export default CardOverlay;
