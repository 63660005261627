/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { stylishopStrings } from '../../utils/localizedStrings';
import { ga4ClickEvent } from '../../utils/helper';

class Stylishop extends Component {
  gaEvents = (e, eventName, label, action) => {
    ga4ClickEvent({ category: eventName, action, label, props: this.props });
    ReactGA.event({
      category: eventName,
      action: action,
      label: label
    });
  };

  render() {
    const { user, orderInfo, tracking } = this.props;
    const { lang } = user;
    const enterpriseProfile = tracking.theme.meta_data;
    return orderInfo &&
      orderInfo.step &&
      orderInfo.step.current &&
      orderInfo.step.current < 2 ? (
      <footer
        className={`container-fluid footer-wrapper sticky-footer stylishop ${
          user.hideLink
            ? ''
            : user.appLink &&
              user.subdomain === 'hopscotch' &&
              this.context.router.history.location.search &&
              this.context.router.history.location.pathname === '/'
            ? 'xs-pd-btm-100'
            : ''
        }`}
      >
        <div className="row no-gutter">
          <div className="col-md-24 col-xs-24 text-left footer-link-container">
            <div className="row">
              <div className="col-sm-4 col-lg-3 footer-box">
                <p className="heading mrg-btm-5 xs-mrg-btm-10 xs-text-center">
                  {stylishopStrings[lang].women}
                </p>
                <ul className="footer-link showhide">
                  <li>
                    <a
                      href={`https://stylishop.com/${lang}/list/women/clothing`}
                    >
                      {stylishopStrings[lang].clothing}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`https://stylishop.com/${lang}/list/women/accessories`}
                    >
                      {stylishopStrings[lang].accessories}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`https://stylishop.com/${lang}/list/women/lingerie-nightwear`}
                    >
                      {stylishopStrings[lang].lingerie}
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-sm-4 col-lg-3 footer-box">
                <p className="heading mrg-btm-5 xs-mrg-btm-10 xs-text-center">
                  {stylishopStrings[lang].men}
                </p>
                <ul className="footer-link showhide">
                  <li>
                    <a href={`https://stylishop.com/${lang}/list/men/clothing`}>
                      {stylishopStrings[lang].clothing}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`https://stylishop.com/${lang}/list/men/accessories`}
                    >
                      {stylishopStrings[lang].clothing}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`https://stylishop.com/${lang}/list/men/underwear-nightwear`}
                    >
                      {stylishopStrings[lang].underwear}
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-sm-4 col-lg-5 footer-box">
                <p className="heading mrg-btm-5 xs-mrg-btm-10 xs-text-center">
                  {stylishopStrings[lang].help}
                </p>
                <ul className="footer-link showhide">
                  <li>
                    <a href={`https://stylishop.com/${lang}/contact`}>
                      {stylishopStrings[lang].contactUs}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`https://stylishop.com/${lang}/helpcentre#shipping`}
                    >
                      {stylishopStrings[lang].shipping}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`https://stylishop.com/${lang}/helpcentre#returns`}
                    >
                      {stylishopStrings[lang].returnsPolicy}
                    </a>
                  </li>
                  <li className="last">
                    <a href={`https://stylishop.com/${lang}/helpcentre`}>
                      {stylishopStrings[lang].faq}
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-sm-6 col-lg-9 hidden-xs">
                <div className="footer-box">
                  <p className="heading mrg-btm-10">
                    {stylishopStrings[lang].downloadApps}
                  </p>
                  <ul
                    id="footer-content-4"
                    className="footer-app-link showhide footer-link-images"
                  >
                    <li>
                      <a href="https://apps.apple.com/us/app/styli/id1469688029">
                        <img
                          src="https://stylishop.com/media/wysiwyg/apple.png"
                          alt="Appstore"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="https://play.google.com/store/apps/details?id=com.stylishop.styli">
                        <img
                          src="https://stylishop.com/media/wysiwyg/android.png"
                          alt="Google Play"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-sm-4 col-lg-4 mobile-hidden desktop-social-icons">
                <div className="footer-box pull-right xs-pull-unset">
                  <p className="heading mrg-btm-10">
                    {stylishopStrings[lang].keepInTouch}
                  </p>
                  <ul
                    id="footer-content-4"
                    className="footer-social-link showhide footer-link-social"
                  >
                    <li>
                      {enterpriseProfile.social &&
                      enterpriseProfile.social.fb ? (
                        <a
                          href={enterpriseProfile.social.fb}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={e =>
                            this.gaEvents(
                              e,
                              'Social',
                              'facebook',
                              enterpriseProfile.social.fb
                            )
                          }
                        >
                          <img
                            src="https://stylishop.com/media/wysiwyg/mobile-fb.png"
                            alt="facebook"
                          />
                        </a>
                      ) : null}
                    </li>
                    <li>
                      {enterpriseProfile.social &&
                      enterpriseProfile.social.tw ? (
                        <a
                          href={enterpriseProfile.social.tw}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={e =>
                            this.gaEvents(
                              e,
                              'Social',
                              'twitter',
                              enterpriseProfile.social.tw
                            )
                          }
                        >
                          <img
                            src="https://stylishop.com/media/wysiwyg/mobile-twitter.png"
                            alt="twitter"
                          />
                        </a>
                      ) : null}
                    </li>
                    <li>
                      {enterpriseProfile.social &&
                      enterpriseProfile.social.ig ? (
                        <a
                          href={enterpriseProfile.social.ig}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={e =>
                            this.gaEvents(
                              e,
                              'Social',
                              'instagram',
                              enterpriseProfile.social.ig
                            )
                          }
                        >
                          <img
                            src="https://stylishop.com/media/wysiwyg/instg.png"
                            alt="instagram"
                          />
                        </a>
                      ) : null}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-24 copyright-container">
            <div className="row">
              <div className="col-md-2 hidden-xs">
                <a href="#">
                  <img src={tracking.theme.logo} alt="styli" width="47" />
                </a>
              </div>
              <div className="col-md-10 col-xs-24 xs-mrg-top-10 text-left xs-text-center">
                {stylishopStrings[lang].copyright}
              </div>
              <div className="col-md-12 col-xs-24 text-right xs-text-center">
                <a href={`https://stylishop.com/sa/${lang}/legal/privacy`}>
                  {stylishopStrings[lang].privacyPolicy}
                </a>{' '}
                -{' '}
                <a
                  href={`https://stylishop.com/sa/${lang}/legal/user-agreement`}
                >
                  {stylishopStrings[lang].termOfUse}
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    ) : null;
  }
}

Stylishop.contextTypes = {
  router: PropTypes.object.isRequired
};

Stylishop.propTypes = {
  tracking: PropTypes.object,
  user: PropTypes.object,
  orderInfo: PropTypes.object,
  page: PropTypes.object
};

const mapStateToProps = state => ({
  tracking: state.tracking,
  user: state.user,
  orderInfo: state.orderInfo,
  page: state.page
});

export default connect(mapStateToProps, null)(Stylishop);
