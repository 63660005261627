import { useState } from 'react';

export const useForm = ({ init, validation }) => {
  const [isDirty, setIsDirty] = useState(false);
  const [errors, setErrors] = useState({});
  const [data, setData] = useState({ ...init });

  const onChange = (name, value) => {
    data[name] = value;
    setIsDirty(JSON.stringify(data) !== JSON.stringify(init));

    setData({ ...data });
  };
  const handleSubmit = onSubmit => e => {
    e?.preventDefault();
    const errorObj = validation ? validation(data) : {};
    setErrors(errorObj);
    if (Object.keys(errorObj).length > 0) return;
    onSubmit(data);
  };

  const reset = () => {
    setIsDirty(false);
    setData(init);
  };

  return { onChange, formData: data, isDirty, handleSubmit, errors, reset };
};
