import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { ga4ClickEvent } from '../../utils/helper';

class Sleepycat extends Component {
  constructor() {
    super();
    this.gaEvents = this.gaEvents.bind(this);
  }

  gaEvents(e, eventName, label, action) {
    ga4ClickEvent({ category: eventName, action, label, props: this.props });
    ReactGA.event({
      category: eventName,
      action: action,
      label: label
    });
  }

  render() {
    const { user, tracking } = this.props;
    const enterpriseProfile = tracking.theme.meta_data || {};
    return (
      <div className="sleepycat">
        <footer
          className={`container-fluid footer-wrapper ${
            user.hideLink
              ? ''
              : user.appLink &&
                user.subdomain === 'hopscotch' &&
                this.context.router.history.location.pathname === '/'
              ? 'xs-pd-btm-100'
              : ''
          }`}
        >
          <div className="row no-gutter">
            <div className="col-md-20 col-md-offset-2 text-center">
              <h2 className="footer-heading"> {`We're Here For You!`}</h2>
              <p className="footer-timings">
                {enterpriseProfile.day && enterpriseProfile.day.from} -{' '}
                {enterpriseProfile.day && enterpriseProfile.day.to} &nbsp; |
                &nbsp; {enterpriseProfile.time && enterpriseProfile.time.from} -{' '}
                {enterpriseProfile.time && enterpriseProfile.time.to}
              </p>

              <div className="d-flex">
                <div className="email">
                  <h5 className="title hidden-xs">Email</h5>
                  <p>
                    <span className="visible-xs-inline-block label mrg-right-5">
                      Email:
                    </span>
                    <a className="label" href={enterpriseProfile.email}>
                      {enterpriseProfile.email}
                    </a>
                  </p>
                </div>
                <div className="call">
                  <h5 className="title hidden-xs">Call</h5>
                  <p>
                    <span className="visible-xs-inline-block label mrg-right-5">
                      Call:
                    </span>
                    <a
                      className="label"
                      href={`tel:${enterpriseProfile.contact}`}
                    >
                      {enterpriseProfile.contact}
                    </a>
                  </p>
                </div>
                <div className="social">
                  <h5 className="title hidden-xs">Social</h5>
                  <div className="d-flex social_share_footer_2">
                    <ul className="social-links">
                      <li>
                        <a
                          href={enterpriseProfile.social.fb}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={e =>
                            this.gaEvents(
                              e,
                              'Social',
                              'facebook',
                              enterpriseProfile.social.fb
                            )
                          }
                        >
                          <span className="icon-fb" />
                        </a>
                      </li>
                      <li>
                        <a
                          href={enterpriseProfile.social.tw}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={e =>
                            this.gaEvents(
                              e,
                              'Social',
                              'twitter',
                              enterpriseProfile.social.tw
                            )
                          }
                        >
                          <span className="icon-twitter" />
                        </a>
                      </li>
                      <li
                        className={`${
                          enterpriseProfile.social.ig ? '' : 'hide'
                        }`}
                      >
                        <a
                          href={enterpriseProfile.social.ig}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={e =>
                            this.gaEvents(
                              e,
                              'Social',
                              'instagram',
                              enterpriseProfile.social.ig
                            )
                          }
                        >
                          <span className="icon-instagram" />
                        </a>
                      </li>
                      <li
                        className={`${
                          enterpriseProfile.social.yt ? '' : 'hide'
                        }`}
                      >
                        <a
                          href={enterpriseProfile.social.yt}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={e =>
                            this.gaEvents(
                              e,
                              'Social',
                              'youtube',
                              enterpriseProfile.social.yt
                            )
                          }
                        >
                          <span className="icon-youtube1" />
                        </a>
                      </li>
                    </ul>

                    <a
                      href="https://www.facebook.com/pg/sleepycat.in/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-facebook-square"></i>
                    </a>
                    <a
                      href="https://www.instagram.com/sleepycat.in/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UCAyZiLLNy-f6oGl4J6uQrWQ"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-youtube "></i>
                    </a>
                    <a
                      href="https://twitter.com/SleepyCat_India"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-twitter"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row no-gutter hide">
            <div className="col-md-24 col-xs-24">
              <ul className="social-links">
                <li>
                  <a
                    href={enterpriseProfile.social.fb}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={e =>
                      this.gaEvents(
                        e,
                        'Social',
                        'facebook',
                        enterpriseProfile.social.fb
                      )
                    }
                  >
                    <span className="icon-fb" />
                  </a>
                </li>
                <li>
                  <a
                    href={enterpriseProfile.social.tw}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={e =>
                      this.gaEvents(
                        e,
                        'Social',
                        'twitter',
                        enterpriseProfile.social.tw
                      )
                    }
                  >
                    <span className="icon-twitter" />
                  </a>
                </li>
                <li className={`${enterpriseProfile.social.ig ? '' : 'hide'}`}>
                  <a
                    href={enterpriseProfile.social.ig}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={e =>
                      this.gaEvents(
                        e,
                        'Social',
                        'instagram',
                        enterpriseProfile.social.ig
                      )
                    }
                  >
                    <span className="icon-instagram" />
                  </a>
                </li>
                <li className={`${enterpriseProfile.social.yt ? '' : 'hide'}`}>
                  <a
                    href={enterpriseProfile.social.yt}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={e =>
                      this.gaEvents(
                        e,
                        'Social',
                        'youtube',
                        enterpriseProfile.social.yt
                      )
                    }
                  >
                    <span className="icon-youtube1" />
                  </a>
                </li>
              </ul>
              <span className="fs-10 text-gray pull-right">
                © 2022 All rights reserved.
              </span>
            </div>
          </div>
        </footer>
        <div className="container-fluid footer-bottom">
          <div className="row no-gutter">
            <div className="col-md-20 col-md-offset-2">
              <div className="row">
                <div className="col-md-6 hidden-xs">
                  <div className="footer-logo footer-image">
                    <a href="https://www.sleepycat.in">
                      <img
                        src="https://pyck-res-bucket.s3.ap-southeast-1.amazonaws.com/clickpost_assets/logo/Sleepycat_image.png"
                        alt="sleepycat"
                        className="img-responsive"
                      />
                    </a>
                  </div>
                </div>
                <div className="col-md-9 col-xs-12">
                  <div className="row">
                    <div className="col-md-12 col-xs-24">
                      <ul className="mrg-btm-15">
                        <li className="bold">MATTRESSES</li>
                        <li>
                          <a href="https://www.sleepycat.in/product/sleepycat-mattress">
                            The Original
                          </a>
                        </li>
                        <li>
                          <a href="https://www.sleepycat.in/product/sleepycat-plus">
                            The Plus
                          </a>
                        </li>
                        <li>
                          <a href="https://www.sleepycat.in/product/sleepycat-latex-mattress">
                            The Latex
                          </a>
                        </li>
                        <li>
                          <a href="https://www.sleepycat.in/product/baby-mattress/">
                            The Baby Mattress
                          </a>
                        </li>
                      </ul>
                      <ul className="mrg-btm-15">
                        <li className="bold">ACCESSORIES</li>
                        <li>
                          <a href="https://www.sleepycat.in/product/mattress-protector/">
                            Mattress Protector
                          </a>
                        </li>
                        <li>
                          <a href="https://www.sleepycat.in/product/sleepycat-pillow/">
                            Pillow
                          </a>
                        </li>
                      </ul>
                      <ul className="mrg-btm-15">
                        <li className="bold">
                          <a href="https://www.sleepycat.in/product/sleepycat-smartbase/">
                            SMART BASE
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-12 col-xs-24 xs-mrg-top-40">
                      <ul>
                        <li className="bold">ABOUT US</li>
                        <li>
                          <a href="https://www.sleepycat.in/about-us">
                            Our Story
                          </a>
                        </li>
                        <li>
                          <a href="https://www.sleepycat.in/the-truth">
                            The Truth
                          </a>
                        </li>
                        <li>
                          <a href="https://www.sleepycat.in/giving-back">
                            Giving Back
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-9 col-xs-12">
                  <div className="row">
                    <div className="col-md-12 col-xs-24 xs-mrg-top-30">
                      <ul className="footer_links">
                        <li>
                          <a href="https://www.sleepycat.in/blog">Blog</a>
                        </li>
                        <li>
                          <a href="https://www.sleepycat.in/faq">FAQs</a>
                        </li>
                        <li>
                          <a href="https://www.sleepycat.in/fine-print">
                            30 Nights Free Trial
                          </a>
                        </li>
                        <li>
                          <a href="https://www.sleepycat.in/fine-print/#oneDay">
                            One Day Delivery
                          </a>
                        </li>
                        <li>
                          <a href="https://www.sleepycat.in/fine-print/#warranty">
                            10 Year Warranty
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-12 col-xs-24 xs-mrg-top-40">
                      <ul className="footer_links mob">
                        <li>
                          <a href="https://www.sleepycat.in/bangalore/">
                            Mattress in Bangalore
                          </a>
                        </li>
                        <li>
                          <a href="https://www.sleepycat.in/chennai/">
                            Mattress in Chennai
                          </a>
                        </li>
                        <li>
                          <a href="https://www.sleepycat.in/kolkata/">
                            Mattress in Kolkata
                          </a>
                        </li>
                        <li>
                          <a href="https://www.sleepycat.in/mumbai/">
                            Mattress in Mumbai
                          </a>
                        </li>
                        <li>
                          <a href="https://www.sleepycat.in/pune/">
                            Mattress in Pune
                          </a>
                        </li>
                        <li>
                          <a href="https://www.sleepycat.in/delhi/">
                            Mattress in Delhi
                          </a>
                        </li>
                        <li>
                          <a href="https://www.sleepycat.in/hyderabad/">
                            Mattress in Hyderabad
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright-container">
            <p className="mrg-top-30">
              <span className="mrg-right-5">
                2022 All Rights Reserved. Sleep Management Pvt Ltd.
              </span>{' '}
              <span className="hidden-xs">|</span>
              <span className="privacy-link">
                <a href="https://www.sleepycat.in/fine-print/#privacy">
                  Privacy Policy
                </a>{' '}
                |{' '}
                <a href="https://www.sleepycat.in/fine-print/">
                  Terms & Conditions
                </a>
              </span>
            </p>
            <p>
              H.O.: Kagalwala House, Kalina, Santacruz East, Mumbai, Maharashtra
              400098.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

Sleepycat.contextTypes = {
  router: PropTypes.object.isRequired
};

Sleepycat.propTypes = {
  tracking: PropTypes.object,
  user: PropTypes.object,
  page: PropTypes.object
};

const mapStateToProps = state => ({
  tracking: state.tracking,
  user: state.user,
  page: state.page
});

export default connect(mapStateToProps, null)(Sleepycat);
