import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class Onemg extends Component {
  render() {
    const { tracking } = this.props;
    return (
      <header className="container-fluid header-wrapper onemg">
        <div className="row">
          <div className="col-md-11 col-xs-24 text-left xs-text-center col-md-offset-1">
            <a
              href={tracking.theme.meta_data.website || '#'}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="background-logo" />
            </a>
          </div>
        </div>
      </header>
    );
  }
}

Onemg.propTypes = {
  tracking: PropTypes.object
};

const mapStateToProps = state => ({
  tracking: state.tracking
});

export default
  connect(
    mapStateToProps,
    null
  )(Onemg);
