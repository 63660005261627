import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class Usplworld extends Component {
  render() {
    const { tracking } = this.props;
    return (
      <header className="container-fluid usplworld header-wrapper">
        <div className="row">
          <div className="col-md-2 col-xs-6 text-left xs-text-center col-md-offset-1">
            <a
              href={tracking.theme.meta_data.website || '#'}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="common logo"
                src={
                  'https://s3-ap-southeast-1.amazonaws.com/pyck-res-bucket/clickpost_assets/logo/wrogn_logo.png' ||
                  '/images/logo_placeholder.svg'
                }
                alt="logo"
              />
            </a>
          </div>
        </div>
      </header>
    );
  }
}

Usplworld.propTypes = {
  tracking: PropTypes.object
};

const mapStateToProps = state => ({
  tracking: state.tracking
});

export default connect(mapStateToProps, null)(Usplworld);
