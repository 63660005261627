import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class Hopscotch extends Component {
  render() {
    const { user, tracking } = this.props;
    const enterpriseProfile = tracking.theme.meta_data;
    return (
      <div className={`hs ${user.webview ? 'hide' : 'show'}`}>
        <div className="topbar text-right visible-lg visible-md">
          <a
            href="https://www.hopscotch.in/helpcenter/#/"
            target="_blank"
            rel="noopener noreferrer"
          >
            24x7 Customer care
          </a>
        </div>
        <header className="container-fluid header-wrapper">
          <div className="row col-gutter-25">
            <div className="col-md-3 col-xs-12 text-left">
              <a
                className="logo"
                href={enterpriseProfile.website}
                target="_blank"
                rel="noopener noreferrer"
              >
                {/* <img className="logo" src={tracking.theme.logo || '/images/logo.svg'} alt="logo" /> */}
              </a>
            </div>
            <div className="col-md-12 text-left visible-lg visible-md">
              <ul className="nav">
                <li>
                  <a
                    href="https://www.hopscotch.in/moments/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Moments
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </header>
      </div>
    );
  }
}

Hopscotch.propTypes = {
  tracking: PropTypes.object,
  user: PropTypes.object
};

const mapStateToProps = state => ({
  tracking: state.tracking,
  user: state.user
});

export default connect(
    mapStateToProps,
    null
  )(Hopscotch);
