import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { strings, copyrightYear } from '../../utils/localizedStrings';
import { ga4ClickEvent } from '../../utils/helper';

const BaccabucciFooter = props => {
  const gaEvents = (e, eventName, label, action) => {
    ga4ClickEvent({ category: eventName, action, label, props });
    ReactGA.event({
      category: eventName,
      action: action,
      label: label
    });
  };
  const { user, orderInfo, tracking } = props;
  const enterpriseProfile = tracking.theme.meta_data;
  const { udfKeys } = tracking.theme;
  const { lang } = user;
  const notShowCPBranding = tracking.theme.meta_data?.udf?.filter(
    item => item.key === 'notShowCPBranding'
  )?.[0]?.value;

  /* 
    We will show icons in desktop view and not in mobile view
    */
  const isMobile =
    user?.device === 'mobile' ||
    user?.device === 'ios' ||
    user?.device === 'android';

  return orderInfo &&
    orderInfo.step &&
    orderInfo.step.current &&
    orderInfo.step.current < 2 ? (
    <footer
      className={`container-fluid footer-wrapper sticky-footer ${
        user.hideLink
          ? ''
          : user.appLink &&
            user.subdomain === 'hopscotch' &&
            this.context.router.history.location.search &&
            this.context.router.history.location.pathname === '/'
          ? 'xs-pd-btm-100'
          : ''
      }`}
    >
      <div className="row col-gutter-60">
        <Fragment>
          <div className="col-md-11 col-lg-12 col-xs-24 xs-mrg-top-15">
            <div className={`${!isMobile ? 'd-flex justify-between' : ''}`}>
              <p className="col-md-10 col-lg-8 col-xs-24 xs-mrg-top-15 text-left goback">
                <span className="info">
                  <a
                    href={
                      (tracking.theme.meta_data &&
                        tracking.theme.meta_data.website) ||
                      '#'
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Go Back to Website
                  </a>
                </span>
                <span className="info">
                  <a
                    href="https://baccabucci.com/pages/avada-faqs"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    FAQ
                  </a>{' '}
                </span>
              </p>
              <div className="col-md-14 col-lg-16 col-xs-24 xs-mrg-top-15">
                {enterpriseProfile &&
                  enterpriseProfile.email &&
                  !udfKeys?.raiseATicketLink && (
                    <p className="text-left">
                      <span className="info user-select">
                        {!isMobile && (
                          <span
                            className={`pd-right-10 ${
                              user.subdomain === 'mygossip'
                                ? 'icon-envelope'
                                : 'icon-email'
                            }`}
                          />
                        )}
                        {/* <span>{strings[lang].emailUs}</span> */}
                        <a
                          href={`mailto:${enterpriseProfile &&
                            enterpriseProfile.email}`}
                          onClick={e =>
                            gaEvents(
                              e,
                              'Email ID',
                              'help',
                              enterpriseProfile && enterpriseProfile.email
                            )
                          }
                        >
                          {enterpriseProfile && enterpriseProfile.email}
                        </a>
                      </span>
                    </p>
                  )}
                {enterpriseProfile && enterpriseProfile.contact && (
                  <p className="text-left">
                    <div className="row ">
                      <div className="col-md-24 col-lg-9 col-xs-24 xs-mrg-top-5 whitespace-nowrap">
                        <span className="info">
                          {!isMobile && (
                            <span className="pd-right-10 icon-phone" />
                          )}
                          {/* {strings[lang].callUs} */}
                          <a
                            href={`tel:${enterpriseProfile &&
                              enterpriseProfile.contact}`}
                            onClick={e =>
                              gaEvents(
                                e,
                                'Contact Number',
                                'help',
                                enterpriseProfile && enterpriseProfile.contact
                              )
                            }
                          >
                            {enterpriseProfile && enterpriseProfile.contact}
                          </a>
                        </span>
                      </div>
                      <div className="col-md-24 col-lg-15 col-lg-gutter-60 col-xs-24 col-lg-push-0">
                        <span className="info">
                          {!(
                            enterpriseProfile &&
                            enterpriseProfile.time &&
                            enterpriseProfile.time.to
                          ) &&
                          !(
                            enterpriseProfile &&
                            enterpriseProfile.time &&
                            enterpriseProfile.time.from
                          ) ? null : (
                            <span>
                              (
                              {enterpriseProfile &&
                                enterpriseProfile.time &&
                                enterpriseProfile.time.from}{' '}
                              to{' '}
                              {enterpriseProfile &&
                                enterpriseProfile.time &&
                                enterpriseProfile.time.to}
                              {enterpriseProfile &&
                                enterpriseProfile.day &&
                                enterpriseProfile.day.from &&
                                enterpriseProfile &&
                                enterpriseProfile.day &&
                                enterpriseProfile.day.to && (
                                  <span>
                                    {' '}
                                    {enterpriseProfile &&
                                      enterpriseProfile.day &&
                                      enterpriseProfile.day.from}{' '}
                                    to{' '}
                                    {enterpriseProfile &&
                                      enterpriseProfile.day &&
                                      enterpriseProfile.day.to}
                                  </span>
                                )}
                              )
                            </span>
                          )}
                        </span>
                      </div>
                    </div>
                  </p>
                )}
                {(enterpriseProfile?.whatsappLink ||
                  enterpriseProfile?.whatsappNumber) && (
                  <p className="text-left">
                    <span className="info">
                      {!isMobile && (
                        <span className="pd-right-10 icon-whatsapp" />
                      )}
                      <a
                        href={
                          enterpriseProfile?.whatsappLink
                            ? enterpriseProfile.whatsappLink
                            : `https://api.whatsapp.com/send/?phone=${enterpriseProfile?.whatsappNumber}&text=Hi! I need some help.&type=phone_number&app_absent=0`
                        }
                        onClick={e =>
                          gaEvents(
                            e,
                            'Whatsapp',
                            'help',
                            enterpriseProfile?.whatsappLink ??
                              enterpriseProfile?.whatsappNumber
                          )
                        }
                      >
                        {enterpriseProfile?.whatsappText
                          ? enterpriseProfile?.whatsappText
                          : 'Chat with us on WhatsApp'}
                      </a>
                    </span>
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-13 col-lg-12 col-xs-24 xs-mrg-top-10 clickpost-info">
            <div className="row">
              <div className="col-md-8 col-sm-24 xs-mrg-top-15 sm-mrg-top-15">
                <ul className="social-links">
                  <li
                    className={`${
                      enterpriseProfile &&
                      enterpriseProfile.social &&
                      enterpriseProfile.social.fb
                        ? ''
                        : 'hide'
                    }`}
                  >
                    <a
                      href={
                        enterpriseProfile &&
                        enterpriseProfile.social &&
                        enterpriseProfile.social.fb
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={e =>
                        gaEvents(
                          e,
                          'Social',
                          'facebook',
                          enterpriseProfile &&
                            enterpriseProfile.social &&
                            enterpriseProfile.social.fb
                        )
                      }
                    >
                      <span className="icon-fb" />
                    </a>
                  </li>
                  <li
                    className={`${
                      enterpriseProfile &&
                      enterpriseProfile.social &&
                      enterpriseProfile.social.tw
                        ? ''
                        : 'hide'
                    }`}
                  >
                    <a
                      href={
                        enterpriseProfile &&
                        enterpriseProfile.social &&
                        enterpriseProfile.social.tw
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={e =>
                        gaEvents(
                          e,
                          'Social',
                          'twitter',
                          enterpriseProfile &&
                            enterpriseProfile.social &&
                            enterpriseProfile.social.tw
                        )
                      }
                    >
                      <span className="icon-twitter" />
                    </a>
                  </li>
                  <li
                    className={`${
                      enterpriseProfile &&
                      enterpriseProfile.social &&
                      enterpriseProfile.social.ig
                        ? ''
                        : 'hide'
                    }`}
                  >
                    <a
                      href={
                        enterpriseProfile &&
                        enterpriseProfile.social &&
                        enterpriseProfile.social.ig
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={e =>
                        gaEvents(
                          e,
                          'Social',
                          'instagram',
                          enterpriseProfile &&
                            enterpriseProfile.social &&
                            enterpriseProfile.social.ig
                        )
                      }
                    >
                      <span className="icon-instagram" />
                    </a>
                  </li>
                  <li
                    className={`${
                      enterpriseProfile &&
                      enterpriseProfile.social &&
                      enterpriseProfile.social.yt
                        ? ''
                        : 'hide'
                    }`}
                  >
                    <a
                      href={
                        enterpriseProfile &&
                        enterpriseProfile.social &&
                        enterpriseProfile.social.yt
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={e =>
                        gaEvents(
                          e,
                          'Social',
                          'youtube',
                          enterpriseProfile &&
                            enterpriseProfile.social &&
                            enterpriseProfile.social.yt
                        )
                      }
                    >
                      <span className="icon-youtube1" />
                    </a>
                  </li>
                </ul>
              </div>
              <div
                className={`col-md-16 col-sm-24 xs-col-gutter-16 padding-right-60 text-right ${notShowCPBranding &&
                  'xs-text-center pd-right-30'}`}
              >
                <span className="copyright text-gray">
                  © {copyrightYear} {strings[lang].allRightsReserved}
                </span>
                {notShowCPBranding ? null : (
                  <span className="text-gray powered">
                    <span>{strings[lang].poweredBy} </span>{' '}
                    <a
                      href="https://www.clickpost.ai/post-purchase-customer-experience-software"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://pyck-res-bucket.s3.ap-southeast-1.amazonaws.com/clickpost_assets/Clickpost+Logo+HR2.png"
                        alt="clickpost"
                      />
                    </a>
                  </span>
                )}
              </div>
            </div>
          </div>
        </Fragment>
      </div>
    </footer>
  ) : null;
};

BaccabucciFooter.contextTypes = {
  router: PropTypes.object.isRequired
};

BaccabucciFooter.propTypes = {
  tracking: PropTypes.object,
  user: PropTypes.object,
  orderInfo: PropTypes.object,
  page: PropTypes.object
};

const mapStateToProps = state => ({
  tracking: state.tracking,
  user: state.user,
  orderInfo: state.orderInfo,
  page: state.page
});

export default connect(mapStateToProps, null)(BaccabucciFooter);
