import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { strings, copyrightYear } from '../../utils/localizedStrings';
import { ga4ClickEvent } from '../../utils/helper';
class Onemg extends Component {
  constructor() {
    super();
    this.gaEvents = this.gaEvents.bind(this);
  }

  gaEvents = (e, eventName, label, action) => {
    ga4ClickEvent({ category: eventName, action, label, props: this.props });
    ReactGA.event({
      category: eventName,
      action: action,
      label: label
    });
  };

  render() {
    const { user, tracking } = this.props;
    const enterpriseProfile = tracking.theme.meta_data;
    const { lang } = user;
    return (
      <div className="onemg">
        <footer
          className={`container-fluid footer-wrapper pd-top-10 ${
            user.hideLink
              ? ''
              : user.appLink &&
                user.subdomain === 'hopscotch' &&
                this.context.router.history.location.pathname === '/'
              ? 'xs-pd-btm-100'
              : ''
          }`}
        >
          <div className="row col-gutter-130">
            <div className="col-md-12 col-xs-12 image-container text-right">
              <a
                href="https://www.legitscript.com/"
                target="_blank"
                rel="noopener noreferrer"
                title="Verify LegitScript Approval"
              >
                <img
                  src="https://pyck-res-bucket.s3.ap-southeast-1.amazonaws.com/clickpost_assets/logo/1mg_footer_logo.png"
                  alt="LegitScript approved"
                />
              </a>
            </div>
            <div className="col-md-12 col-xs-12 image-container text-left">
              <a
                href="http://www.pjr.com/about-pjr/pjr-clients"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                <div className="">
                  <img
                    alt='We are certified "ISO/IEC 27001:2013"'
                    src="https://pyck-res-bucket.s3.ap-southeast-1.amazonaws.com/clickpost_assets/logo/onemg_footer_logo.png"
                    title='We are certified "ISO/IEC 27001:2013"'
                  />
                </div>
              </a>
            </div>
            <div className="col-md-24 col-xs-22 col-md-offset-0 col-xs-offset-1 text-center mrg-top-20">{`India's only LegitScript and ISO/IEC 27001 certified online healthcare platform`}</div>
          </div>
          <div className="row col-gutter-70 mrg-top-20 copyright">
            <div className="col-md-24 col-xs-24 text-left">
              <ul className="social-links">
                <li>
                  <a
                    href={
                      enterpriseProfile.social && enterpriseProfile.social.fb
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={e =>
                      this.gaEvents(
                        e,
                        'Social',
                        'facebook',
                        enterpriseProfile.social && enterpriseProfile.social.fb
                      )
                    }
                  >
                    <span className="icon-fb" />
                  </a>
                </li>
                <li>
                  <a
                    href={
                      enterpriseProfile.social && enterpriseProfile.social.tw
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={e =>
                      this.gaEvents(
                        e,
                        'Social',
                        'twitter',
                        enterpriseProfile.social && enterpriseProfile.social.tw
                      )
                    }
                  >
                    <span className="icon-twitter" />
                  </a>
                </li>
                <li
                  className={`${
                    enterpriseProfile.social && enterpriseProfile.social.ig
                      ? ''
                      : 'hide'
                  }`}
                >
                  <a
                    href={
                      enterpriseProfile.social && enterpriseProfile.social.ig
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={e =>
                      this.gaEvents(
                        e,
                        'Social',
                        'instagram',
                        enterpriseProfile.social && enterpriseProfile.social.ig
                      )
                    }
                  >
                    <span className="icon-instagram" />
                  </a>
                </li>
                <li
                  className={`${
                    enterpriseProfile.social && enterpriseProfile.social.yt
                      ? ''
                      : 'hide'
                  }`}
                >
                  <a
                    href={
                      enterpriseProfile.social && enterpriseProfile.social.yt
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={e =>
                      this.gaEvents(
                        e,
                        'Social',
                        'youtube',
                        enterpriseProfile.social && enterpriseProfile.social.yt
                      )
                    }
                  >
                    <span className="icon-youtube1" />
                  </a>
                </li>
              </ul>
              <span className="copyright-text pull-right">
                © {copyrightYear} {strings[lang].allRightsReserved}
              </span>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

Onemg.contextTypes = {
  router: PropTypes.object.isRequired
};

Onemg.propTypes = {
  tracking: PropTypes.object,
  user: PropTypes.object,
  page: PropTypes.object
};

const mapStateToProps = state => ({
  tracking: state.tracking,
  user: state.user,
  page: state.page
});

export default connect(mapStateToProps, null)(Onemg);
