import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { infoIcon } from '../utils/svgs';

const Instructions = ({ user, description, className }) => {
  return (
    <div
      className={`bg-brand-primary bd-primary bg-light pd-15 d-flex align-items-center rounded ${className}`}
    >
      <div className="mrg-right-10 d-flex align-items-center">
        {infoIcon(user.subdomain)}
      </div>

      {/* <div> */}
      {/* <div className="fw-600 mrg-btm-5 text-primary-color">
                  {isExchange ? 'Exchange' : 'Return'} Instructions :
                </div> */}
      <div className="text-secondary-color d-flex align-items-center">
        {description}
      </div>
      {/* </div> */}
    </div>
  );
};

Instructions.propTypes = {
  user: PropTypes.any,
  description: PropTypes.any,
  className: PropTypes.string
};

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(Instructions);
