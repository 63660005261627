import React from 'react';
import { statusMap } from './index';
import PropTypes from 'prop-types';
import { openUrl } from '../../utils/helper';
import { infoIcon } from '../exchange/utils/svgs';
import toast from 'react-hot-toast';
// import { currencyMapper } from '../../utils/entity_mapping';
import { connect } from 'react-redux';
import { currencyMapper } from '../../utils/entity_mapping';

const copyToClipboard = text => {
  if (navigator.clipboard) {
    navigator.clipboard
      ?.writeText(text)
      .then(() => {
        toast.success(`${text} copied to clipboard`);
      })
      .catch(() => toast.error('Copy to clipboard not available'));
  } else {
    toast.error('Copy to clipboard not available');
  }
};

const greenTick = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 0C3.136 0 0 3.136 0 7C0 10.864 3.136 14 7 14C10.864 14 14 10.864 14 7C14 3.136 10.864 0 7 0ZM5.6 10.5L2.1 7L3.087 6.013L5.6 8.519L10.913 3.206L11.9 4.2L5.6 10.5Z"
      fill="#14985B"
    />
  </svg>
);

const failedIcon = () => (
  <svg
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 12H14L7 0L0 12ZM7.63636 10.1053H6.36364V8.8421H7.63636V10.1053ZM7.63636 7.57895H6.36364V5.05263H7.63636V7.57895Z"
      fill="#E9A800"
    />
  </svg>
);

const StatusBasedUI = ({ paymentInfo, isAfterOFD, tracking }) => {
  const currencyCode = currencyMapper(tracking.theme.config.country_code);
  switch (paymentInfo.status) {
    case statusMap.PAYMENT_LINK_GENERATED:
      return (
        <>
          <div className="col-xs-24 col-md-16 xs-pd-btm-0 pd-20 d-flex">
            <span className="mrg-right-10" style={{ minWidth: 20 }}>
              {infoIcon('')}
            </span>

            <span className="text-left text-primary-color">
              <p className="text-theme fw-600 fs-16">
                {paymentInfo.discount > 0
                  ? 'Pay online to avail discount!'
                  : 'Pay online!'}
              </p>
              <p className="mrg-top-5 fs-14 text-secondary-color">
                {paymentInfo.discount > 0
                  ? `Your COD order amount is ${currencyCode} ${paymentInfo.originalAmount}. Pay online to avail an instant discount of ${currencyCode}${paymentInfo.discount} and ensure a smooth delivery.`
                  : `Your COD order amount is ${currencyCode} ${paymentInfo.originalAmount}. Pay online to facilitate a smooth delivery process.`}
              </p>
            </span>
          </div>
          <div className="col-xs-24 col-md-8 pd-top-15 pd-btm-15 d-flex">
            <button
              onClick={() => openUrl(paymentInfo.paymentLink, 'blank')}
              className="button sm xs-width-full ml-auto"
            >
              Pay Now
            </button>
          </div>
        </>
      );
    case statusMap.SHIPMENT_CONVERTED:
    case statusMap.PAYMENT_SUCCESSFUL:
      return (
        <div className="col-xs-24 pd-15 d-flex">
          <div
            // style={{ marginTop: 8 }}
            className="mrg-right-10"
          >
            {greenTick()}
          </div>
          <div>
            <div className="text-left d-flex">
              <p className="mrg-right-5 text-nowrap fs-16 text-success">
                Payment successful
                {paymentInfo.discount > 0 && (
                  <span
                    style={{ whiteSpace: 'nowrap', padding: '5px 10px' }}
                    className="rounded-pill text-success mrg-left-5 fs-12 pd-left-10 pd-right-10 bg-success-light"
                  >
                    {currencyCode}
                    {paymentInfo.discount} discount availed
                  </span>
                )}
              </p>
            </div>
            <div className="fs-14 mrg-top-10 text-left text-secondary-color">
              Online payment is successful, you do not need to pay the delivery
              executive at the doorstep now.
            </div>
            <div className="md-flex text-left">
              <div className="d-flex text-primary-color">
                <div
                  className="fs-14 mrg-top-10 mrg-right-20"
                  style={{ minWidth: 100 }}
                >
                  Transaction ID:
                </div>
                <div className="fs-14 mrg-top-10 mrg-right-10">
                  {paymentInfo.transactionId}
                  <span
                    role="presentation"
                    className="cursor-pointer mrg-left-5"
                    onClick={() => copyToClipboard(paymentInfo.transactionId)}
                  >
                    <img src={'/images/copy.svg'} alt="copy" />
                  </span>
                </div>
              </div>
              <div className="d-flex text-primary-color">
                <div
                  className="fs-14 bd-left-1 xs-bd-0 xs-pd-left-0 pd-left-10 mrg-top-10 mrg-right-20"
                  style={{ minWidth: 100 }}
                >
                  Amount Paid:
                </div>
                <div className=" fs-14 mrg-top-10">
                  {currencyCode}
                  {paymentInfo.paidAmount}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    case statusMap.PAYMENT_FAILED:
      return isAfterOFD ? null : (
        <>
          <div className="col-xs-24 col-md-16 xs-pd-btm-0 pd-20 d-flex">
            <span className="mrg-right-10" style={{ minWidth: 20 }}>
              {failedIcon()}
            </span>

            <span className="text-left text-primary-color">
              <p className="text-warning fw-600 fs-16">Payment failed!</p>
              <p className="mrg-top-5 fs-14 text-secondary-color">
                {paymentInfo.discount > 0
                  ? `Payment failed, retry online payment to avail an instant discount of ${currencyCode}${paymentInfo.discount}.`
                  : `Payment failed, retry online payment to facilitate a smooth delivery.`}
              </p>
              <div className="bg-warning-light fs-12 rounded pd-15 mrg-top-5">
                Any deducted amount will be refunded to your source account
                shortly.
              </div>
            </span>
          </div>
          <div
            style={{ alignSelf: 'start' }}
            className="col-xs-24 col-md-8 pd-top-15 pd-btm-15 d-flex"
          >
            <button
              onClick={() => openUrl(paymentInfo.paymentLink, 'blank')}
              className="button bg-warning sm xs-width-full ml-auto"
            >
              Retry Online Payment
            </button>
          </div>
        </>
      );
    case statusMap.SHIPMENT_CONVERSION_FAIL:
      if (isAfterOFD)
        return (
          <>
            <div className="col-xs-24 col-md-16 pd-20 d-flex">
              <span className="mrg-right-10" style={{ minWidth: 20 }}>
                {failedIcon()}
              </span>

              <span className="text-left text-primary-color">
                <p className="text-warning fw-600 fs-16">Payment failed!</p>
                <p className="mrg-top-5 fs-14 text-secondary-color">
                  Online payment failed due to an internal issue.
                </p>
                <div className="bg-warning-light fs-12 rounded pd-15 mrg-top-5">
                  Any deducted amount will be refunded to your source account
                  shortly.
                </div>
              </span>
            </div>
          </>
        );
      return (
        <>
          <div className="col-xs-24 col-md-16 pd-20 d-flex">
            <span className="mrg-right-10" style={{ minWidth: 20 }}>
              {failedIcon()}
            </span>

            <span className="text-left text-primary-color">
              <p className="text-warning fw-600 fs-16">Payment failed!</p>
              <p className="mrg-top-5 fs-14 text-secondary-color">
                Online payment failed due to an internal issue. Please pay the
                delivery executive at the doorstep to receive the order.
              </p>
              <div className="bg-warning-light fs-12 rounded pd-15 mrg-top-5">
                Any deducted amount will be refunded to your source account
                shortly.
              </div>
            </span>
          </div>
        </>
      );
    case statusMap.REFUND_REQUEST_GENERATED_DUE_TO_FAILED_CONVERSION:
      return (
        <>
          <div className="col-xs-24 col-md-16 pd-20 d-flex">
            <span className="mrg-right-10" style={{ minWidth: 20 }}>
              {failedIcon()}
            </span>

            <span className="text-left text-primary-color">
              <p className="text-warning fw-600 fs-16">Payment failed!</p>
              <p className="mrg-top-5 fs-14 text-secondary-color">
                {isAfterOFD
                  ? 'Online payment failed due to an internal issue.'
                  : 'Online payment failed due to an internal issue. Please pay the delivery executive at the doorstep to receive the order.'}
              </p>
              <div className="bg-warning-light fs-12 rounded pd-15 mrg-top-5">
                {paymentInfo.refundUTR
                  ? `Refund has been processed successfully to your source account with UTR No. ${paymentInfo.refundUTR}`
                  : 'Refund has been initiated to your source account, it should reach you shortly.'}
              </div>
            </span>
          </div>
        </>
      );
    case statusMap.REFUND_REQUEST_GENERATED_DUE_TO_RTO:
      return (
        <>
          <div className="col-xs-24 col-md-16 pd-20 d-flex">
            <span className="mrg-right-10" style={{ minWidth: 20 }}>
              {infoIcon()}
            </span>

            <span className="text-left text-primary-color">
              <p className="text-theme fw-600 fs-16">
                {' '}
                {paymentInfo.refundUTR
                  ? 'Refund processed!'
                  : 'Refund initiated!'}
              </p>
              <p className="mrg-top-5 fs-14 text-secondary-color">
                {paymentInfo.refundUTR
                  ? `Refund has been processed successfully to your source account with UTR No. ${paymentInfo.refundUTR}`
                  : 'Since your order could not be delivered, the refund has been intiated to your source account.'}
              </p>
            </span>
          </div>
        </>
      );
    case statusMap.REFUND_SUCCESSFUL:
      return (
        <>
          <div className="col-xs-24 col-md-16 pd-20 d-flex">
            <span className="mrg-right-10" style={{ minWidth: 20 }}>
              {infoIcon()}
            </span>

            <span className="text-left text-primary-color">
              <p className="text-theme fw-600 fs-16">Refund processed!</p>
              <p className="mrg-top-5 fs-14 text-secondary-color">
                Refund has been processed successfully to your source account
                with UTR No. {paymentInfo.refundUTR}
              </p>
            </span>
          </div>
        </>
      );
    default:
      return null;
  }
};

StatusBasedUI.propTypes = {
  paymentInfo: PropTypes.any,
  isAfterOFD: PropTypes.bool,
  tracking: PropTypes.any
};

const mapStateToProps = state => ({
  tracking: state.tracking
});

export default connect(mapStateToProps)(StatusBasedUI);
