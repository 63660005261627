import React from 'react';

export const infoIcon = (subdomain, fill) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11 15H9V9H11V15ZM11 7H9V5H11V7Z"
      fill={
        fill
          ? fill
          : subdomain === '82e'
          ? 'var(--base-primary-color)'
          : 'var(--base-border-color)'
      }
    />
  </svg>
);

export const swapIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_861_289)">
      <path
        d="M13.3332 14.175V8.33333H11.6665V14.175H9.1665L12.4998 17.5L15.8332 14.175H13.3332ZM7.49984 2.5L4.1665 5.825H6.6665V11.6667H8.33317V5.825H10.8332L7.49984 2.5ZM13.3332 14.175V8.33333H11.6665V14.175H9.1665L12.4998 17.5L15.8332 14.175H13.3332ZM7.49984 2.5L4.1665 5.825H6.6665V11.6667H8.33317V5.825H10.8332L7.49984 2.5Z"
        fill="var(--base-theme-color)"
      />
    </g>
    <defs>
      <clipPath id="clip0_861_289">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const arrowUp = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
  >
    <path d="M440-160v-487L216-423l-56-57 320-320 320 320-56 57-224-224v487h-80Z" />
  </svg>
);

export const arrowDown = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="12px"
    viewBox="0 -960 960 960"
    width="12px"
  >
    <path
      fill="current"
      height={'12px'}
      width={'12px'}
      d="M440-800v487L216-537l-56 57 320 320 320-320-56-57-224 224v-487h-80Z"
    />
  </svg>
);

export const chevronLeft = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="navigate_next_black_24dp (2) 2" clipPath="url(#clip0_856_1325)">
      <path
        id="Vector"
        d="M13.9801 18L15.3901 16.59L10.8101 12L15.3901 7.41L13.9801 6L7.98014 12L13.9801 18Z"
        fill="#617D8A"
      />
    </g>
    <defs>
      <clipPath id="clip0_856_1325">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(24 24) rotate(-180)"
        />
      </clipPath>
    </defs>
  </svg>
);
