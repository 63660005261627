import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { strings, copyrightYear } from '../../utils/localizedStrings';
import { ga4ClickEvent } from '../../utils/helper';

class Kalkifashion extends Component {
  gaEvents = (e, eventName, label, action) => {
    ga4ClickEvent({ category: eventName, action, label, props: this.props });
    ReactGA.event({
      category: eventName,
      action: action,
      label: label
    });
  };
  render() {
    const { user, orderInfo, tracking } = this.props;
    const enterpriseProfile = tracking.theme.meta_data;
    const { lang } = user;
    return orderInfo &&
      orderInfo.step &&
      orderInfo.step.current &&
      orderInfo.step.current < 2 ? (
      <footer
        className={`container-fluid footer-wrapper sticky-footer ${
          user.hideLink
            ? ''
            : user.appLink &&
              user.subdomain === 'hopscotch' &&
              this.context.router.history.location.search &&
              this.context.router.history.location.pathname === '/'
            ? 'xs-pd-btm-100'
            : ''
        }`}
      >
        <div className="row col-gutter-60">
          {/* <div className="row col-gutter-60"> */}
          <Fragment>
            <div className="col-md-4 col-xs-10 pos-rel">
              <p>
                <span className="icon icon-email" />
                <span className="info">You can email us on</span>
              </p>
              <p>
                <span className="info user-select">
                  <a
                    href={`mailto:${enterpriseProfile &&
                      enterpriseProfile.email}`}
                    onClick={e =>
                      this.gaEvents(
                        e,
                        'Email ID',
                        'help',
                        enterpriseProfile && enterpriseProfile.email
                      )
                    }
                  >
                    {enterpriseProfile && enterpriseProfile.email}
                  </a>
                </span>
              </p>
            </div>

            <div className="col-md-7 col-xs-14 pos-rel">
              {!(
                enterpriseProfile &&
                enterpriseProfile.time &&
                enterpriseProfile.time.to
              ) &&
              !(
                enterpriseProfile &&
                enterpriseProfile.time &&
                enterpriseProfile.time.from
              ) ? (
                <p>
                  <span className="icon icon-phone" />
                  <span className="info"> Call us on</span>
                </p>
              ) : (
                <p>
                  <span className="icon icon-phone" />
                  <span className="info">
                    Call us{' '}
                    {enterpriseProfile &&
                      enterpriseProfile.time &&
                      enterpriseProfile.time.from}{' '}
                    to{' '}
                    {enterpriseProfile &&
                      enterpriseProfile.time &&
                      enterpriseProfile.time.to}{' '}
                    (
                    {enterpriseProfile &&
                      enterpriseProfile.day &&
                      enterpriseProfile.day.from}{' '}
                    to{' '}
                    {enterpriseProfile &&
                      enterpriseProfile.day &&
                      enterpriseProfile.day.to}
                    )
                  </span>
                </p>
              )}
              {user.subdomain === 'kalkifashion' &&
                [
                  { label: '(India)', value: '022-489-00416' },
                  { label: '(US)', value: '+1(930) 205-2554' },
                  { label: '(UK)', value: '+44 (203) 287-9415' },
                  { label: '(AUS)', value: '+61 (280) 069-415' }
                ].map(contact => (
                  <p key={contact.label}>
                    <span className="info user-select">
                      {contact.label}{' '}
                      <a
                        href={`tel:${contact.value}`}
                        onClick={e =>
                          this.gaEvents(
                            e,
                            'Contact Number',
                            'help',
                            contact.value
                          )
                        }
                      >
                        {contact.value}
                      </a>
                    </span>
                  </p>
                ))}
            </div>

            <div className="col-md-12 col-xs-24 xs-mrg-top-20 social-col">
              <ul className="social-links">
                <li>
                  <a
                    href={
                      enterpriseProfile &&
                      enterpriseProfile.social &&
                      enterpriseProfile.social.fb
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={e =>
                      this.gaEvents(
                        e,
                        'Social',
                        'facebook',
                        enterpriseProfile &&
                          enterpriseProfile.social &&
                          enterpriseProfile.social.fb
                      )
                    }
                  >
                    <span className="icon-fb" />
                  </a>
                </li>
                <li>
                  <a
                    href={
                      enterpriseProfile &&
                      enterpriseProfile.social &&
                      enterpriseProfile.social.tw
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={e =>
                      this.gaEvents(
                        e,
                        'Social',
                        'twitter',
                        enterpriseProfile &&
                          enterpriseProfile.social &&
                          enterpriseProfile.social.tw
                      )
                    }
                  >
                    <span className="icon-twitter" />
                  </a>
                </li>
                <li
                  className={`${
                    enterpriseProfile &&
                    enterpriseProfile.social &&
                    enterpriseProfile.social.ig
                      ? ''
                      : 'hide'
                  }`}
                >
                  <a
                    href={
                      enterpriseProfile &&
                      enterpriseProfile.social &&
                      enterpriseProfile.social.ig
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={e =>
                      this.gaEvents(
                        e,
                        'Social',
                        'instagram',
                        enterpriseProfile &&
                          enterpriseProfile.social &&
                          enterpriseProfile.social.ig
                      )
                    }
                  >
                    <span className="icon-instagram" />
                  </a>
                </li>
                <li
                  className={`${
                    enterpriseProfile &&
                    enterpriseProfile.social &&
                    enterpriseProfile.social.yt
                      ? ''
                      : 'hide'
                  }`}
                >
                  <a
                    href={
                      enterpriseProfile &&
                      enterpriseProfile.social &&
                      enterpriseProfile.social.yt
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={e =>
                      this.gaEvents(
                        e,
                        'Social',
                        'youtube',
                        enterpriseProfile &&
                          enterpriseProfile.social &&
                          enterpriseProfile.social.yt
                      )
                    }
                  >
                    <span className="icon-youtube1" />
                  </a>
                </li>
                {enterpriseProfile &&
                enterpriseProfile.app &&
                (enterpriseProfile.app.android || enterpriseProfile.app.ios) ? (
                  <li className="display-block-desktop">
                    |{' '}
                    <span className="pd-left-10">
                      {' '}
                      {strings[lang].downloadApp}
                    </span>
                  </li>
                ) : null}
                {enterpriseProfile &&
                enterpriseProfile.app &&
                enterpriseProfile.app.android ? (
                  <li className="display-block-desktop">
                    <a
                      href={enterpriseProfile.app.android}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={e =>
                        this.gaEvents(
                          e,
                          'App Install',
                          user.device,
                          enterpriseProfile.app.android
                        )
                      }
                    >
                      <span className="icon-android" />
                    </a>
                  </li>
                ) : null}
                {enterpriseProfile &&
                enterpriseProfile.app &&
                enterpriseProfile.app.ios ? (
                  <li className="display-block-desktop">
                    <a
                      href={enterpriseProfile.app.ios}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={e =>
                        this.gaEvents(
                          e,
                          'App Install',
                          user.device,
                          enterpriseProfile.app.ios
                        )
                      }
                    >
                      <span className="icon-apple" />
                    </a>
                  </li>
                ) : null}
              </ul>
              <span className="copyright text-gray pull-right">
                © {copyrightYear} {strings[lang].allRightsReserved}
              </span>
            </div>
          </Fragment>
        </div>
      </footer>
    ) : null;
  }
}

Kalkifashion.contextTypes = {
  router: PropTypes.object.isRequired
};

Kalkifashion.propTypes = {
  tracking: PropTypes.object,
  user: PropTypes.object,
  orderInfo: PropTypes.object,
  page: PropTypes.object
};

const mapStateToProps = state => ({
  tracking: state.tracking,
  user: state.user,
  orderInfo: state.orderInfo,
  page: state.page
});

export default connect(mapStateToProps, null)(Kalkifashion);
