import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class NutraBay extends Component {
  render() {
    const { tracking } = this.props;
    return (
      <header className="container-fluid header-wrapper nutrabay">
        <div className="row no-gutter">
          <div className="col-md-22 col-xs-24 col-md-offset-1 text-left xs-text-center">
            <a
              href={tracking.theme.meta_data.website || '#'}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="common logo"
                src={tracking.theme.logo || '/images/logo_placeholder.svg'}
                alt="logo"
              />
            </a>
          </div>
        </div>
      </header>
    );
  }
}

NutraBay.propTypes = {
  tracking: PropTypes.object
};

const mapStateToProps = state => ({
  tracking: state.tracking
});

export default connect(
    mapStateToProps,
    null
  )(NutraBay);
