import { strings } from '../../utils/localizedStrings';

export const refundTypeCodes = {
  REFUND_LINK: 'REFUND_LINK',
  DISCOUNT_CODE: 'DISCOUNT_CODE',
  SHOPIFY_CREDITS: 'SHOPIFY_CREDITS',
  GIFT_CARD: 'GIFT_CARD',
  MANUAL: 'MANUAL',
  source: 'REFUND_TO_SOURCE_ACCOUNT',
  upiDetails: 'UPI_TRANSFER',
  bankDetails: 'BANK_ACCOUNT_TRANSFER',
  credits: 'BRAND_CREDIT'
};

export const newRefundTypes = (lang, creditsText = '') => ({
  REFUND_LINK: {
    text: strings[lang].payoutLink,
    refund_name: refundTypeCodes.REFUND_LINK
  },
  DISCOUNT_CODE: {
    text: strings[lang].discountCoupon,
    refund_name: refundTypeCodes.DISCOUNT_CODE
  },
  GIFT_CARD: {
    text: strings[lang].giftCard,
    refund_name: refundTypeCodes.GIFT_CARD
  },
  source: {
    text: strings[lang].backToSource,
    refund_name: refundTypeCodes.source
  },
  upiDetails: {
    text: strings[lang].upiDetails,
    refund_name: refundTypeCodes.upiDetails
  },
  bankDetails: {
    text: strings[lang].bankAccount,
    refund_name: refundTypeCodes.bankDetails
  },
  credits: {
    text: creditsText,
    refund_name: refundTypeCodes.credits
  }
});
