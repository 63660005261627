/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { createContext, useContext, useState } from 'react';
import {
  address as addressObj,
  calendarDates
} from '../../utils/entity_mapping';
import { stepActions, steps } from './defaultValues';

export const ExchangeContext = createContext();
export const useExchangeContext = () => useContext(ExchangeContext);
const Wrapper = WrappedComponent => props => {
  const { location, history, tracking, user } = props;
  const { udfKeys } = props.tracking.theme;
  const [state, setState] = useState({
    address: Object.assign(addressObj),
    isSelfShipped: false,
    tempPincodeData: { city: '', state: '', country: '', pincode: '' },
    dateMeta: calendarDates({ itr: 6, subdomain: user.subdomain }) || [],
    source: '',
    token: '',
    sku: '',
    editProductId: '',
    skipStep: -1
  });
  const [step, setStep] = useState({
    current: steps.ITEM_SELECTION,
    action: ''
  });

  const exchangeTexts =
    udfKeys.exchangeTextAlt === 'true'
      ? ['Replace', 'Replacement', 'Replaced']
      : ['Exchange', 'Exchange', 'Exchanged'];

  const returnTexts =
    udfKeys.returnTextAlt === 'true' ? ['Refund'] : ['Return'];

  if (
    location?.pathname?.split('/')[1] === 'return-exchange' &&
    tracking?.theme?.config?.exchangeServiceEnabled === false
  ) {
    history.push('/returns' + location.search);
  }

  const goForward = step =>
    setStep({ current: step, action: stepActions.FORWARD });
  const goBack = step =>
    setStep({ current: step, action: stepActions.REVERSE });

  return (
    <ExchangeContext.Provider
      value={{
        state,
        setState,
        labels: { exchangeTexts, returnTexts },
        step,
        setStep,
        goBack,
        goForward
      }}
    >
      <WrappedComponent {...props} />
    </ExchangeContext.Provider>
  );
};

export default Wrapper;
