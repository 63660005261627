import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class CommonHeader extends Component {
  // langSwitcher = () => {
  //   const { lang } = this.props.user;
  //   let newLang = 'en';
  //   if (lang === 'en') {
  //     newLang = 'ar';
  //   }
  //   let { pathname, search, hash } = this.props.location;
  //   let splitUrl = pathname.split('/');
  //   const idx = splitUrl.indexOf(lang);
  //   if (idx > -1) {
  //     pathname = pathname.replace(lang, newLang);
  //     splitUrl[1] = newLang;
  //   } else {
  //     splitUrl.splice(1, 0, newLang);
  //   }
  //   if (splitUrl.length === 2) {
  //     splitUrl[2] = '';
  //   }
  //   window.location.href = splitUrl.join('/') + search + hash;
  // };
  render() {
    const { tracking, user } = this.props;
    // const { lang } = user;
    return (
      <header className={`container-fluid header-wrapper ${user.subdomain}`}>
        <div className="row no-gutter col-gutter-130 items-center d-flex">
          <div className="col-sm-10 pd-0 col-xs-6 col-sm-offset-7 col-xs-offset-0 text-center">
            <a
              href={tracking.theme.meta_data.website || '#'}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="common logo"
                src={tracking.theme.logo || '/images/logo_placeholder.svg'}
                alt="logo"
              />
            </a>
          </div>
          {/* <div className="col-xs-9 pd-0 d-flex justify-end col-xs-offset-9 col-sm-offset-0 text-primary-color items-center">
            <a
              className="hyperlink text-primary-color fs-12 "
              href={`https://www.brandsforless.com/${
                user.lang === 'ar' ? 'ar' : 'en'
              }-ae/need-help/`}
            >
              <span className="visible hidden-xs">
              {lang === 'en' ? 'Need help?' : 'تحتاج مساعدة؟'}</span>
              <img
                className="hide visible-xs"
                alt={'support'}
                src={'/images/bfl-support.svg'}
                width={24}
              />
            </a>
          </div> */}
        </div>
      </header>
    );
  }
}

CommonHeader.propTypes = {
  tracking: PropTypes.object,
  user: PropTypes.object,
  location: PropTypes.object
};

const mapStateToProps = state => ({
  tracking: state.tracking,
  user: state.user
});

export default withRouter(connect(mapStateToProps, null)(CommonHeader));
