import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { strings, copyrightYear } from '../../utils/localizedStrings';
import { ga4ClickEvent } from '../../utils/helper';

class HerbalifeFooter extends Component {
  gaEvents = (e, eventName, label, action) => {
    ga4ClickEvent({ category: eventName, action, label, props: this.props });
    ReactGA.event({
      category: eventName,
      action: action,
      label: label
    });
  };

  render() {
    const { user, orderInfo, tracking } = this.props;
    const enterpriseProfile = tracking.theme.meta_data;
    const { lang } = user;

    const isMobile =
      user?.device === 'mobile' ||
      user?.device === 'ios' ||
      user?.device === 'android';

    const returnSupportEmail = tracking.theme.meta_data?.udf?.filter(
      item => item.key === 'returnSupportEmail'
    )?.[0]?.value;

    const returnSupportPhone = tracking.theme.meta_data?.udf?.filter(
      item => item.key === 'returnSupportPhone'
    )?.[0]?.value;

    const returnURL =
      this.context.router.history.location.pathname === '/returns';

    const notShowCPBranding = tracking.theme.meta_data?.udf?.filter(
      item => item.key === 'notShowCPBranding'
    )?.[0]?.value;

    const footerInfo = {
      en: `${
        user.subdomain === 'herbalife-fr'
          ? 'Monday to Friday from 9 a.m. to 4 p.m.'
          : '9:30 AM WIB to 6:00 PM WIB Monday to Friday, 9:30 AM WIB to 1:00 PM WIB Saturday'
      }`,
      id:
        '9:30 AM WIB to 6:00 PM WIB Senin to Jumat, 9:30 AM WIB to 1:00 PM WIB Sabtu',
      fr: 'Du lundi au vendredi de 9H à 16H'
    };

    const onlinesupport = {
      en: 'Myherbalife online support',
      fr: 'Assistance en ligne Myherbalife'
    };
    return orderInfo &&
      orderInfo.step &&
      orderInfo.step.current &&
      orderInfo.step.current < 2 ? (
      <footer
        className={`container-fluid footer-wrapper sticky-footer ${
          user.hideLink
            ? ''
            : user.appLink &&
              user.subdomain === 'hopscotch' &&
              this.context.router.history.location.search &&
              this.context.router.history.location.pathname === '/'
            ? 'xs-pd-btm-100'
            : ''
        }`}
      >
        <div className="row lg-col-gutter-130">
          {/* <div className="row col-gutter-60"> */}
          <Fragment>
            {/* user.enterprise === 'herbalife' ? (
              <div className="col-md-8 col-xs-24 pos-rel">
                <p>
                  <span className="icon icon-email" />
                  <span className="info">
                    You can log and track all your inquiries on
                  </span>
                </p>
                <p>
                  <span className="info user-select">
                    24x7 Online Support:{' '}
                    <a
                      className="support-link"
                      href="https://herbalife-econnect.hrbl.com/intelligentchannel/faces/createinquiry"
                    >
                      {' '}
                      <b>I-channel (Click Here)</b>
                    </a>
                  </span>
                </p>
              </div>
            ) :  */}
            <div className="col-md-8 col-lg-10 col-xs-24 xs-mrg-top-15">
              {enterpriseProfile && enterpriseProfile.email && (
                <p className="text-left">
                  <span className="info user-select">
                    <span>{strings[lang].emailUs}</span>
                    <a
                      href={`mailto:${enterpriseProfile &&
                        enterpriseProfile.email}`}
                      onClick={e =>
                        this.gaEvents(
                          e,
                          'Email ID',
                          'help',
                          enterpriseProfile && enterpriseProfile.email
                        )
                      }
                    >
                      {returnURL && returnSupportEmail
                        ? returnSupportEmail
                        : enterpriseProfile && enterpriseProfile.email}
                    </a>
                  </span>
                </p>
              )}
              {enterpriseProfile && enterpriseProfile.contact && (
                <p className="text-left">
                  <div className="row ">
                    <div
                      className={`${
                        user.subdomain === 'herbalife-fr'
                          ? 'col-lg-24'
                          : 'col-lg-10'
                      }col-md-24  col-xs-24 xs-mrg-top-5`}
                    >
                      {user.subdomain === 'herbalife-fr' ? (
                        <>
                          <span className="fs-20 d-block">
                            {strings[lang].callUs}
                          </span>
                          <span
                            className={`${
                              isMobile ? '' : 'd-flex items-center'
                            } whitespace-nowrap`}
                          >
                            <span
                              className={` ${
                                isMobile ? 'hide' : 'show'
                              } icon-note-text`}
                            />
                            {onlinesupport[lang] ?? onlinesupport.en}
                          </span>
                          <span className="d-block">7J/7 24H/24</span>
                        </>
                      ) : (
                        <span className="info">
                          {strings[lang].callUs}
                          <a
                            href={`tel:${enterpriseProfile &&
                              enterpriseProfile.contact}`}
                            onClick={e =>
                              this.gaEvents(
                                e,
                                'Contact Number',
                                'help',
                                enterpriseProfile && enterpriseProfile.contact
                              )
                            }
                          >
                            {returnURL && returnSupportPhone
                              ? returnSupportPhone
                              : enterpriseProfile && enterpriseProfile.contact}
                          </a>
                        </span>
                      )}
                    </div>
                    <div
                      className={`${
                        user.subdomain === 'herbalife-fr'
                          ? ''
                          : 'col-lg-gutter-60'
                      } col-md-24 col-lg-14 col-xs-24 col-lg-push-0 mrg-top-5 xs-mrg-top-0`}
                    >
                      {user.subdomain === 'herbalife-fr' ? (
                        <span>
                          <a
                            href={`tel:${enterpriseProfile &&
                              enterpriseProfile.contact}`}
                            onClick={e =>
                              this.gaEvents(
                                e,
                                'Contact Number',
                                'help',
                                enterpriseProfile && enterpriseProfile.contact
                              )
                            }
                            className={`${
                              isMobile ? '' : 'd-flex items-center'
                            }`}
                          >
                            <span
                              className={`${
                                isMobile ? 'hide' : 'show'
                              } icon-old-phone mrg-right-5`}
                            />
                            {enterpriseProfile && enterpriseProfile.contact}
                          </a>
                          <p>
                            ({' '}
                            {footerInfo[lang] ??
                              'Monday to Friday from 9 a.m. to 4 p.m.'}{' '}
                            )
                          </p>
                        </span>
                      ) : (
                        <span>{footerInfo[lang] ?? footerInfo.en}</span>
                      )}
                    </div>
                  </div>
                </p>
              )}
            </div>
            <div className="col-md-16 col-lg-14 col-xs-24 xs-mrg-top-10 clickpost-info">
              <div className="row">
                <div className="col-md-8 col-sm-24 xs-mrg-top-15 sm-mrg-top-15">
                  <ul className="social-links">
                    <li
                      className={`${
                        user.subdomain === 'herbalife-fr' ? '' : 'hide'
                      } `}
                    >
                      <a
                        href="https://www.herbalife-blog.fr/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="icon-monitor" />
                      </a>
                    </li>
                    <li
                      className={`${
                        enterpriseProfile &&
                        enterpriseProfile.social &&
                        enterpriseProfile.social.fb
                          ? ''
                          : 'hide'
                      }`}
                    >
                      <a
                        href={
                          enterpriseProfile &&
                          enterpriseProfile.social &&
                          enterpriseProfile.social.fb
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={e =>
                          this.gaEvents(
                            e,
                            'Social',
                            'facebook',
                            enterpriseProfile &&
                              enterpriseProfile.social &&
                              enterpriseProfile.social.fb
                          )
                        }
                      >
                        <span className="icon-fb" />
                      </a>
                    </li>
                    <li
                      className={`${
                        enterpriseProfile &&
                        enterpriseProfile.social &&
                        enterpriseProfile.social.tw
                          ? ''
                          : 'hide'
                      }`}
                    >
                      <a
                        href={
                          enterpriseProfile &&
                          enterpriseProfile.social &&
                          enterpriseProfile.social.tw
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={e =>
                          this.gaEvents(
                            e,
                            'Social',
                            'twitter',
                            enterpriseProfile &&
                              enterpriseProfile.social &&
                              enterpriseProfile.social.tw
                          )
                        }
                      >
                        <span className="icon-twitter" />
                      </a>
                    </li>
                    <li
                      className={`${
                        enterpriseProfile &&
                        enterpriseProfile.social &&
                        enterpriseProfile.social.ig
                          ? ''
                          : 'hide'
                      }`}
                    >
                      <a
                        href={
                          enterpriseProfile &&
                          enterpriseProfile.social &&
                          enterpriseProfile.social.ig
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={e =>
                          this.gaEvents(
                            e,
                            'Social',
                            'instagram',
                            enterpriseProfile &&
                              enterpriseProfile.social &&
                              enterpriseProfile.social.ig
                          )
                        }
                      >
                        <span className="icon-instagram" />
                      </a>
                    </li>
                    <li
                      className={`${
                        enterpriseProfile &&
                        enterpriseProfile.social &&
                        enterpriseProfile.social.yt
                          ? ''
                          : 'hide'
                      }`}
                    >
                      <a
                        href={
                          enterpriseProfile &&
                          enterpriseProfile.social &&
                          enterpriseProfile.social.yt
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={e =>
                          this.gaEvents(
                            e,
                            'Social',
                            'youtube',
                            enterpriseProfile &&
                              enterpriseProfile.social &&
                              enterpriseProfile.social.yt
                          )
                        }
                      >
                        <span className="icon-youtube1" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div
                  className={`col-md-16 col-sm-24 xs-col-gutter-16 ${notShowCPBranding &&
                    'xs-text-center text-right pd-right-30'} lg-text-right `}
                >
                  <span className="copyright text-gray">
                    © {copyrightYear} {strings[lang].allRightsReserved}
                  </span>
                  {notShowCPBranding ? null : (
                    <span className="text-gray powered">
                      <span>{strings[lang].poweredBy} </span>
                      <a
                        href="https://www.clickpost.ai/post-purchase-customer-experience-software"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src="https://pyck-res-bucket.s3.ap-southeast-1.amazonaws.com/clickpost_assets/clickpost_logo.png"
                          alt="clickpost"
                        />
                      </a>
                    </span>
                  )}
                </div>
              </div>
            </div>
          </Fragment>
        </div>
      </footer>
    ) : null;
  }
}

HerbalifeFooter.contextTypes = {
  router: PropTypes.object.isRequired
};

HerbalifeFooter.propTypes = {
  tracking: PropTypes.object,
  user: PropTypes.object,
  orderInfo: PropTypes.object,
  page: PropTypes.object
};

const mapStateToProps = state => ({
  tracking: state.tracking,
  user: state.user,
  orderInfo: state.orderInfo,
  page: state.page
});

export default connect(mapStateToProps, null)(HerbalifeFooter);
