/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

const surveyLink = user => {
  const [state, setState] = useState({ show: true, elementHeight: null });
  const elementRef = useRef(null);

  const handleScroll = () => {
    window.scrollY || document.documentElement.scrollTop;
  };

  useEffect(() => {
    setState({
      ...state,
      elementHeight: document.getElementById('survey').offsetHeight
    });
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const ShowData = () => {
    return (
      <div
        style={{
          marginBottom:
            user?.user?.subdomain === 'pantaloons'
              ? `${state.elementHeight}px`
              : ''
        }}
      >
        <div
          id="survey"
          ref={elementRef}
          className={`row survey-link ${
            user?.user?.subdomain === 'pantaloons' ? 'pos-fixed top' : 'pos-rel'
          }`}
          style={{
            top: user?.user?.subdomain === 'pantaloons' ? '0' : '10'
          }}
        >
          <div className="col-md-24 d-flex justify-center items-center ">
            <div className="pd-20">
              For the best experience, enhance browsing by using Chrome, Safari,
              Firefox, or Edge. If you are still facing issues, {''}
              <a
                href="https://abfrlcx.qualtrics.com/jfe/form/SV_dnUZOWfwmSWQ0mO"
                target="_blank"
                rel="noopener noreferrer"
              >
                click here.
              </a>
            </div>
            <div className="pd-right-20">
              <span
                className="icon-close cursor-pointer"
                onClick={() => setState({ ...state, show: false })}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return <>{state.show ? <ShowData /> : null}</>;
};

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(surveyLink);
