import axios from 'axios';

const post = config => {
  if (config) {
    return axios({
      url: config.url,
      method: config.method ? config.method : 'POST',
      data: config.data,
      // Headers: [],
      headers: config.headers
    });
  }
};

const get = config => {
  if (config) {
    return axios({
      url: config.url,
      method: 'GET',
      headers: config.headers
    });
  }
};

export { post, get };
