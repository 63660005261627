import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { ga4ClickEvent } from '../../utils/helper';
import { strings, copyrightYear } from '../../utils/localizedStrings';

class Bewakoof extends Component {
  constructor() {
    super();
    this.gaEvents = this.gaEvents.bind(this);
  }

  gaEvents(e, eventName, label, action) {
    ga4ClickEvent({ category: eventName, action, label, props: this.props });
    ReactGA.event({
      category: eventName,
      action: action,
      label: label
    });
  }

  render() {
    const { user, tracking } = this.props;
    const enterpriseProfile = tracking.theme.meta_data;
    const { lang } = user;
    return (
      <div className="bewakoof">
        <footer
          className={`container-fluid footer-wrapper ${
            user.hideLink
              ? ''
              : user.appLink &&
                user.subdomain === 'hopscotch' &&
                this.context.router.history.location.pathname === '/'
              ? 'xs-pd-btm-100'
              : ''
          }`}
        >
          <div className="row no-gutter">
            <div className="col-md-8 col-xs-24 visible-xs">
              <p className="text-center">
                You can reach us at{' '}
                <a
                  href={`mailto:${enterpriseProfile.email}`}
                  onClick={e =>
                    this.gaEvents(
                      e,
                      'Email ID',
                      'help',
                      enterpriseProfile.email
                    )
                  }
                >
                  {enterpriseProfile.email}
                </a>{' '}
                with all queries.
              </p>
            </div>
            <div className="col-md-8 col-xs-24 pos-rel hidden-xs">
              <p>
                <span className="icon icon-email" />
                <span className="info">E-mail us at for 24/7 support</span>
              </p>
              <p>
                <span className="info user-select">
                  <a
                    href={`mailto:${enterpriseProfile.email}`}
                    onClick={e =>
                      this.gaEvents(
                        e,
                        'Email ID',
                        'help',
                        enterpriseProfile.email
                      )
                    }
                  >
                    {enterpriseProfile.email}
                  </a>
                </span>
              </p>
            </div>
            <div className="col-md-8 col-xs-24 pd-10">
              <span className="right-block text-left">
                {' '}
                <a href="https://www.bewakoof.com/contact-us">
                  Contact Us
                </a> |{' '}
                <a href="https://www.bewakoof.com/faq?query=return">
                  Return Policy
                </a>{' '}
                |{' '}
                <a href="https://www.bewakoof.com/privacy-policy-and-disclaimer">
                  Privacy Policy
                </a>{' '}
                |{' '}
                <a href="https://www.bewakoof.com/terms-and-conditions">T&C</a>{' '}
              </span>
            </div>
            <div className="col-md-8 col-xs-24 pd-10 text-right">
              <ul className="social-links">
                <li>
                  <a
                    href={enterpriseProfile.social.fb}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={e =>
                      this.gaEvents(
                        e,
                        'Social',
                        'facebook',
                        enterpriseProfile.social.fb
                      )
                    }
                  >
                    <span className="icon-fb" />
                  </a>
                </li>
                <li>
                  <a
                    href={enterpriseProfile.social.tw}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={e =>
                      this.gaEvents(
                        e,
                        'Social',
                        'twitter',
                        enterpriseProfile.social.tw
                      )
                    }
                  >
                    <span className="icon-twitter" />
                  </a>
                </li>
                <li className={`${enterpriseProfile.social.ig ? '' : 'hide'}`}>
                  <a
                    href={enterpriseProfile.social.ig}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={e =>
                      this.gaEvents(
                        e,
                        'Social',
                        'instagram',
                        enterpriseProfile.social.ig
                      )
                    }
                  >
                    <span className="icon-instagram" />
                  </a>
                </li>
                <li className={`${enterpriseProfile.social.yt ? '' : 'hide'}`}>
                  <a
                    href={enterpriseProfile.social.yt}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={e =>
                      this.gaEvents(
                        e,
                        'Social',
                        'youtube',
                        enterpriseProfile.social.yt
                      )
                    }
                  >
                    <span className="icon-youtube1" />
                  </a>
                </li>
              </ul>
              <span className="copyright text-gray pull-right">
                © {copyrightYear} {strings[lang].allRightsReserved}
              </span>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

Bewakoof.contextTypes = {
  router: PropTypes.object.isRequired
};

Bewakoof.propTypes = {
  tracking: PropTypes.object,
  user: PropTypes.object,
  page: PropTypes.object
};

const mapStateToProps = state => ({
  tracking: state.tracking,
  user: state.user,
  page: state.page
});

export default connect(mapStateToProps, null)(Bewakoof);
