/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { ga4ClickEvent } from '../../utils/helper';

class Nykaa extends Component {
  constructor() {
    super();
    this.gaEvents = this.gaEvents.bind(this);
  }

  gaEvents(e, eventName, label, action) {
    ga4ClickEvent({ category: eventName, action, label, props: this.props });
    ReactGA.event({
      category: eventName,
      action: action,
      label: label
    });
  }

  render() {
    return (
      <div className="nykaa">
        <footer className="container-fluid">
          <div className="row">
            <div className="col-xs-24 visible-xs social-container">
              <div className="text-uppercase">
                <strong>
                  show us some love <i className="fa fa-heart" /> on social
                  media
                </strong>
              </div>
              <ul className="social">
                <li>
                  <a
                    href="//instagram.com/mynykaa?ref=badge"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={e =>
                      this.gaEvents(
                        e,
                        'Social',
                        'instagram',
                        '//instagram.com/mynykaa?ref=badge'
                      )
                    }
                  >
                    <i className="instagram" />
                  </a>
                </li>
                <li>
                  <a
                    href="//www.facebook.com/MyNykaa"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={e =>
                      this.gaEvents(
                        e,
                        'Social',
                        'facebook',
                        '//www.facebook.com/MyNykaa'
                      )
                    }
                  >
                    <i className="facebook" />
                  </a>
                </li>
                <li>
                  <a
                    href="//www.youtube.com/channel/UCoaH2UtB1PsV7av17woV1BA"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={e =>
                      this.gaEvents(
                        e,
                        'Social',
                        'youtube',
                        '//www.youtube.com/channel/UCoaH2UtB1PsV7av17woV1BA'
                      )
                    }
                  >
                    <i className="youtube" />
                  </a>
                </li>
                <li>
                  <a
                    href="//twitter.com/MyNykaa"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={e =>
                      this.gaEvents(
                        e,
                        'Social',
                        'twitter',
                        '//twitter.com/MyNykaa'
                      )
                    }
                  >
                    <i className="twitter" />
                  </a>
                </li>
                <li>
                  <a
                    href="//www.pinterest.com/mynykaa/"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={e =>
                      this.gaEvents(
                        e,
                        'Social',
                        'pinterest',
                        '//www.pinterest.com/mynykaa/'
                      )
                    }
                  >
                    <i className="pinterest" />
                  </a>
                </li>
              </ul>
            </div>
            <a
              className="col-xs-24 visible-xs help"
              href="https://support.nykaa.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="help-center" /> &nbsp;<span>HELP CENTER</span>
            </a>
            {/* <div className="col-xs-12 visible-xs help hide">
              <a href="mailto:help@nykaa.com">
                <i className="email" /> &nbsp;<span>help@nykaa.com</span>
              </a>
            </div> */}
          </div>
          <div className="row col-gutter-130 footer-container">
            {/* <div className="col-md-12 col-xs-24 pd-10">
              <p>
                Copyright © 2022 Netmeds Marketplace Limited.
                <br /> All rights Reserved
              </p>
            </div>
            <div className="col-md-12 col-xs-24 pd-10">
              <span className="right-block text-right xs-text-left">
                {' '}
                <a href="https://www.netmeds.com/sitemap">Sitemap</a> |{' '}
                <a href="https://www.netmeds.com/privacy-policy">
                  Privacy Policy
                </a>{' '}
                |{' '}
                <a href="https://www.netmeds.com/terms-and-conditions">
                  Terms of use
                </a>{' '}
              </span>
            </div>
           */}

            <div className="pages-links-list-wrap col-md-24 col-xs-24 hidden-xs">
              <ul className="pages-links-list">
                <li>
                  <a
                    href="https://www.nykaa.com/terms-conditions"
                    target="_blank"
                  >
                    Terms &amp; Conditions
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.nykaa.com/shipping-policy"
                    target="_blank"
                  >
                    Shipping Policy
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.nykaa.com/cancellation-policy"
                    target="_blank"
                  >
                    Cancellation Policy
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.nykaa.com/privacy-policy"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
            <div className="copyrights-text col-md-24 col-xs-24">
              <span className="copyright">©</span> 2022 Nykaa E-Retail Pvt. Ltd.
              All Rights Reserved
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

Nykaa.contextTypes = {
  router: PropTypes.object.isRequired
};

Nykaa.propTypes = {
  tracking: PropTypes.object,
  user: PropTypes.object,
  page: PropTypes.object
};

const mapStateToProps = state => ({
  tracking: state.tracking,
  user: state.user,
  page: state.page
});

export default connect(mapStateToProps, null)(Nykaa);
